import { NestedTreeControl } from '@angular/cdk/tree';
import { SelectionModel, ArrayDataSource } from '@angular/cdk/collections';
import { MatChipList } from '@angular/material/chips';
import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { BehaviorSubject } from 'rxjs';
import { RequestsService } from 'src/app/services/requests.service';
import { Lookup } from 'src/app/models/Lookup';

/** Flat to-do item node with expandable and level information */
export class TodoItemFlatNode {
  name!: string;
  level!: number;
  expandable!: boolean;
  id!: any;
  childs!: Lookup[];
  isicCode!: any;
}

@Component({
  selector: 'app-tree-selection',
  templateUrl: './tree-selection.component.html'
})
export class TreeSelectionComponent implements OnInit {


  @Input() showIsicCode: boolean = false;
  @Input() TREE_DATA: Lookup[] = [];
  @Input() selected: Lookup[] = [];

  @Input() isView = false;
  @Input() label = false;
  @Input() loading = false;
  @Input() isSubmitted = false;
  @Input() isRequired = false;
  @Input() showAllSelectedChips = false;
  @Input() isDataFound: boolean = false;
  @Output() selectedItems = new EventEmitter<Lookup[]>();
  @Output() makeSearch = new EventEmitter<string>();
  searchText!: string;

  showTree = false;
  @ViewChild('searchInput', { static: false }) searchInput!: ElementRef<HTMLInputElement>;
  @ViewChild('chipList', { static: false }) chipList!: MatChipList;


  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<TodoItemFlatNode, Lookup>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<Lookup, TodoItemFlatNode>();

  /** A selected parent node to be inserted */
  selectedParent: TodoItemFlatNode | null = null;

  /** The new item's name */
  newItemName = '';

  treeControl: FlatTreeControl<TodoItemFlatNode>;

  treeFlattener: MatTreeFlattener<Lookup, TodoItemFlatNode>;

  dataSource: MatTreeFlatDataSource<Lookup, TodoItemFlatNode>;


  /** The selection for checklist */
  checklistSelection = new SelectionModel<TodoItemFlatNode>(true /* multiple */);



  constructor(private requestsService: RequestsService) {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
      this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<TodoItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  }

  ngOnInit(): void {
console.log(this.searchText);

    if(this.TREE_DATA && this.TREE_DATA.length){
      this.dataSource.data = this.TREE_DATA;
    }
    if(this.selected && this.selected.length){
      setTimeout(() => {
        if(this.treeControl && this.treeControl.dataNodes && this.treeControl.dataNodes.length){
          this.treeControl.dataNodes.forEach((parent: any) => {
            this.childsR(parent);
          });
        }
      }, 100);
    }
  }

  getLevel = (node: TodoItemFlatNode) => node.level;

  isExpandable = (node: TodoItemFlatNode) => node.expandable;

  getChildren = (node: Lookup): Lookup[] => node.childs!;

  hasChild = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.expandable;

  // ngOnChanges(changes: SimpleChanges) {
  //   if (changes) {
  //     if (changes.TREE_DATA) {
  //       this.treeControl.dataNodes.forEach((parent: any) => {
  //         this.childsR(parent);
  //       });
  //     }
  //   }
  // }

  sendMakeSearch(e: any) {
    this.makeSearch.emit(e);
  }
  // End Chips, autocomplete field methods


  childsR(parent: any) {
    if (parent.childs && parent.childs.length) {
      parent.childs.forEach((element: any) => {
        if (element.childs && element.childs.length) {
          this.childsR(element);
        }
      });
    } else {
      const itemFound = this.selected.find((item: any) => parent.id === item.id);
      if (itemFound) {
        this.todoLeafItemSelectionToggle(parent);
      }
    }
  }

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: Lookup, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.name === node.name
      ? existingNode
      : new TodoItemFlatNode();
    node.level = level;
    node.expandable = !!node.childs?.length;

    flatNode.id = node.id;
    flatNode.level = level;
    flatNode.expandable = !!node.childs?.length;
    flatNode.childs = (node.childs && node.childs.length) ? node.childs : [];
    flatNode.name = node.name!;
    flatNode.isicCode = node.isicCode!;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }


  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.length > 0 && descendants.every(child => {
      return this.checklistSelection.isSelected(child);
    });
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: TodoItemFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.forEach(child => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);

    this.selectedItem(node);
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: TodoItemFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: TodoItemFlatNode): void {
    let parent: TodoItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: TodoItemFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.length > 0 && descendants.every(child => {
      return this.checklistSelection.isSelected(child);
    });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: TodoItemFlatNode): TodoItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  isInArray(parentObj: any, item: any) {
    return parentObj.some((obj: any) => obj.id === item.id);
  }

  selectedItem(node: any) {
    this.selected = [];
    this.checklistSelection.selected.forEach((element: any) => {
      if ( ( element.childs && !element.childs!.length ) && !this.isInArray(this.selected, element)) {
        this.selected.push(element);
      }
    });
    this.selectedItems.emit(this.selected);
  }


  removeItem(obj: any): void {
    if(this.selected && this.selected.length){
      const index = this.selected.indexOf(obj);
      if (index >= 0) {
        this.selected.splice(index, 1);
        this.todoItemSelectionToggle(obj);
      }
      this.selectedItems.emit(this.selected);
    }
  }


  public hideLeafNode(node: TodoItemFlatNode): boolean {
    return this.isView
      ? true
      : new RegExp(this.searchText, 'i').test(node.name) === false;
  }
  public showParentNode(node: TodoItemFlatNode): boolean {
    const parentNode = this.getParentNode(node);
    if (parentNode) {
      return !this.hideLeafNode(parentNode);
    }
    return true;
  }
  public hideParentNode(node: TodoItemFlatNode): boolean {
    if(this.searchText)
    {
      this.treeControl.expandAll();
    } else {
      this.treeControl.collapseAll();
    }
    return this.treeControl
        .getDescendants(node)
        .filter(node => node.childs == null || node.childs.length === 0)
        .every(node => this.hideLeafNode(node));
  }

  // END TREE   configrations //////////////////////////////////////////

}
