
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Config } from '../models/config';

@Injectable({
  providedIn: 'root',
})
export class AssistanceRequestsService {
  constructor(private http: HttpClient) {}

  getListAssistanceRequests(filterModel: any): any {
    return this.http.get(`${environment.apiUrl}/AssistanceRequest/ListAssistanceRequests`, {
      params: filterModel,
    });
  }
  getRequestDetails(id: any): any {
    return this.http.get(`${environment.apiUrl}/AssistanceRequest/GetRequestDetails/` + id);
  }

}
