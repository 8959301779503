import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-basic-layout',
  templateUrl: './basic-layout.component.html'
})
export class BasicLayoutComponent implements OnInit {

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  logout() {
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/admin/dashboard/my-dashboard']);
    } else {
      this.authenticationService.logout();
      this.router.navigate(['/login']);
    }
  }
}
