import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminLayoutComponent } from './layout/admin-layout/admin-layout.component';
import { BasicLayoutComponent } from './layout/basic-layout/basic-layout.component';
import { AuthGuard } from './helpers/auth.guard';
import { BlankLayoutComponent } from './layout/blank-layout/blank-layout.component';

const routes: Routes = [
  {
    path: '',
    component: BasicLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./features/authentication/authentication.module').then(
            (m) => m.AuthenticationModule
          ),
      },
      {
        path: '404',
        loadChildren: () =>
          import('./features/not-found/not-found-routing.module').then(
            (m) => m.NotFoundRoutingModule
          ),
      },
    ],
  },
  {
    path: 'viewer',
    component: BlankLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./features/viewer/viewer.module').then(
            (m) => m.ZoomViewerModule
          ),
      },
    ],
  },
  {
    path: 'admin',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      // { path: 'myprofile', component: MyProfileComponent},
       {
        path: 'dashboard',
        loadChildren: () =>
          import('./features/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: 'kpi-dashboard',
        loadChildren: () =>
          import('./features/kpi-dashboard/kpi-dashboard.module').then(
            (m) => m.KpiDashboardModule
          ),
      },
      {
        path: 'config',
        loadChildren: () =>
          import('./features/configurations/configurations.module').then(
            (m) => m.ConfigurationsModule
          ),
      },
      {
        path: 'projects',
        loadChildren: () =>
          import('./features/projects/projects.module').then(
            (m) => m.ProjectsModule
          ),
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./features/users/users.module').then((m) => m.UsersModule),
      },
      {
        path: 'applicationsAndQuestions',
        loadChildren: () =>
          import(
            './features/questionnaires-questions/questionnaires-questions.module'
          ).then((m) => m.QuestionBankModule),
      },
      {
        path: 'requests',
        loadChildren: () =>
          import('./features/requests/expert-requests.module').then(
            (m) => m.ExpertRequestsModule
          ),
      },
      {
        path: 'experts',
        loadChildren: () =>
          import('./features/experts/experts.module').then(
            (m) => m.RequestsModule
          ),
      },
      {
        path: 'enterprises',
        loadChildren: () =>
          import('./features/enterprise/enterprise.module').then(
            (m) => m.EnterpriseModule
          ),
      },
      {
        path: 'matchmaking',
        loadChildren: () =>
          import('./features/matchmaking/matchmaking.module').then(
            (m) => m.MatchmakingModule
          ),
      },
      {
        path: 'cases',
        loadChildren: () =>
          import('./features/cases/cases.module').then((m) => m.CasesModule),
      },
      {
        path: 'support',
        loadChildren: () =>
          import('./features/support/support.module').then(
            (m) => m.SupportModule
          ),
      },
      {
        path: 'assistance-requests',
        loadChildren: () =>
          import(
            './features/assistance-requests/assistance-requests.module'
          ).then((m) => m.AssistanceRequestsModule),
      },
      {
        path: 'active-users',
        loadChildren: () =>
          import(
            './features/active-users/active-users.module'
          ).then((m) => m.ActiveUsersModule),
      },
    ],
  },
];

@NgModule({
  // imports: [RouterModule.forRoot(routes)],
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      onSameUrlNavigation: 'reload',
      scrollOffset: [0, 64],
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
