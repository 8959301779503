import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
export interface DialogData {
  data:any;
  from:any
}
@Component({
  selector: 'app-matchmaking-show-more',
  templateUrl: './matchmaking-show-more.component.html',
})
export class MatchmakingShowMoreComponent implements OnInit {
  list:any;
  from:any;

  constructor(
  @Inject(MAT_DIALOG_DATA) public data: DialogData,
   public dialog: MatDialog,) { }

  ngOnInit(): void {
    console.log(this.data);
    this.list = this.data.data;


  }


}
