<div class="header">
	<div class="app-sidebar__toggle">
		<a (click)="sidebarTrigger.emit()"
			class="open-toggle">
			<svg width="20.001"
				height="19"
				viewBox="0 0 20.001 19">
				<path id="menu-btn"
					d="M2157.5,3082a1.5,1.5,0,1,1,0-3h17a1.5,1.5,0,1,1,0,3Zm0-8a1.5,1.5,0,1,1,0-3h17a1.5,1.5,0,1,1,0,3Zm0-8a1.5,1.5,0,0,1,0-3h17a1.5,1.5,0,0,1,0,3Z"
					transform="translate(-2156 -3063)"
					fill="#446475"></path>
			</svg>
		</a>
	</div>
	<div class="logo-mobile">
		<img src="./assets/images/UNIDO-En-Logo.svg">
	</div>
	<div class="header-app-btns d-flex align-items-center justify-content-between">
		<a (click)="changeLanguage()"
			class="lang-btn">
			<img src="./assets/images/icons/language-icon.svg"
				alt>
			<span>
				{{ 'header.language' | translate }}
			</span>
		</a>


		<div class="header-part2 d-flex align-items-center ml-auto">
			<div class="notification-btn">
				<span (click)="openNotificationPanel()">
					<span class="notification-count">
						{{ notificationsResult.unSeenNotificationsCount }}
					</span>
					<svg [ngClass]="{swing: notificationsResult.unSeenNotificationsCount}"
						width="17"
						height="21"
						viewBox="0 0 17 21">
						<path id="notifications-icon"
							d="M-3257.219,11489.385v-.525h-5.052a.721.721,0,0,1-.729-.713.721.721,0,0,1,.729-.713h.631v-6.611a6.929,6.929,0,0,1,5.063-6.621,2.146,2.146,0,0,1-.255-1.016,2.214,2.214,0,0,1,2.235-2.187,2.213,2.213,0,0,1,2.234,2.188,2.145,2.145,0,0,1-.255,1.016,6.929,6.929,0,0,1,5.064,6.621v6.611h.825a.72.72,0,0,1,.728.713.72.72,0,0,1-.728.713h-5.149v.525a2.646,2.646,0,0,1-2.672,2.613A2.645,2.645,0,0,1-3257.219,11489.385Zm1.457,0a1.2,1.2,0,0,0,1.214,1.189,1.2,1.2,0,0,0,1.215-1.189v-.525h-2.428Zm6.752-1.951v-6.611a5.53,5.53,0,0,0-5.307-5.465,2.193,2.193,0,0,1-.279.018,2.208,2.208,0,0,1-.28-.018,5.53,5.53,0,0,0-5.306,5.465v6.611Zm-5.406-13.508a.777.777,0,0,0,.6-.74.78.78,0,0,0-.777-.76.78.78,0,0,0-.777.76.777.777,0,0,0,.6.74l.18,0Z"
							transform="translate(3263 -11470.998)"
							fill="#4990e1"></path>
					</svg>
				</span>
				<div [ngClass]="{active: notificationMenu}"
					class="notification-panel">
					<div [class.loading]="notifLoading"
						class="notification-header">
						<h3>
							{{ 'header.Notification' | translate }}
						</h3>
					</div>
					<div *ngIf="dataLoading"
						class="dataLoading">
						<mat-spinner class="m-auto"
							diameter="50"></mat-spinner>
					</div>
					<ng-container *ngIf="!dataLoading">
						<div *ngIf="!notificationsList.length"
							class="no-data text-center px-2 py-5">
							{{ 'header.You have no notification!' | translate }}
						</div>


						<div *ngIf="notificationsList.length"
							[scrollWindow]="false"
							[infiniteScrollDistance]="2"
							[infiniteScrollThrottle]="pageCount"
							(scrolled)="getNextPage()"
							class="notification-list"
							infiniteScroll>
							<div *ngFor="let notif of notificationsList"
								[ngClass]="{new: !notif.isSeen}"
								(click)="notificationMenu = false">


								<!-- ExpertApprovedMission || AddExpertFeedback || AddEnterpriseFeedback || AddFocalFeedback -->
								<a *ngIf="(notif.transactionType == 18 || notif.transactionType == 17)"
									[routerLink]="['/admin/projects', notif.requestId]">
									<p class="title">
										{{notif.notificationText}}
									</p>
									<span class="date">
										{{notif.notificationDate | date: "MMMM dd, y hh:mm aaa"}}
									</span>
								</a>
								<!-- ChangeDataByAdmin(myProfile)  [routerLink]="['/admin/myprofile']" -->
								<a *ngIf="(notif.transactionType == 3 || notif.transactionType == 5)">
									<p class="title">
										{{notif.notificationText}}
									</p>
									<span class="date">
										{{notif.notificationDate | date: "MMMM dd, y hh:mm aaa"}}
									</span>
								</a>


								<!-- Register || RejectRegisterRequest -->
								<a *ngIf="notif.transactionType == 0 || notif.transactionType === 2 || notif.transactionType === 20"
									[routerLink]="['/admin/requests/'+((notif.relatedObjectType == 0)?'expert-requests':'enterprise-requests'), notif.relatedObjectId]">
									<p class="title">
										{{notif.notificationText}}
									</p>
									<span class="date">
										{{notif.notificationDate | date: "MMMM dd, y hh:mm aaa"}}
									</span>
								</a>


								<!-- ApproveRegisterRequest || ApproveChangeDataRequest -->
								<!-- <a *ngIf="notif.transactionType == 1 || notif.transactionType == 6"
                                [routerLink]="['/admin/'+((notif.relatedObjectType == 0)?'expert':'enterprise'), notif.relatedObjectId]">
                                <p class="title">{{notif.notificationText}}</p>
                            </a> -->



								<!-- ChangeDataRequest = 4 -->
								<a *ngIf="notif.transactionType === 4"
									[routerLink]="['/admin/'+((notif.relatedObjectType == 0)?'experts':'enterprises'), notif.relatedObjectId]">
									<p class="title">
										{{notif.notificationText}}
									</p>
									<span class="date">
										{{notif.notificationDate | date: "MMMM dd, y hh:mm aaa"}}
									</span>
								</a>


								<a *ngIf="notif.transactionType === 13"
									[routerLink]="['/admin/cases/', notif.requestId]">
									<p class="title">
										{{notif.notificationText}}
									</p>
									<span class="date">
										{{notif.notificationDate | date: "MMMM dd, y hh:mm aaa"}}
									</span>
								</a>


								<a *ngIf="notif.transactionType === 23"
									[routerLink]="['/admin/assistance-requests/', notif.requestId]">
									<p class="title">
										{{notif.notificationText}}
									</p>
									<span class="date">
										{{notif.notificationDate | date: "MMMM dd, y hh:mm aaa"}}
									</span>
								</a>


								<a *ngIf="notif.transactionType === 24"
									[routerLink]="['/admin/assistance-requests/', notif.requestId]">
									<p class="title">
										{{notif.notificationText}}
									</p>
									<span class="date">
										{{notif.notificationDate | date: "MMMM dd, y hh:mm aaa"}}
									</span>
								</a>


								<a *ngIf="notif.transactionType === 8"
									[routerLink]="['/admin/assistance-requests/', notif.requestId]">
									<p class="title">
										{{notif.notificationText}}
									</p>
									<span class="date">
										{{notif.notificationDate | date: "MMMM dd, y hh:mm aaa"}}
									</span>
								</a>


								<a *ngIf="notif.transactionType === 26"
									[routerLink]="['/admin/cases/', notif.requestId]">
									<p class="title">
										{{notif.notificationText}}
									</p>
									<span class="date">
										{{notif.notificationDate | date: "MMMM dd, y hh:mm aaa"}}
									</span>
								</a>


								<a *ngIf="notif.transactionType === 27"
									[routerLink]="['/admin/cases/', notif.requestId]">
									<p class="title">
										{{notif.notificationText}}
									</p>
									<span class="date">
										{{notif.notificationDate | date: "MMMM dd, y hh:mm aaa"}}
									</span>
								</a>
								<a *ngIf="notif.transactionType === 29" (click)="routerToSupport()">
									<p class="title">
										{{notif.notificationText}}
									</p>
									<span class="date">
										{{notif.notificationDate | date: "MMMM dd, y hh:mm aaa"}}
									</span>
								</a>
								<!-- ChangeDataRequest = 4 -->
								<!-- <a *ngIf="notif.transactionType === 4"
                [routerLink]="['/admin/profile-requests/'+((notif.relatedObjectType == 0)?'expertprofile':'enterpriseprofile'), notif.relatedObjectId]"
                [queryParams]="{message:  notif.relatedObjectId }">
                <p class="title">{{notif.notificationText}}</p>
              </a> -->


								<!-- Matchmaking || ExpertRejectedMission || WithDrawed || UpdateAssessmentRequest -->
								<!-- <a *ngIf="notif.transactionType == 8 || notif.transactionType == 9 || notif.transactionType == 11 || notif.transactionType == 15 || notif.transactionType == 17"
                                [routerLink]="['/admin/matchmaking', notif.requestId]">
                                <p class="title">{{notif.notificationText}}</p>
                            </a> -->


								<!-- ExpertApprovedMission || AddExpertFeedback || AddEnterpriseFeedback || AddFocalFeedback -->
								<!-- <a *ngIf="notif.transactionType == 10 || notif.transactionType == 12 || notif.transactionType == 13 || notif.transactionType == 14"
                                [routerLink]="['/admin/cases', notif.requestId]">
                                <p class="title">{{notif.notificationText}}</p>
                            </a> -->
							</div>
						</div>
					</ng-container>
				</div>
			</div>
			<div (click)="userMenu = !userMenu; notificationMenu = false;"
				class="userAccount"
				id="userAccountDrop">
				<div class="profile-image">
					<img *ngIf="currentUser.profilePicture"
						[src]="currentUser.profilePicture"
						(error)="currentUser.profilePicture = ''">
					<span *ngIf="!currentUser.profilePicture">
						{{ initName(fullName) | uppercase }}
					</span>
				</div>
				<div class="user-name">
					<span class="mr-2">
						{{ 'header.Hello,' | translate }} {{fullName}}
					</span>
					<svg *ngIf="!userMenu"
						width="8"
						height="5"
						viewBox="0 0 8 5">
						<path id="arrow-down"
							d="M-2.313-6.729.75-3.627l3.063-3.1.937.949-4,4.051-4-4.051Z"
							transform="translate(3.25 6.729)"
							fill="#999"></path>
					</svg>
					<svg *ngIf="userMenu"
						width="8"
						height="5"
						viewBox="0 0 8 5">
						<path id="arrow-down"
							d="M-2.313-1.729.75-4.83l3.063,3.1.937-.949-4-4.051-4,4.051Z"
							transform="translate(3.25 6.729)"
							fill="#999"></path>
					</svg>
				</div>


				<div [ngClass]='{"active": userMenu}'
					class="custom-dropdown-menu">
					<ul>
						<!-- <li><a [routerLink]="['/admin/myprofile']">
                <svg width="14" height="17" viewBox="0 0 14 17">
                  <path id="my-profile-icon"
                    d="M7,8.5a12.35,12.35,0,0,1,5,1c1.3.6,2,1.4,2,2.4v1.7H0V11.9A2.954,2.954,0,0,1,2,9.5,12.35,12.35,0,0,1,7,8.5ZM2.6,15.3H4.4V17H2.6ZM7,0A3.74,3.74,0,0,1,9.5,1a2.965,2.965,0,0,1,1,2.4,2.965,2.965,0,0,1-1,2.4A3.74,3.74,0,0,1,7,6.8a3.317,3.317,0,0,1-2.5-1,3.1,3.1,0,0,1-1-2.4A2.965,2.965,0,0,1,4.5,1,3.74,3.74,0,0,1,7,0ZM6.1,15.3H7.8V17H6.1Zm3.5,0h1.8V17H9.6Z"
                    fill="#999999" />
                </svg>{{ 'header.My-Profile' | translate }}</a></li> -->
						<li>
							<a (click)="signOut()">
								<svg width="17.001"
									height="17.006"
									viewBox="0 0 17.001 17.006">
									<path id="logout-icon"
										d="M6223.19,12103h-13.4a1.815,1.815,0,0,1-1.8-1.808v-13.383a1.815,1.815,0,0,1,1.811-1.808h13.4a1.817,1.817,0,0,1,1.792,1.808v4.146l-1.659-1.559v-2.74h-13.687v13.575h13.687v-2.625l1.659-1.461v4.165a1.766,1.766,0,0,1-.529,1.267,1.69,1.69,0,0,1-1.123.43C6223.3,12103.006,6223.243,12103,6223.19,12103Zm-4.514-5.039,2.691-2.688h-9.562v-1.54h9.562l-2.691-2.691,1.167-1.169,4.52,4.629-4.52,4.629Z"
										transform="translate(-6208 -12086)"
										fill="#999"></path>
								</svg>
								{{ 'header.SignOut' | translate }}
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</div>
<div *ngIf="userMenu || notificationMenu"
	id="popOverlay"></div>
