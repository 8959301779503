<div class="contact-info-container">
  <div class="contact-info-head">
    <h2>
      {{ "forms.contact-via" | translate }}
    </h2>
    <button (click)="addContactItem(null, true)" type="button" class="add-more-btn">
      <svg width="20" height="20" viewBox="0 0 20 20">
        <g id="add-icon" transform="translate(-1161 -629)">
          <circle id="bg" cx="10" cy="10" r="10" transform="translate(1161 629)" fill="#4990e1"></circle>
          <path id="plus-icon"
            d="M9.318,4.091H5.909V.682A.682.682,0,0,0,5.227,0H4.773a.682.682,0,0,0-.682.682V4.091H.682A.682.682,0,0,0,0,4.773v.455a.682.682,0,0,0,.682.682H4.091V9.318A.682.682,0,0,0,4.773,10h.455a.682.682,0,0,0,.682-.682V5.909H9.318A.682.682,0,0,0,10,5.227V4.773A.682.682,0,0,0,9.318,4.091Zm0,0"
            transform="translate(1166 634)" fill="#fff"></path>
        </g>
      </svg>
      <span>
        {{ "forms.AddMore" | translate }}
      </span>
    </button>
  </div>
  <div class="contact-info-content">
    <div [formGroup]="form" class="add-lessons-form">
      <ng-container formArrayName="contactMethods">
        <ng-container *ngFor="let methodsForm of contactMethods.controls; let i = index">
          <div [formGroupName]="i">
            <div *ngIf="activeIndex !== i" (click)="activeIndex = i" class="static-item">
              <span *ngIf="!contactMethods.controls[i].get('canedit')?.value">
                {{ "forms.select-contact-method" | translate }}
              </span>
              <span *ngIf="contactMethods.controls[i].get('canedit')?.value">
                {{ "forms.ContactMethod" | translate }}:
                <span class="label">
                  ( {{ contactMethods.controls[i].get("methodName")?.value }}:
                </span>
                <strong>
                  {{ contactMethods.controls[i].get("filed")?.value?.number
                  ? contactMethods.controls[i].get("filed")?.value?.number
                  : contactMethods.controls[i].get("filed")?.value
                  }}
                  )
                </strong>
              </span>
              <svg width="8.001" height="15" viewBox="0 0 8.001 15">
                <path id="expand-contact-icon"
                  d="M-8881-618.05l.938-.95,3.063,3.1,3.061-3.1.94.95-4,4.05Zm0-6.9,4-4.052,4,4.052-.94.947-3.061-3.1-3.062,3.1Z"
                  transform="translate(8881 629)" fill="#999"></path>
              </svg>
            </div>
            <div *ngIf="activeIndex == i" class="contact-method mb-3">
              <div (click)="activeIndex = null" class="item-head">
                <span class="item-label">
                  {{ "forms.ContactMethod" | translate }}
                </span>
                <svg width="8.001" height="15" viewBox="0 0 8.001 15">
                  <path id="expand-contact-icon"
                    d="M-8881-618.05l.938-.95,3.063,3.1,3.061-3.1.94.95-4,4.05Zm0-6.9,4-4.052,4,4.052-.94.947-3.061-3.1-3.062,3.1Z"
                    transform="translate(8881 629)" fill="#999"></path>
                </svg>
              </div>
              <!-- ADD -->
              <ng-container *ngIf="!contactMethods.controls[i].get('canedit')?.value">
                <mat-radio-group class="w-100 button-row" formControlName="selectedMethod">
                  <mat-radio-button *ngFor="let communicationType of communicationTypes" [value]="communicationType.id"
                    (click)="
                      selectContactMethod(communicationType, i);
                      contactMethods.controls[i].get('filed')?.setValue(null);contactMethods.controls[i].get('methodName')?.setValue(communicationType.name);
                    ">
                    {{ communicationType.name }}
                  </mat-radio-button>
                </mat-radio-group>
                <!-- <div class="button-row" *ngFor="let communicationType of communicationTypes">
                  <button type="button" [ngClass]="{'active': selectedMethod.id == communicationType.id}"
                    class="button-contact" mat-flat-button
                    (click)="selectContactMethod(communicationType , i );contactMethods.controls[i].get('filed')?.setValue(null)">
                    {{communicationType.name}}
                  </button>
                </div> -->
              </ng-container>
              <ng-container *ngIf="contactMethods.controls[i].get('canedit')?.value">
                <div class="button-row">
                  <button type="button" class="button-contact active" mat-flat-button>
                    {{ contactMethods.controls[i].get("methodName")?.value }}
                  </button>
                </div>
              </ng-container>

              <ng-container *ngIf="
                  contactMethods.controls[i].get('selectedMethod')?.value ==
                    1 ||
                  contactMethods.controls[i].get('selectedMethod')?.value == 3
                ">
                <div class="mb-4 position-relative">
                  <ngx-intl-tel-input [enableAutoCountrySelect]="true" [enableAutoCountrySelect]="true"
                    [enablePlaceholder]="true" [maxLength]="20" [numberFormat]="PhoneNumberFormat.International"
                    [phoneValidation]="true" [searchCountryField]="[
                      SearchCountryField.Iso2,
                      SearchCountryField.Name,
                      SearchCountryField.DialCode
                    ]" [searchCountryFlag]="true" [searchCountryFlag]="true" [selectFirstCountry]="false"
                    [selectedCountryISO]="
                      contactMethods.controls[i].get('countryCode')?.value
                    " [separateDialCode]="separateDialCode" formControlName="filed" name="mobile">
                  </ngx-intl-tel-input>
                  <mat-error *ngIf="
                      contactMethods.controls[i].get('filed')?.touched &&
                      contactMethods.controls[i].get('filed')?.invalid &&
                      !contactMethods.controls[i].get('filed')?.pristine
                    ">
                    <div *ngIf="
                        contactMethods.controls[i].get('filed')?.errors
                          ?.required
                      " class="intl-validation">
                      {{ "forms.Required" | translate }}
                    </div>
                    <div
                      *ngIf="contactMethods.controls[i].get('filed')?.errors?.validatePhoneNumber && !contactMethods.controls[i].get('filed')?.errors?.required"
                      class="intl-validation">
                      {{ "forms.validMobile" | translate }}
                    </div>
                  </mat-error>
                </div>
              </ng-container>

              <ng-container *ngIf="
                  contactMethods.controls[i].get('selectedMethod')?.value !=
                    1 &&
                  contactMethods.controls[i].get('selectedMethod')?.value != 3
                ">
                <mat-form-field class="form-group" appearance="fill">
                  <mat-label *ngIf="!contactMethods.controls[i].get('canedit')?.value">
                    {{ selectedMethod.name }}
                  </mat-label>
                  <mat-label *ngIf="contactMethods.controls[i].get('canedit')?.value">
                    {{ contactMethods.controls[i].get("methodName")?.value }}
                  </mat-label>
                  <input matInput formControlName="filed">
                  <mat-error *ngIf="hasError('required', 'filed', i)">
                    {{ "forms.Required" | translate }}
                  </mat-error>
                  <mat-error *ngIf="hasError('maxlength', 'filed', i)">
                    {{ "forms.maxLength50" | translate }}
                  </mat-error>
                  <mat-error *ngIf="hasError('pattern', 'filed', i)">
                    {{ "forms.NotValid" | translate }}
                  </mat-error>
                </mat-form-field>
              </ng-container>

              <div class="btn-conatiner">
                <button [matTooltip]="'experts.Delete' | translate" (click)="removeContactItem(i)" type="button"
                  class="btn-delete" matTooltipClass="bg-tooltip">
                  <svg width="20" height="26" viewBox="0 0 20 26">
                    <path id="delete-icon"
                      d="M5617.238,13487a4.27,4.27,0,0,1-4.319-4.21v-13.66a.884.884,0,0,1,.9-.874h16.371a.884.884,0,0,1,.9.874v13.66a4.271,4.271,0,0,1-4.32,4.21Zm6.918-13.48v8.216a1.1,1.1,0,0,0,2.2,0v-8.216a1.1,1.1,0,0,0-2.2,0Zm-6.515,0v8.216a1.1,1.1,0,0,0,2.2,0v-8.216a1.1,1.1,0,0,0-2.2,0Zm-5.078-6.27a.555.555,0,0,1-.562-.546v-1.7a2.683,2.683,0,0,1,2.713-2.647h3.908v-.33a1.044,1.044,0,0,1,1.057-1.028h4.645a1.044,1.044,0,0,1,1.057,1.028v.33h3.908a2.684,2.684,0,0,1,2.713,2.647v1.7a.557.557,0,0,1-.563.546Z"
                      transform="translate(-5612.001 -13461.004)" fill="#b6c3d4"></path>
                  </svg>
                </button>
                <button [matTooltip]="'general.Add' | translate" (click)="addNewContact(i)" mat-raised-button
                  type="button" color="primary" matTooltipClass="bg-tooltip">
                  <svg class="d-block" width="19" height="14" viewBox="0 0 19 14">
                    <path id="check-mark"
                      d="M155.509,169.385a1.615,1.615,0,0,1,0,2.274L144.8,182.443a1.589,1.589,0,0,1-2.258,0l-5.1-5.134a1.615,1.615,0,0,1,0-2.273,1.588,1.588,0,0,1,2.257,0l3.969,4,9.58-9.648A1.589,1.589,0,0,1,155.509,169.385Z"
                      transform="translate(-136.976 -168.914)" fill="#fff"></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <div *ngIf="!contactMethodItems.length && isSubmited && isRequired" class="has-error">
    {{ "forms.Required" | translate }}
  </div>
</div>