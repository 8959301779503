// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  googleSiteKey: '6Leb6VwkAAAAAPFbm0YX9tK7UYglY8ilegEIVpfZ',
  environmentName: "test",

  // test
  apiUrl: 'https://unido-emiptestapi.expertapps.com.sa/api',
  signalRUrl: 'https://unido-emiptestslr.expertapps.com.sa/hubs',
  loginUrl: 'https://unido-emiptestapi.expertapps.com.sa/api/Authentication/Login',
  adminApiUrl: 'https://unido-emiptestapi.expertapps.com.sa/api/admin',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
