<div class="basic">
    <header>
        <div class="container">
            <!-- <a [routerLink]="['']" class="logo">
                <img src="./assets/images/UNIDO-En-Logo.svg" alt="">
            </a>         -->
            <a (click)="logout()" class="logo">
                <img src="./assets/images/UNIDO-En-Logo.svg" alt="">
            </a>
        </div>
    </header>

    <router-outlet></router-outlet>

    <footer>
        <div class="container">
            <div>
                <div class="copyRight">
                    {{ 'footer.copy-right' | translate }}
                </div>
            </div>
        </div>
    </footer>
</div>
