<mat-dialog-content class="createUserDialog">
  <section class="createUser">

    <div *ngIf='dataLoading' class="dataLoading">
      <mat-spinner class="m-auto" diameter="50"></mat-spinner>
    </div>

    <ng-container *ngIf='!dataLoading'>
      <div class="auth-card pb-0" *ngIf='!dataLoading'>

        <div class="d-flex align-items-center justify-content-between">
          <div>
            <h2 class="title">{{ 'enterprises.Update enterprise data' | translate}}</h2>
          </div>

          <button class="close" (click)="closeDialog()">
            <mat-icon>close</mat-icon>
          </button>
        </div>

        <ng-container>
          <h4 class="sub-title mt-3">{{ 'enterprises.Few steps to update enterprise data' | translate}}</h4>

          <div class="stepper-head">
            <div class="paging-no">
              <span>{{createPages[pageIndex].index}}</span> {{ 'general.of' | translate}} <span> {{ stepsCount }}</span>
              <mat-progress-spinner diameter="50" [value]="(createPages[pageIndex].index / stepsCount) * 100">
              </mat-progress-spinner>
            </div>
            <h3>{{createPages[pageIndex].title}}</h3>
          </div>
        </ng-container>
      </div>
      <mat-horizontal-stepper linear #stepper (selectionChange)="stepChanged($event);">
        <mat-step [stepControl]="formGroupAppInfo">
          <form [formGroup]="formGroupAppInfo" class="mx-4">
            <div class="dialog-body longer row">
              <div class="col-12">
                <div class="row">
                  <div class="col-6">
                    <mat-form-field class="form-group" appearance="fill">
                      <mat-label>{{ 'enterprises.Applicant First Name' | translate}}</mat-label>
                      <input matInput formControlName="firstName" maxlength="100">
                      <mat-error *ngIf="hasError(formGroupAppInfo, 'firstName', 'required')">{{ 'forms.Required' |
                        translate
                        }}
                      </mat-error>
                      <mat-error *ngIf="hasError(formGroupAppInfo, 'firstName', 'pattern')">
                        {{ 'forms.Enter valid First Name' | translate}}
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-6">
                    <mat-form-field class="form-group" appearance="fill">
                      <mat-label>{{ 'enterprises.Applicant Last Name' | translate}}</mat-label>
                      <input matInput formControlName="lastName" maxlength="100">
                      <mat-error *ngIf="hasError(formGroupAppInfo, 'lastName', 'required')">{{ 'forms.Required' |
                        translate
                        }}
                      </mat-error>
                      <mat-error *ngIf="hasError(formGroupAppInfo, 'lastName', 'pattern')">
                        {{ 'forms.Enter valid Last Name' | translate}}
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <mat-form-field class="form-group">
                      <mat-label>{{ 'enterprises.Applicant Email Address' | translate }}</mat-label>
                      <input matInput formControlName="email" (keyup)="checkEmail()" required
                        placeholder="pat@example.com" maxlength="100">
                      <mat-error *ngIf="hasError(formGroupAppInfo, 'email', 'required')">{{ 'forms.Required' |
                        translate
                        }}
                      </mat-error>
                      <mat-error *ngIf="hasError(formGroupAppInfo, 'email', 'pattern')">{{ 'forms.Invalid-Email' |
                        translate }}
                      </mat-error>
                      <mat-error *ngIf="hasError(formGroupAppInfo, 'email', 'emailTaken')">{{ 'forms.uniqe-email' |
                        translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <mat-form-field class="form-group" appearance="fill">
                  <mat-label>{{ 'enterprises.Applicant Position' | translate }} </mat-label>
                  <input type="text" aria-label="Number" matInput formControlName="positions"
                    [matAutocomplete]="postionList" maxlength="100" required>
                  <mat-autocomplete autoActiveFirstOption #postionList="matAutocomplete" [displayWith]="displayFn">
                    <mat-option *ngFor="let position of listpositions" [value]="position">
                      {{position.name}}
                    </mat-option>
                  </mat-autocomplete>
                  <mat-error *ngIf="hasError(formGroupAppInfo, 'positions', 'required')">{{ 'forms.Required' | translate
                    }}</mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="form-group gray">
              <mat-checkbox (change)="addApplicantName()" formControlName="contactPersonHasApplicantName">
                {{ "dashboard.Same contact person name and applicant name" | translate}}
              </mat-checkbox>
            </div>
            <div class="pb-5 pt-3">
              <div class="btn-container">
                <button mat-raised-button color="primary" class="ml-auto" matStepperNext
                  (click)="checkNext(formGroupAppInfo, 'formGroupAppInfo')">{{
                  'general.Next' |
                  translate}}</button>
              </div>
            </div>
          </form>
        </mat-step>
        <mat-step [stepControl]="formGroupContactInfo" *ngIf="true">
          <form [formGroup]="formGroupContactInfo" class="mx-4">
            <div class="dialog-body longer row">
              <div class="col-12">
                <!-- Contact Person Name field -->
                <div class="row">
                  <div class="col-6">
                    <mat-form-field class="form-group" appearance="fill">
                      <mat-label>{{ 'enterprises.Contact Person First Name' | translate }} </mat-label>
                      <input matInput formControlName="contactPersonFirstName" maxlength="100">
                      <mat-error *ngIf="hasError(formGroupContactInfo, 'contactPersonFirstName', 'required')">{{
                        'forms.Required' |
                        translate
                        }}
                      </mat-error>
                      <mat-error *ngIf="hasError(formGroupContactInfo, 'contactPersonFirstName', 'pattern')">
                        {{ 'forms.Enter valid First Name' | translate}}
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-6">
                    <mat-form-field class="form-group" appearance="fill">
                      <mat-label>{{ 'enterprises.Contact Person Last Name' | translate }}</mat-label>
                      <input matInput formControlName="contactPersonLastName" maxlength="100">
                      <mat-error *ngIf="hasError(formGroupContactInfo, 'contactPersonLastName', 'required')">{{
                        'forms.Required' |
                        translate
                        }}
                      </mat-error>
                      <mat-error *ngIf="hasError(formGroupContactInfo, 'contactPersonLastName', 'pattern')">
                        {{ 'forms.Enter valid Last Name' | translate}}
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <!-- End Contact Person Name field -->

                <!-- Languages field -->
                <mat-form-field class="form-group" appearance="fill">
                  <mat-label>{{ 'forms.Languages-Communication' | translate }}</mat-label>
                  <mat-chip-list #chipLanguagesList required>
                    <mat-chip *ngFor="let lang of languages" [selectable]="true"
                      (removed)="remove(lang, languages, 'languages' , formGroupContactInfo)">
                      {{lang.name}}
                      <mat-icon matChipRemove>
                        <svg width="7" height="7" viewBox="0 0 7 7">
                          <path id="remove-btn"
                            d="M3.217-7.954l-.7-.7-2.8,2.8-2.8-2.8-.7.7,2.8,2.8-2.8,2.8.7.7,2.8-2.8,2.8,2.8.7-.7-2.8-2.8Z"
                            transform="translate(3.783 8.658)" fill="#999" />
                        </svg>
                      </mat-icon>
                    </mat-chip>
                    <input #languageInput matInput formControlName="languages" [matAutocomplete]="languageAuto"
                      [matChipInputFor]="chipLanguagesList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                  </mat-chip-list>
                  <mat-autocomplete #languageAuto="matAutocomplete" (optionSelected)="selectedLang($event)">
                    <mat-option *ngFor="let lang of filteredLanguages | async" [value]="lang">
                      <span>{{lang.name}}</span>
                    </mat-option>
                  </mat-autocomplete>
                  <p class="has-error no-mat-error" *ngIf="!formGroupContactInfo.get('languages')!.valid">
                    {{langChipsErrorMsg}}</p>
                </mat-form-field>
                <!-- End Languages field -->

                <!-- contactInfo field -->
                <div *ngIf="dataSource">
                  <!-- <app-contact-info-form class="form-group" [updatedData]="dataSource.contactInfo" [isRequired]='true'
                    [isSubmited]='isSubmitted' (selectedMethods)="updateCommunicationsField($event)"
                    [showNewItem]='true'>
                  </app-contact-info-form> -->
                  <app-contact-info-reactive-form class="form-group" [updatedData]="dataSource.contactInfo"
                    [isRequired]='true' [isSubmited]='isSubmitted'
                    (selectedMethods)="updateCommunicationsField($event)">
                  </app-contact-info-reactive-form>
                </div>
                <!-- End contactInfo field -->

              </div>
            </div>
            <div class="pb-5 pt-3">
              <div class="btn-container">
                <button mat-raised-button color="grey" type="button" matStepperPrevious
                  (click)='pageIndex = pageIndex-1'>{{
                  'general.Back' | translate}}</button>
                <button mat-raised-button color="primary" type="button" class="ml-auto" matStepperNext
                  (click)="checkNext(formGroupContactInfo, 'formGroupContactInfo')">{{ 'general.Next' |
                  translate}}</button>
              </div>
            </div>
          </form>
        </mat-step>
        <mat-step [stepControl]="formGroupEnterpriseDetails" *ngIf="true">
          <form [formGroup]="formGroupEnterpriseDetails" class="mx-4">
            <div class="dialog-body longer row">
              <div class="col-12">
                <div class="row">
                  <div class="col-12">
                    <mat-form-field class="form-group" appearance="fill">
                      <mat-label>{{ 'forms.EnterpriseName' | translate}}</mat-label>
                      <input matInput formControlName="enterpriseName" maxlength="100"
                        (input)="checkDistinctEnterprise()">
                      <mat-error *ngIf="hasError(formGroupEnterpriseDetails, 'enterpriseName', 'required')">{{
                        'forms.Required'
                        |
                        translate
                        }}
                      </mat-error>
                      <mat-error *ngIf="hasError(formGroupEnterpriseDetails, 'enterpriseName', 'pattern')">
                        {{ 'forms.Enter valid Name' | translate}}
                      </mat-error>
                    </mat-form-field>
                    <p class="has-error no-mat-error"
                      *ngIf="isduplicatedEnterpise && !hasError(formGroupEnterpriseDetails, 'enterpriseName', 'pattern') && !hasError(formGroupEnterpriseDetails, 'enterpriseName', 'required')"
                      style="margin-top: -21px;margin-bottom: 11px;">{{ 'auth.Existed Before' | translate }} </p>
                  </div>
                </div>

                <!-- Country field -->
                <div class="row">
                  <div class="col-12">
                    <mat-form-field class="form-group">
                      <mat-label>{{ 'users.Resident Country' | translate}}</mat-label>
                      <mat-select formControlName="country"
                        (selectionChange)="getCities($event , true);checkDistinctEnterprise()">
                        <input matInput [placeholder]="'forms.Country-Residence' | translate" aria-label="State"
                          (keyup)="getCountries()" type="search" [(ngModel)]="countrySearch"
                          [ngModelOptions]="{standalone: true}" class="searchInput">
                        <mat-option [value]="country.id" *ngFor="let country of allCountries">
                          <div class="img-container">
                            <img *ngIf="country.logoUrl" [src]="country.logoUrl" height="25">
                            <img *ngIf="!country.logoUrl" [src]="'./assets/images/empty-flag.png'" height="25">
                          </div>
                          <span>{{country.name}}</span>
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="hasError(formGroupEnterpriseDetails, 'country', 'required')">{{
                        'forms.Required' |
                        translate }}
                      </mat-error>
                    </mat-form-field>

                    <p class="has-error no-mat-error"
                      *ngIf="isduplicatedEnterpise && !hasError(formGroupEnterpriseDetails, 'country', 'required')"
                      style="margin-top: -21px;margin-bottom: 11px;">{{ 'auth.Existed Before' | translate }} </p>
                  </div>
                </div>
                <!-- End Country field -->

                <!-- Citys field -->
                <div class="row">
                  <div class="col-12">
                    <mat-form-field class="form-group">
                      <mat-label>{{ 'forms.City' | translate}}</mat-label>
                      <mat-select formControlName="citys" (selectionChange)="checkDistinctEnterprise()">
                        <input matInput placeholder="City" aria-label="State" (keyup)="getCities($event , false)"
                          type="search" [(ngModel)]="searchCityText" [ngModelOptions]="{standalone: true}"
                          class="searchInput">
                        <mat-option [value]="city.id" *ngFor="let city of allCitys">
                          {{city.name}}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="hasError(formGroupEnterpriseDetails, 'citys', 'required')">{{
                        'forms.Required' |
                        translate }}
                      </mat-error>
                    </mat-form-field>
                    <p class="has-error no-mat-error"
                      *ngIf="isduplicatedEnterpise && !hasError(formGroupEnterpriseDetails, 'citys', 'required')"
                      style="margin-top: -21px;margin-bottom: 11px;">{{ 'auth.Existed Before' | translate }} </p>
                  </div>
                </div>
                <!-- End Citys field -->
                <!-- Address field -->
                <div class="row">
                  <div class="col-12">
                    <mat-form-field class="form-group" appearance="fill">
                      <mat-label>{{ 'forms.Adress' | translate}}</mat-label>
                      <textarea matInput formControlName="address" maxlength="500"></textarea>
                      <mat-error *ngIf="hasError(formGroupEnterpriseDetails, 'address', 'required')">{{ 'forms.Required'
                        | translate}}
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <!-- End Address field -->

                <!-- Website field -->
                <div class="row">
                  <div class="col-12">
                    <mat-form-field class="form-group" appearance="fill">
                      <mat-label>{{ 'forms.Website' | translate}}</mat-label>
                      <input matInput formControlName="website">
                      <mat-error *ngIf="hasError(formGroupEnterpriseDetails, 'website', 'required')">{{'forms.Required'
                        | translate}}</mat-error>
                      <mat-error *ngIf="hasError(formGroupEnterpriseDetails, 'website', 'pattern')">
                        {{ 'forms.InvalidURL' | translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <!-- End Website field -->

                <!-- Registration ID field -->
                <div class="row">
                  <div class="col-12">
                    <mat-form-field class="form-group" appearance="fill">
                      <mat-label>{{ 'forms.Tax-ID' | translate}}</mat-label>
                      <input matInput formControlName="registrationId" type="text" maxlength="40"
                        (keyup)="getDistinctTaxOrRegirtrationId()">
                      <mat-error *ngIf="hasError(formGroupEnterpriseDetails, 'registrationId', 'required')">
                        {{'forms.Required' | translate}}</mat-error>
                      <mat-error *ngIf="hasError(formGroupEnterpriseDetails, 'registrationId', 'pattern')">
                        {{ 'forms.Enter-valid' | translate }} {{ 'forms.Tax-ID' | translate }}</mat-error>
                    </mat-form-field>
                    <p class="has-error no-mat-error"
                      *ngIf="isduplicatedTax && !hasError(formGroupEnterpriseDetails, 'registrationId', 'required') && !hasError(formGroupEnterpriseDetails, 'registrationId', 'pattern')"
                      style="margin-top: -21px;margin-bottom: 11px;">{{ 'enterprises.please enter new Tax-ID' |
                      translate }} </p>
                  </div>
                </div>

                <!-- End Registration ID field -->

                <!-- Enterprise Size field -->
                <div class="row form-group">
                  <div class="col-12">
                    <p class="form-hint">{{ 'enterprises.Enterprise Size' | translate}}</p>
                  </div>
                  <div class="col-12">
                    <mat-radio-group formControlName="enterpriseSize">
                      <mat-radio-button [value]="0"> {{ 'enterprises.Micro' | translate}}</mat-radio-button>
                      <mat-radio-button [value]="1"> {{ 'enterprises.Small' | translate}}</mat-radio-button>
                      <mat-radio-button [value]="2"> {{ 'enterprises.Medium' | translate}}</mat-radio-button>
                      <mat-radio-button [value]="3"> {{ 'enterprises.Large' | translate}}</mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>
                <!-- End Enterprise Size field -->

                <!-- No. of Employees field -->
                <div class="row">
                  <div class="col-12">
                    <mat-form-field class="form-group" appearance="fill">
                      <mat-label>{{ 'enterprises.NumperEmployees' | translate}}</mat-label>
                      <mat-select formControlName="employeeNumber">
                        <mat-option [value]="el.id" *ngFor="let el of listCompaniesSizes">
                          {{el.name}}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="hasError(formGroupEnterpriseDetails, 'employeeNumber', 'required')">{{
                        'forms.Required' |
                        translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <!-- End No. of Employees field -->
                <!-- services field -->
                <mat-form-field class="form-group" appearance="fill">
                  <mat-label>{{ 'forms.ServicesProvided' | translate}} </mat-label>
                  <!-- <mat-icon matSuffix (click)="showServicesDrop = true" style="opacity: 0.5; cursor: pointer;">
                        expand_more</mat-icon> -->
                  <mat-chip-list #chipServicesList required>
                    <mat-chip *ngFor="let elm of services" [selectable]="true"
                      (removed)="remove(elm, services, 'services' ,formGroupEnterpriseDetails )">
                      {{elm.name}}
                      <mat-icon matChipRemove>
                        <svg width="7" height="7" viewBox="0 0 7 7">
                          <path id="remove-btn"
                            d="M3.217-7.954l-.7-.7-2.8,2.8-2.8-2.8-.7.7,2.8,2.8-2.8,2.8.7.7,2.8-2.8,2.8,2.8.7-.7-2.8-2.8Z"
                            transform="translate(3.783 8.658)" fill="#999" />
                        </svg>
                      </mat-icon>
                    </mat-chip>
                    <input #serviceInput matInput formControlName="services" [matAutocomplete]="serviceAuto"
                      [matChipInputFor]="chipServicesList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                      (matChipInputTokenEnd)="addService($event)">
                  </mat-chip-list>
                  <mat-autocomplete #serviceAuto="matAutocomplete" (optionSelected)="selectedService($event)">
                    <mat-option *ngFor="let elm of filteredServices | async" [value]="elm">
                      <span>{{elm.name}}</span>
                    </mat-option>
                  </mat-autocomplete>
                  <p class="has-error no-mat-error" *ngIf="!formGroupEnterpriseDetails.get('services')!.valid">
                    {{serviceChipsErrorMsg}}</p>
                </mat-form-field>
                <div class="note">
                  <span class="icon mr-2">
                    <svg height="16" viewBox="0 0 16 16" width="16">
                      <path
                        d="M3244,10174a8,8,0,1,1,8,8A8.01,8.01,0,0,1,3244,10174Zm1.455,0a6.545,6.545,0,1,0,6.545-6.545A6.552,6.552,0,0,0,3245.454,10174Zm5.818,3.88v-4.364a.727.727,0,1,1,1.455,0v4.364a.727.727,0,1,1-1.455,0Zm-.242-7.516a.969.969,0,1,1,.969.969A.971.971,0,0,1,3251.03,10170.362Z"
                        fill="#bbb" id="info" transform="translate(-3244 -10165.998)"></path>
                    </svg>
                  </span>
                  <p>{{ 'forms.pressEnter' | translate}}</p>
                </div>
                <!-- End services field -->

              </div>
            </div>
            <div class="pb-5 pt-3">
              <div class="btn-container">
                <button mat-raised-button color="grey" matStepperPrevious type="button"
                  (click)='pageIndex = pageIndex-1'>{{
                  'general.Back' | translate}}</button>
                <button mat-raised-button color="primary" type="button" class="ml-auto" matStepperNext
                  (click)="checkNext(formGroupEnterpriseDetails, 'formGroupEnterpriseDetails')">{{ 'general.Next' |
                  translate}}</button>
              </div>
            </div>

          </form>
        </mat-step>

        <mat-step [stepControl]="additionalInforGroup" *ngIf="true">
          <form [formGroup]="additionalInforGroup" class="additionalInforGroup mx-4">
            <div class="dialog-body longer row px-3">
              <div class="col-12 ">
                <div class="row">
                  <ng-container formArrayName="radioControlsGroup">
                    <div *ngFor="let field of radioControlsGroup.controls; let i = index" class="form-group">
                      <ng-container *ngIf="field.value.questionTypeCode == 8" [formGroupName]="i">
                        <label>{{field.value.text}}</label>
                        <mat-radio-group required formControlName="radioControl">
                          <ng-container *ngFor="let option of field.value.options;let optionIndex = index">
                            <mat-radio-button
                              (change)="addAnswer(field.value.id , field.value.questionTypeCode , option.id , null )"
                              [value]="option.id">
                              {{ option.text }}
                            </mat-radio-button>
                          </ng-container>
                        </mat-radio-group>
                        <mat-error class="has-error no-mat-error"
                          *ngIf="hasArrayError(radioControlsGroup.controls[i], 'radioControl', 'required') && additionalInforGroupSubmited">
                          {{ 'forms.Required' |
                          translate }}</mat-error>
                      </ng-container>
                      <ng-container *ngIf="field.value.questionTypeCode == 3" [formGroupName]="i">
                        <label>{{field.value.text}}</label>
                        <mat-form-field class="form-group">
                          <mat-label>{{'forms.please-answer' | translate}}</mat-label>
                          <textarea matInput formControlName="additionalInfoText" #text
                            (keyup)="addAnswer(field.value.id , field.value.questionTypeCode , null , text.value )"
                            required></textarea>
                          <mat-error
                            *ngIf="hasArrayError(radioControlsGroup.controls[i], 'additionalInfoText', 'required')">{{
                            'forms.Required' |
                            translate }}</mat-error>
                          <mat-error
                            *ngIf="hasArrayError(radioControlsGroup.controls[i], 'additionalInfoText', 'maxlength')">{{
                            'requestes.maxLength500' |
                            translate }}</mat-error>
                        </mat-form-field>
                      </ng-container>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="pb-5 pt-3">
              <div class="btn-container">
                <button mat-raised-button color="grey" matStepperPrevious (click)='pageIndex = pageIndex-1'>{{
                  'general.Back' | translate}}</button>
                <button mat-raised-button color="primary" class="ml-auto" [class.loading-btn]="loading"
                  [disabled]="loading" (click)="updateEnterprise()">{{
                  'general.Update' | translate}}</button>
              </div>
            </div>
          </form>
        </mat-step>

      </mat-horizontal-stepper>
    </ng-container>
  </section>
</mat-dialog-content>