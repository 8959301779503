import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  RecaptchaModule,
  RecaptchaSettings,
  RECAPTCHA_SETTINGS,
} from 'ng-recaptcha';
import { DemoMaterialModule } from '../helpers/material-modules';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { DateTimeMergePipe } from '../helpers/DateTimeMerge.pipe';
import { DndDirective } from '../helpers/dnd.directive';
import { FilterPipe } from '../helpers/filter.pipe';
import { OnlynumberDirective } from '../helpers/onlynumber.directive';
import { PrintDirective } from '../helpers/print.directive';
import { SanitizeHtmlPipePipe } from '../helpers/sanitize-html-pipe.pipe';
import { ShortNamePipe } from '../helpers/shortName.pipe';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FileManagerComponent } from './file-manager/file-manager.component';
import { FilesViewerComponent } from './files-viewer/files-viewer.component';
import { ContactInfoFormComponent } from './contact-info-form/contact-info-form.component';
import { Config } from '../models/config';
import { ChipsTreeSearchComponent } from './chips-tree-search/chips-tree-search.component';
import { CommonRequestDetailsComponent } from './common-request-details/common-request-details.component';
import { TreeSelectionComponent } from './tree-selection/tree-selection.component';
import { environment } from 'src/environments/environment';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { ContactInfoReactiveFormComponent } from './contact-info-reactive-form/contact-info-reactive-form.component';
import { UploadFileComponent } from './upload-file/upload-file.component';
import { InView } from '../helpers/in-view.directive';
import { TimeLeftPipe } from '../helpers/time-left.pipe';

@NgModule({
  declarations: [
    // AccessControlDirective,
    DateTimeMergePipe,
    DndDirective,
    FilterPipe,
    OnlynumberDirective,
    InView,
    PrintDirective,
    SanitizeHtmlPipePipe,
    ShortNamePipe,
    FileManagerComponent,
    FilesViewerComponent,
    ContactInfoFormComponent,
    ContactInfoReactiveFormComponent,
    ChipsTreeSearchComponent,
    TreeSelectionComponent,
    CommonRequestDetailsComponent,
    UploadFileComponent,
    TimeLeftPipe,
  ],
  imports: [
    CommonModule,
    // Forms
    FormsModule,
    ReactiveFormsModule,
    // Recaptcha
    RecaptchaModule,
    InfiniteScrollModule,
    NgxIntlTelInputModule,
    // material
    DemoMaterialModule,
    NgxMaterialTimepickerModule,
    TranslateModule.forChild({}),
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    DemoMaterialModule,
    NgxMaterialTimepickerModule,
    TranslateModule,
    // helpers
    DateTimeMergePipe,
    InfiniteScrollModule,
    DndDirective,
    FilterPipe, // used for search on caselogs,
    OnlynumberDirective,
    InView,
    PrintDirective,
    SanitizeHtmlPipePipe,
    NgxIntlTelInputModule,
    ShortNamePipe,
    FileManagerComponent,
    FilesViewerComponent,
    ContactInfoFormComponent,
    ContactInfoReactiveFormComponent,
    ChipsTreeSearchComponent,
    TreeSelectionComponent,
    UploadFileComponent,
    TimeLeftPipe
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.googleSiteKey,
        useValue: localStorage.getItem('language') || 'en',
      } as RecaptchaSettings,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'fill' },
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { hasBackdrop: true, disableClose: true },
    },
  ],
})
export class SharedModule { }
