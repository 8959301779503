import {
  Component,
  OnInit,
  HostListener,
  Output,
  EventEmitter,
} from '@angular/core';
import { User } from 'src/app/models/user/user';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NotificationsService } from 'src/app/services/notifications.service';

import { SignalRServiceService } from 'src/app/services/signal-rservice.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationsSearchModel } from 'src/app/models/notification/NotificationsSearchModel';
import { Notification } from 'src/app/models/notification/Notification';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
  language = localStorage.getItem('language') || 'en';

  @Output() sidebarTrigger = new EventEmitter();
  notificationNumber: number = 0;
  currentUser = new User();
  dataLoading = false;
  userMenu = false;
  shouldShow = false;
  fullName: any;

  notifLoading = false;
  pageNumber = 0;
  pageSize = 10;
  pageCount: number = 0;
  notificationMenu = false;
  notificationsResult: any = {
    unSeenNotificationsCount: 0,
    totalCount: 0,
  };
  // notificationsList: Array<Notification> = [];
  notificationsList: Notification[] = [];
  NotificationsObject: NotificationsSearchModel =
    new NotificationsSearchModel();

  constructor(
    private router: Router,
    public translate: TranslateService,
    private authenticationService: AuthenticationService,
    private signalRService: SignalRServiceService,
    private snackBar: MatSnackBar,
    private notificationsService: NotificationsService,
    private adminService: AdminService
  ) {
    this.currentUser = this.authenticationService.currentUserValue;
    this.getNotifications(false);
    this.signalRService.startConnection();
    this.signalRService.changeNotificationCount(this, this.updateNotification);
    // this.signalRService.sendAlert(this, this.openAlert);
  }

  ngOnInit() {
    this.getCurrentUserFullName();
    this.adminService.checkUserNameAfterUpdate.subscribe((data) => {
      if (data) {
        this.getCurrentUserFullName();
      }
    });
    this.signalRService.logout(this, this.logoutNotification);
  }
  updateNotification(context: any) {
    context.getNotifications(true);
    context.openSnackBar('You have a new notification!');
  }
  openSnackBar(msg: any) {
    this.snackBar.open(msg, '✖', {
      duration: 10000,
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
    });
  }
  logoutNotification(context: any) {
    context.logout();
  }

  signOut() {
    this.authenticationService.signOut().subscribe(
      (res) => {},
      (err) => {
        console.log(err);
      }
    );
    this.logout();
  }
  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
    this.signalRService.stopConnection();
  }

  toggleSearchBar() {
    this.shouldShow = !this.shouldShow;
  }

  initName(name: any) {
    if (!name) {
      return '';
    }
    let initials = name.match(/\b\w/g) || [];
    initials = (
      (initials.shift() || '') + (initials.pop() || '')
    ).toUpperCase();
    return initials;
  }

  openNotificationPanel() {
    this.updateNotificationsSeenStatus();
    this.getNotifications(true, true);
    this.notificationMenu = !this.notificationMenu;
    this.userMenu = false;
  }
  getNextPage() {
    if (this.pageNumber < this.pageCount - 1) {
      this.pageNumber += 1;
      this.getNotifications(false);
    }
  }
  getCurrentUserFullName() {
    this.adminService.getCurrentUserFullName().subscribe(
      (data) => {
        if (data) {
          this.fullName = data;
        }
      },
      (err) => {
        console.log(err);
      }
    );
    //
  }
  getNotifications(update: any, isOpen = false) {
    if (update) {
      this.dataLoading = true;
      this.pageNumber = 0;
      this.notificationsList = [];
    } else {
      this.notifLoading = true;
    }

    this.NotificationsObject.pageSize = this.pageSize;
    this.NotificationsObject.pageIndex = this.pageNumber;
    this.notificationsService
      .GetNotifications(this.NotificationsObject)
      .subscribe(
        (result: any) => {
          this.notifLoading = false;
          this.notificationsResult = result;
          this.pageCount = Math.ceil(
            this.notificationsResult.totalCount / this.pageSize
          );
          this.notificationsList = this.notificationsList.concat(
            this.notificationsResult.pageData
          );
          if (!isOpen) {
            this.notificationsResult.unSeenNotificationsCount =
              result.unSeenNotificationsCount;
          } else {
            this.notificationsResult.unSeenNotificationsCount = 0;
          }
          this.dataLoading = false;
        },
        (err) => {
          this.notifLoading = false;
          this.dataLoading = false;
          console.error(err);
        }
      );
  }
  updateNotificationsSeenStatus() {
    this.notificationsService.SeeNotifications().subscribe(
      (result) => {},
      (err) => {
        console.error(err);
      }
    );
  }

  @HostListener('window:keydown', ['$event']) handleKeyboardEvent(
    event: KeyboardEvent
  ) {
    if (event.key === 'Escape') {
      this.closeOpenedDrop();
    }
  }
  @HostListener('document:click', ['$event']) onDocumentClick(
    event: MouseEvent
  ) {
    if (event.target === document.getElementById('popOverlay')) {
      this.closeOpenedDrop();
    }
  }
  closeOpenedDrop() {
    this.userMenu = false;
    this.shouldShow = false;
    this.notificationMenu = false;
    // this.updateNotificationsSeenStatus();
  }
  changeLanguage() {
    if (this.language === 'en') {
      localStorage.setItem('language', 'fr');
    } else {
      localStorage.setItem('language', 'en');
    }
    window.location.reload();
  }
  routerToSupport() {
    if (this.router.url.includes('support')) {
      window.location.reload();
    } else {
      this.router.navigate(['/admin/support']);
    }
  }
}
