<mat-dialog-content class="createUserDialog">
  <section class="createUser">


    <div class="auth-card">

      <div class="d-flex align-items-center justify-content-between">
        <div>
          <h2 class="title">{{ 'requestes.Add UNIDO Feedback' | translate}}</h2>
        </div>

        <button class="close" mat-dialog-close>
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
    <div *ngIf='dataLoading' class="dataLoading">
      <mat-spinner class="m-auto" diameter="50"></mat-spinner>
    </div>
    <div *ngIf='!dataLoading'>
      <form class="form-ds" [formGroup]="formGroup">
        <div class="scroll-div px-3 pt-3 pb-0">

          <div class="form-group feedback-editor larg-height">
            <label class="form-hint">{{'requestes.UNIDO Feedback' | translate}}</label>
            <ckeditor [editor]="Editor" [config]="EditorConfig" formControlName="feedback"></ckeditor>
            <p class="mat-error has-error" *ngIf="hasError(formGroup, 'feedback', 'required') && isSubmited">{{ "forms.Required"
              | translate
              }}
            </p>
          </div>
          <span class="badge bg-light">
            <svg class="mr-1" width="12.001" height="12" viewBox="0 0 12.001 12">
              <path id="info-icon" d="M9485,6171a6,6,0,1,1,6,6A6.007,6.007,0,0,1,9485,6171Zm1.092,0a4.909,4.909,0,1,0,4.91-4.91A4.914,4.914,0,0,0,9486.091,6171Zm4.362,2.908v-3.272a.544.544,0,1,1,1.089,0v3.272a.544.544,0,1,1-1.089,0Zm-.182-5.637a.727.727,0,1,1,.729.727A.729.729,0,0,1,9490.271,6168.271Z" transform="translate(-9484.999 -6164.999)" fill="#94aac7"/>
            </svg>
            {{'requestes.The case will be closed after submiting UNIDO feedback' | translate}}
          </span>
        </div>
        <div class="btn-container px-3 pb-3 ">
          <button mat-raised-button color="grey" (click)="closeDialog()"
            type="button">{{
            'swal.cancel' | translate }}</button>
          <button mat-raised-button color="primary" (click)="save()">
            {{ 'requestes.Submit Feedback' | translate }}
          </button>
        </div>
      </form>
    </div>
  </section>
</mat-dialog-content>
