import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  Messages: any;
  ErrorMessages:any;
}

@Component({
  selector: 'app-assigned-resources-dialog',
  templateUrl: './check-errors-dialog.component.html'
})
export class checkErrorsDialogComponent implements OnInit {
  assignedResources:any;
  loading= false;
  isUpdate = true;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {

  }

  ngOnInit(): void {
    if (this.data.Messages) {
      this.assignedResources = this.data.Messages;
    } else {
      this.assignedResources = this.data.ErrorMessages;
    }


  }

}
