<mat-dialog-content class="createUserDialog createDialog">
  <section class="createUser">
    <div class="auth-card d-flex align-items-center justify-content-between">
      <h2 class="title">
        {{ 'dashboard.import file' | translate }}
      </h2>
      <button class="close" mat-dialog-close="">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="form-element mb-5">
      <div class="col-12 mb-2">
        <ul class="sector-list" *ngIf="data.isCountries">
          <li>
            {{'dashboard.countryCodeMsg' | translate}}
          </li>
          <li>
            {{'dashboard.countryNameEnMsg' | translate}}
          </li>
          <li>
            {{'dashboard.countryNameFrMsg' | translate}}
          </li>
          <li>
            {{'dashboard.cityMsg' | translate}}
          </li>
        </ul>
        <ul class="sector-list" *ngIf="!data.isCountries">
          <li>
            {{'dashboard.langNameEnMsg' | translate}}
          </li>
          <li>
            {{'dashboard.langNameFrMsg' | translate}}
          </li>
          <li>
            {{'dashboard.langStautsMsg' | translate}}
          </li>
        </ul>
      </div>
      <div class="col-12 mt-4 mb-4 text-center">
        <button mat-raised-button color="grey" (click)="downloadSample()">
          {{'dashboard.Download Sample' | translate}}
        </button>
      </div>
      <div class="col-12 mb-2">
        <app-file-manager [isRequired]='true' [allowedExt]="['xlsx']" [isSubmited]='isSubmitted' [files]="files"
          [maxFiles]='1' (selectedFiles)="updateFilesField($event)"></app-file-manager>
        <ng-container *ngIf="errorMessages && errorMessages.length">
          <ul class="errorMessages" *ngFor="let item of errorMessages">
            <li>{{item}}</li>
          </ul>
        </ng-container>
      </div>
    </div>
    <div class="btn-container">
      <button mat-raised-button color="grey" mat-dialog-close>{{ 'swal.cancel' | translate}}</button>
      <button mat-raised-button color="primary" [class.loading-btn]="loading" [disabled]="loading || disabledUplaod"
        (click)="save()">
        <span>{{ 'dashboard.import' | translate}}</span>
      </button>
    </div>
  </section>
</mat-dialog-content>