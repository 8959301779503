<mat-dialog-content class="createUserDialog">
  <section class="createUser">

    <div class="auth-card">
      <div class="d-flex align-items-center justify-content-between">
        <div>
          <h2 class="title">
            <span *ngIf="data.from == 'sectors'">{{ "matchmaking.Assistance Field Sectors" | translate }}</span>
            <span *ngIf="data.from == 'specializations'">{{ "matchmaking.Assistance Field Specializations" | translate
              }}</span>
          </h2>
        </div>

        <button class="close" mat-dialog-close>
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
    <div *ngIf="!data.data" class="dataLoading">
      <mat-spinner class="m-auto" diameter="50"></mat-spinner>
    </div>
    <div class="form-element m-4" *ngIf="data.data">
      <div class="row">
        <div class="col-12">
          <ul class="list-group">
            <li *ngFor="let item of list" class="list-group-item list-group-item-secondary">
              <div class="sector">
                <span class="badge">{{ item.isicCode }}</span>
                <label>{{ item.name }}</label>
                <svg *ngIf="item.isMatched" class="ml-1 mr-1" width="9" height="9" viewBox="0 0 9 9">
                  <g id="matched" transform="translate(0.625 0.35)">
                    <circle id="circle" cx="4.5" cy="4.5" r="4.5" transform="translate(-0.625 -0.35)"
                      fill="#4ab635" />
                    <path id="check-mark"
                      d="M141.675,169.035a.422.422,0,0,1,0,.587l-2.715,2.783a.4.4,0,0,1-.572,0l-1.293-1.325a.422.422,0,0,1,0-.587.4.4,0,0,1,.572,0l1.006,1.032,2.429-2.49A.4.4,0,0,1,141.675,169.035Z"
                      transform="translate(-135.353 -166.505)" fill="#fff" />
                  </g>
                </svg>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</mat-dialog-content>
