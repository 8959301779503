import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ExpertQuestionnaireFeedbackComponent } from 'src/app/features/cases/expert-questionnaire-feedback/expert-questionnaire-feedback.component';
import { FinalFeedbackComponent } from 'src/app/features/cases/final-feedback/final-feedback.component';
import { EnterpriseUpdateComponent } from 'src/app/features/enterprise/enterprise-update/enterprise-update.component';
import { MatchmakingAssignExpertComponent } from 'src/app/features/matchmaking/matchmaking-assign-expert/matchmaking-assign-expert.component';
import { EnterpriseRejectionReasonComponent } from 'src/app/features/requests/enterprise-requests/enterprise-requests-details/enterprise-rejection-reason/enterprise-rejection-reason.component';
import { CasesFilterModel } from 'src/app/models/cases/casesFilterModel';
import { AssistanceRequestStatusEnum } from 'src/app/models/matchmaking/matchmaking-filter-model';
import { AssistanceRequestsService } from 'src/app/services/assistanceRequests.service';
import { CasesService } from 'src/app/services/cases.service';
import { EnterpriseRequestsService } from 'src/app/services/enterprise-requests';
import { ImageViewerService } from 'src/app/services/image-viewer.service';
import { MatchmakingRequestsService } from 'src/app/services/matchmakingRequests.service';
import { SignalRServiceService } from 'src/app/services/signal-rservice.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-common-request-details',
  templateUrl: './common-request-details.component.html',
  styles: [],
})
export class CommonRequestDetailsComponent implements OnInit , OnDestroy {
  panelOpenState = false;
  dataLoading = false;
  canAddFinalFeedback = false;
  listCapturedImages: any[] = [];
  loading = false;
  requestId: any;
  dataSource: any;
  attachments: any;
  tabCase = 'Request';
  filterModel: CasesFilterModel = new CasesFilterModel();
  enterpriseRegistrationStatus = [
    { name: this.translate.instant('experts.New'), value: 0 },
    { name: this.translate.instant('experts.Accepted'), value: 1 },
    { name: this.translate.instant('experts.Declined'), value: 2 },
  ];
  pageCount = 0;
  manualPage = 0;
  assistanceRequestStatusEnum = AssistanceRequestStatusEnum;

  casesView = false;
  assistanceRequestView = false;
  enterpriseView = false;
  enterpriseRequestsDetails = false;
  private subscriptions: Subscription[] = [];

  constructor(
    public translate: TranslateService,
    private matchmakingService: MatchmakingRequestsService,
    private assistanceRequestsService: AssistanceRequestsService,
    private enterpriseRequestsService: EnterpriseRequestsService,
    private imageViewerService: ImageViewerService,
    private signalRService: SignalRServiceService,
    private casesService: CasesService,
    private route: ActivatedRoute,
    public zone: NgZone,
    public dialog: MatDialog,
    private router: Router
  ) {
    this.signalRService.handleNotifyingFBAppFilledSignlar(
      this,
      this.updateRequestDetails
    );
    this.signalRService.handleNotifyingFBKPIsAnswering(this, this.updateList);
    this.signalRService.handleCaseUpdated(this, this.updateDetails);
    this.signalRService.handlenterpriseUpdated(this, this.enterpriseUpdated);
    this.signalRService.handleCaseUpdatedNew(this, this.updateCapturedImages);
    this.signalRService.handleMissionRequestUpdatedSignlar(
      this,
      this.updateRequestDetails
    );
    this.signalRService.handleExpertMissionDecision(
      this,
      this.updateRequestDetails
    );
    this.signalRService.handleRequestUpdatedSignlar(
      this,
      this.updateRequestDetails
    );
    this.signalRService.handlexpertMissionAccepted(this, this.missionAccepted);
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.route.params.subscribe((params: Params) => {
        this.requestId = params['id'];
        if (this.router.url.includes('assistance-requests')) {
          this.assistanceRequestView = true;
          this.getassistanceRequestDetails();
        }
        if (this.router.url.includes('cases')) {
          this.casesView = true;
          this.getCaseDetailsByAdmin();
          this.getListCapturedImages(null);
        }
        if (this.router.url.includes('enterprises')) {
          this.getEnterpriseDetails();
          this.enterpriseView = true;
        }
        if (this.router.url.includes('enterprise-requests')) {
          this.getEnterpriseRequestsDetails();
          this.enterpriseRequestsDetails = true;
        }
      })
    )
  }
  //   navgate toTo Case Details
  missionAccepted(context: any, requestId: number) {
    context.navgatetoToCaseDetails(requestId);
  }
  navgatetoToCaseDetails(id: number) {
    debugger
    if (id == this.requestId && this.assistanceRequestView) {
      this.router.navigate(['/admin/cases', this.requestId]);
    }
  }

  /////

  //   update Captured Images
  updateCapturedImages(context: any, requestId: number) {
    context.refreshCapturedImages(requestId);
  }
  updateDetails(context: any, requestId: number) {
    context.refreshDetails(requestId);
  }
  enterpriseUpdated(context: any, requestId: number) {
    context.refreshDetailsEnterprise(requestId);
  }

  refreshCapturedImages(id: number) {
    if (id == this.requestId) {
      this.filterModel.caseId = id;
      this.getListCapturedImages(null);
      this.getCaseDetailsByAdmin();
    }
  }
  refreshDetails(id: number) {
    if (id == this.requestId) {
      this.getListCapturedImages(null);
      this.getCaseDetailsByAdmin();
    }
  }
  refreshDetailsEnterprise(id: number) {
    if (id == this.requestId) {
      this.getDetails();
    }
  }

  updateCaseDetails(context: any, requestId: number) {
    context.refreshDetails(requestId);
  }
  updateRequestDetails(context: any, requestId: number) {
    debugger
    console.log(requestId);
    console.log(context);
    context.zone.run(() => {
      context.refreshRequestDetails(requestId);
    })
  }

  refreshRequestDetails(id: number) {
    console.log(id);
    console.log(this.requestId);
    if (id == this.requestId) {
      this.getassistanceRequestDetails();
    }
  }
  updateList(context: any) {
    console.log(context);
    context.getassistanceRequestDetails();
  }
  getassistanceRequestDetails() {
    this.dataLoading = true;
    const data = {
      enterpriseProjectId: this.requestId,
    };
    this.assistanceRequestsService.getRequestDetails(this.requestId).subscribe(
      (data: any) => {
        this.dataSource = data;
        console.log(this.dataSource);
        // if (this.dataSource.assistanceRequestStatus == 8) {
        //   this.router.navigate(['/admin/cases', this.dataSource.id]);
        // }
        this.dataLoading = false;
        this.attachments = this.dataSource.attachments;
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  getCaseDetailsByAdmin() {
    this.dataLoading = true;
    const data = {
      id: this.requestId,
    };
    this.casesService.getCaseDetailsByAdmin(this.requestId).subscribe(
      (data: any) => {
        this.dataSource = data;
        this.dataLoading = false;
        this.canAddFinalFeedback = data.canAddFinalFeedback;
        console.log(this.canAddFinalFeedback);
        this.attachments = this.dataSource.attachments;
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  getDetails() {
    this.dataLoading = true;
    this.enterpriseRequestsService
      .getEnterpriseDetails(this.requestId)
      .subscribe(
        (data: any) => {
          this.dataLoading = false;
          console.log(data);
          this.dataSource = data;
          this.attachments = this.dataSource.attachments;
        },
        (error: any) => {
          console.log(error);
        }
      );
  }

  getEnterpriseDetails() {
    this.dataLoading = true;
    this.enterpriseRequestsService
      .getEnterpriseDetails(this.requestId)
      .subscribe(
        (data: any) => {
          this.dataLoading = false;
          console.log(data);
          this.dataSource = data;
          this.attachments = this.dataSource.attachments;
        },
        (error: any) => {
          console.log(error);
        }
      );
  }
  getEnterpriseRequestsDetails() {
    this.dataLoading = true;
    this.enterpriseRequestsService
      .getEnterpriseRequestDetails(this.requestId)
      .subscribe(
        (data: any) => {
          this.dataSource = data;
          this.dataLoading = false;
          this.attachments = this.dataSource.attachments;
        },
        (error: any) => {
          console.log(error);
        }
      );
  }

  addFinalFeedback() {
    const dialogRef = this.dialog.open(FinalFeedbackComponent, {
      width: '530px',
      closeOnNavigation: true,
      data: this.requestId,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.ngOnInit();
      }
    });
  }

  getListCapturedImages(event: any) {
    if (event) {
      this.filterModel.pageNumber = event.pageIndex;
    } else {
      this.filterModel.pageNumber = 0;
    }
    (this.filterModel.caseId = this.requestId),
      this.imageViewerService.getListCapturedImages(this.filterModel).subscribe(
        (data: any) => {
          this.listCapturedImages = data.pageData;
          this.filterModel.totalCount = data.totalCount;
          let count: any = this.filterModel.totalCount;
          this.pageCount = Math.ceil(+count / data.pageSize);
          this.manualPage = Number(data.pageIndex) + 1;
        },
        (error: any) => {
          console.log(error);
        }
      );
  }
  updatePage(event: any) {
    if (event.target.value <= this.pageCount) {
      this.filterModel.pageNumber = event.target.value - 1;
      this.getListCapturedImages(null);
    }
  }
  showViewer(id: any) {
    this.router.navigate(['/viewer', this.requestId, id]);
  }

  Decision(response: boolean, from?: any) {
    let data = {
      id: this.requestId,
      rejectionReason: '',
      response: response,
      from: from,
      isAssistanceRequestView: this.assistanceRequestView,
    };
    console.log(data);
    if (response) {
      Swal.fire({
        title: this.translate.instant('swal.Areyousure'),
        text: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then((result: any) => {
        if (result.value) {
          this.loading = true;
          this.enterpriseRequestsService.registrationDecision(data).subscribe(
            (data: any) => {
              this.loading = false;
              this.dataLoading = false;
              Swal.fire({
                title: 'Success',
                text: 'Decision saved successfully',
                icon: 'success',
                confirmButtonText: this.translate.instant('swal.ok'),
              }).then(() => {
                if (this.dataSource.routeToDetails) {
                  if(this.enterpriseRequestsDetails) {
                     this.router.navigate(['/admin/enterprises', this.requestId])
                  }
                } else {
                  this.ngOnInit();
                }

              });
            },
            (error: any) => {
              this.dataLoading = false;
            }
          );
        }
      });
    } else {
      let dialogRef;
      dialogRef = this.dialog.open(EnterpriseRejectionReasonComponent, {
        width: '530px',
        closeOnNavigation: true,
        data: data,
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        console.log(result);
        if (result) {
          this.ngOnInit();
        }
      });
    }
  }
  otherExpertsFeedback(from: any, group?: any) {
    let data: any = {
      caseId: this.requestId,
      group: group,
      isGenral: from == 'genral' ? true : false,
      isKpis: from == 'KPIS' ? true : false,
      isOther: from == 'other' ? true : false,
    };
    const dialogRef = this.dialog.open(ExpertQuestionnaireFeedbackComponent, {
      width: '530px',
      closeOnNavigation: true,
      data: data,
    });
  }
  assignExpert(data: any) {
    let dialogRef;
    dialogRef = this.dialog.open(MatchmakingAssignExpertComponent, {
      width: '1100px',
      closeOnNavigation: true,
      data: data,
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(result);
      if (result) {
        this.getassistanceRequestDetails();
      }
    });
  }
  SendRegistrationLink(id: number) {
    this.loading = true;
    this.enterpriseRequestsService
      .sendResetPasswordLink(id)
      .subscribe((data) => {
        this.loading = false;
        Swal.fire({
          title: 'Success',
          text: 'Registration link sent successfully',
          icon: 'success',
          confirmButtonText: this.translate.instant('swal.ok'),
        }).then(() => {
          this.getDetails();
        });
      });
  }
  navigateToAdmin() {
    this.router.navigate(['/admin']);
  }
  navigateToProjectDetails(id: number) {
    const url = this.router.createUrlTree(['/admin/projects', id]);
    window.open(url.toString(), '_blank');
  }
  openViewer(imageId: any) {
    this.router.navigate(['/viewer', this.requestId, imageId]);
  }
  openEnterpriseFormDialog(id: number) {
    const dialogRef = this.dialog.open(EnterpriseUpdateComponent, {
      width: '530px',
      closeOnNavigation: true,
      data: { id },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.ngOnInit();
      }
    });
  }
  removeEnterprise(id: number) {
    let data = {
      id: id,
    };
    Swal.fire({
      title: this.translate.instant('swal.Areyousure'),
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('swal.Yesdeleteit'),
      cancelButtonText: this.translate.instant('swal.Nokeepit'),
    }).then((result: any) => {
      if (result.value) {
        this.enterpriseRequestsService.removeEnterprise(data).subscribe(
          (data) => {
            this.dataLoading = false;
            Swal.fire({
              title: 'Success',
              text: '',
              icon: 'success',
              confirmButtonText: this.translate.instant('swal.ok'),
            }).then(() => {
              this.router.navigate(['/admin/enterprises']);
            });
          },
          (error) => {
            this.dataLoading = false;
          }
        );
      }
    });
  }

  toggleTabs(selected: any): void {
    const element: any = document.querySelector('#' + selected);
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.requestId = null;
    this.dataSource = null
  }
}
