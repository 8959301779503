<div class="position-relative">
     <mat-form-field hideRequiredMarker class="form-group" [class.loading-field]="loading"
      [class.mat-form-field-invalid]="!selected.length && isSubmitted && isRequired">

      <mat-label>{{ label }}</mat-label>

      <mat-chip-list #chipList>

        <mat-chip *ngFor="let item of selected; index as i;" (removed)="removeItem(item)"
          [hidden]="(i >= 1) && !showAllSelectedChips">
          <span *ngIf="showIsicCode">[{{item.isicCode}}]</span>
          {{item.name}}
          <mat-icon matChipRemove>
            <svg width="7" height="7" viewBox="0 0 7 7">
              <path id="remove-btn"
                d="M3.217-7.954l-.7-.7-2.8,2.8-2.8-2.8-.7.7,2.8,2.8-2.8,2.8.7.7,2.8-2.8,2.8,2.8.7-.7-2.8-2.8Z"
                transform="translate(3.783 8.658)" fill="#999" />
            </svg>
          </mat-icon>
        </mat-chip>

        <div *ngIf="(selected.length > 1) && !showAllSelectedChips" (click)="showTree = true" class="more-plus">
          + {{selected.length - 1}}
        </div>

        <input #searchInput autocomplete="off" (focus)="showTree = true" matInput (keyup)="applyFilter($event)"
          [matChipInputFor]="chipList">

      </mat-chip-list>

      <mat-icon matSuffix (click)="showTree = true" style="opacity: 0.5; cursor: pointer;">
        {{showTree ? 'expand_less' : 'expand_more'}}
      </mat-icon>

      <p class="has-error no-mat-error mt-2" [class.active]="!selected.length && isSubmitted && isRequired">
        {{ "forms.Required" | translate }}
      </p>

    </mat-form-field>

    <div class="tree-dropdown" [hidden]="!showTree">
      <div class="mt-2 mb-2 text-center" *ngIf="!isDataFound">
        <p>{{ "expert.NoDataFound" | translate }}</p>
      </div>
      <mat-tree *ngIf="isDataFound" [dataSource]="dataSource" [treeControl]="treeControl">
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding class="tree-node">
          <div class="row m-0">
            <div class="d-flex align-items-center">
              <button mat-icon-button disabled type="button"></button>
              <mat-checkbox class="checklist-leaf-node" [matTooltip]="node.name" matTooltipClass="bg-tooltip"
                [checked]="checklistSelection.isSelected(node) || isInArray(selected, node)"
                (change)="todoLeafItemSelectionToggle(node); selectedItem(node);searchInput.value = ''">{{node.name}}</mat-checkbox>
            </div>
            <div class="text-right isic-code">
              <span>{{ node.isicCode }}</span>
            </div>
          </div>
        </mat-tree-node>

        <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding class="tree-node">
          <div class="row m-0">
            <div class="d-flex align-items-center">
              <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name" type="button">
                <mat-icon class="mat-icon-rtl-mirror">
                  {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
              </button>
              <mat-checkbox [checked]="descendantsAllSelected(node)" [matTooltip]="node.name" matTooltipClass="bg-tooltip"
                [indeterminate]="descendantsPartiallySelected(node)"
                (change)="todoItemSelectionToggle(node); selectedItem(node);searchInput.value = ''">{{node.name}}</mat-checkbox>
            </div>
            <div class="text-right isic-code">
              <span>{{ node.isicCode }}</span>
            </div>
          </div>
        </mat-tree-node>
      </mat-tree>
    </div>
    <div id="popOverlay" *ngIf="showTree" (click)="showTree = false"></div>
  </div>
