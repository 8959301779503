import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CasesService } from 'src/app/services/cases.service';
export interface DialogData {
  isGenral:boolean,
  isKpis:boolean,
  isOther:boolean,
  caseId:any,
  group:any
}

@Component({
  selector: 'app-expert-questionnaire-feedback',
  templateUrl: './expert-questionnaire-feedback.component.html'
})
export class ExpertQuestionnaireFeedbackComponent implements OnInit {

  formGroup: FormGroup;
  isSubmited = false;
  dataLoading = false;
  dataSource:any

  foods: any = [
    {value: 'steak-0', viewValue: 'Steak'},
    {value: 'pizza-1', viewValue: 'Pizza'},
    {value: 'tacos-2', viewValue: 'Tacos'},
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private formBuilder: FormBuilder,
    private casesService:CasesService
    ) {}

  ngOnInit(): void {
    console.log(this.data);

    if (this.data.isGenral) {
      this.getGeneralQuestionsFeedback();
    }
    if (this.data.isKpis) {
      this.getKPIFeedback();
    }
    if (this.data.isOther) {
      this.getOtherQuestionsGroupFeedback();
    }
  }

  getGeneralQuestionsFeedback() {
    this.dataLoading = true;
    let obj = {
      assistanceRequestId: this.data.caseId,
    };
    this.casesService.getGeneralQuestionsFeedback(obj).subscribe(
      (data: any) => {
        this.dataLoading = false;
        this.dataSource = data;
      },
      (error: any) => {
        this.dataLoading = false;
        console.log(error);
      }
    );
  }
  getKPIFeedback() {
    this.dataLoading = true;
    let obj = {
      assistanceRequestId: this.data.caseId,
      areaId: this.data.group.id,
    };
    this.casesService.getKPIFeedback(obj).subscribe(
      (data: any) => {
        this.dataLoading = false;
        this.dataSource = data;
      },
      (error: any) => {
        this.dataLoading = false;
        console.log(error);
      }
    );
  }
  getOtherQuestionsGroupFeedback() {
    this.dataLoading = true;
    let obj = {
      assistanceRequestId: this.data.caseId,
      groupId: this.data.group.id,
    };
    this.casesService.getOtherQuestionsGroupFeedback(obj).subscribe(
      (data: any) => {
        this.dataLoading = false;
        this.dataSource = data;
      },
      (error: any) => {
        this.dataLoading = false;
        console.log(error);
      }
    );
  }

}
