<div class="dashBoard main-wrapper">

    <app-sidebar class="side-menu"
        [toggleSide]="toggleSide"
        (sidebarTrigger)="toggleSide = false"
        ></app-sidebar>

    <div class="main-container container-fluid p-0">

        <app-header (sidebarTrigger)="toggleSide = true"></app-header>

        <div>
            <div class="main-content">
                <!-- Breadcrumb -->
                <!-- <ul class="breadcrumb-items">
                    <li *ngFor="let item of menuItems; index as i; first as isFirst; last as isLast">
                        <a [ngClass]='{"active": !isLast}' [routerLink]="item.url">{{item.label}}</a>
                        <span class="icon-split" *ngIf='!isLast'>
                            <svg width="5" height="8" viewBox="0 0 5 8">
                                <path id="breadcrumb-arrow" d="M252.914,255.644l3.1,3.063-3.1,3.063.949.937,4.051-4-4.051-4Z" transform="translate(-252.914 -254.707)" fill="#999"/>
                            </svg>
                        </span>
                    </li>
                </ul> -->
                <!-- End Breadcrumb -->

                <!-- Main Container -->
                <div id="content">
                    <router-outlet></router-outlet>
                </div>

            </div>
        </div>

    </div>
</div>
