import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Config } from '../models/config';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ExportService {

  constructor(private http: HttpClient) { }


  getLanguageSample(): any {
    return this.http.get(`${environment.apiUrl}/Language/GetLanguageSample`);
  }
  getCountrySample(): any {
    return this.http.get(`${environment.apiUrl}/Country/GetCountrySample`);
  }

  import(file: any, apiName: any): any {
    const formData = new FormData();
    file.forEach((file: any) => {
      if (file) {
        formData.append('formFile', file, file.name);
      }
    });

    return this.http.post(`${environment.apiUrl}` + apiName, formData);
  }

  export(model: any, apiName: any) {
    //
    return this.getDownload(
      `${environment.apiUrl}` + apiName,
      model
    )
      .pipe(catchError(this.handleError));
  }



  getDownload(
    path: string,
    body: object = {},
    params?: HttpParams
  ): Observable<any> {
    // params['responseType'] = 'arraybuffer';
    // alert(path);
    return this.http
      .get(`${path}`, {
        headers: this.setHeadersWithFiles(),
        responseType: 'blob' as 'json',
      })
      .pipe(
        catchError((er) => this.formatErrors(er)),
        map((res: any) => res)
      );
  }
  private setHeadersWithFiles(): HttpHeaders {
    const headersConfig = {
      'Content-Type': 'application/json',
      Accept: 'application/pdf',
      responseType: 'blob',
    };
    return new HttpHeaders(headersConfig);
  }
  private formatErrors(error: any) {
    return throwError(error);
  }
  handleError(error: any) {
    return throwError(error);
  }
}
