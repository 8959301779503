import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { ExportService } from 'src/app/services/export-service.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html'
})
export class UploadFileComponent implements OnInit {
  isSubmitted = false
  loading = false
  disabledUplaod = false
  files: any[] = [];
  errorMessages: any[] = [];
  formGroup: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UploadFileComponent>,
    public translate: TranslateService,
    private formBuilder: FormBuilder,
    private service: ExportService
  ) { }

  ngOnInit(): void {
    console.log(this.data);

    this.buildForm()
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      files: [this.files, Validators.required],
    });
  }
  updateFilesField($event: any) {
    this.files = $event;
    this.formGroup.controls.files.setValue(this.files);
    this.disabledUplaod = false
    this.errorMessages = []
  }
  downloadSample() {
    if (this.data.isCountries) {
      this.service.getCountrySample().subscribe(
        (data: any) => {
          if (data) {
            this.downloadFromUrl(data.path);
          }
        },
        (error: any) => {
        }
      );
    } else {
      this.service.getLanguageSample().subscribe(
        (data: any) => {
          if (data) {
            this.downloadFromUrl(data.path);
          }
        },
        (error: any) => {
        }
      );
    }
  }
  downloadFromUrl(url: string) {
    window.open(url);
  }
  save() {
    this.isSubmitted = true
    if (this.formGroup.valid && this.files.length) {
      this.loading = true;
      this.service.import(this.files, this.data.apiUrl).subscribe((res: any) => {
        this.loading = false;
        this.dialogRef.close(true);
        Swal.fire({
          title: this.translate.instant('swal.success'),
          text: this.translate.instant('swal.addedsuccessfully'),
          icon: 'success',
          confirmButtonText: this.translate.instant('swal.ok'),
        }).then(() => { });

      }, (err: any) => {
        console.log(err);
        if (err && err.code == (-3)) {
          this.errorMessages = err.errorMessages
          this.loading = false;
          this.disabledUplaod = true
        }

      });

    }

  }
}
