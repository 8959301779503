<div class="contact-info-container">
  <div class="contact-info-head">
      <h2>{{ 'forms.contact-via' | translate }}</h2>
      <button type="button" class="add-more-btn" (click)="toggleContactItem();">
          <svg width="20" height="20" viewBox="0 0 20 20">
              <g id="add-icon" transform="translate(-1161 -629)">
                  <circle id="bg" cx="10" cy="10" r="10" transform="translate(1161 629)" fill="#4990e1" />
                  <path id="plus-icon"
                      d="M9.318,4.091H5.909V.682A.682.682,0,0,0,5.227,0H4.773a.682.682,0,0,0-.682.682V4.091H.682A.682.682,0,0,0,0,4.773v.455a.682.682,0,0,0,.682.682H4.091V9.318A.682.682,0,0,0,4.773,10h.455a.682.682,0,0,0,.682-.682V5.909H9.318A.682.682,0,0,0,10,5.227V4.773A.682.682,0,0,0,9.318,4.091Zm0,0"
                      transform="translate(1166 634)" fill="#fff" />
              </g>
          </svg>
          <span>{{ 'forms.AddMore' | translate }}</span>
      </button>
  </div>
  <div class="contact-info-content">
      <div class="static-item" *ngIf="!showContactItem && !contactMethodItems.length" (click)="showContactItem = true">
          <span>{{ 'forms.select-contact-method' | translate }}</span>
          <svg width="8.001" height="15" viewBox="0 0 8.001 15">
              <path id="expand-contact-icon" d="M-8881-618.05l.938-.95,3.063,3.1,3.061-3.1.94.95-4,4.05Zm0-6.9,4-4.052,4,4.052-.94.947-3.061-3.1-3.062,3.1Z" transform="translate(8881 629)" fill="#999"/>
          </svg>
      </div>
      <div class="item-list" id="methodList">
          <div class="static-item" *ngFor="let item of contactMethodItems; let i = index" [ngClass]="{'active': !showNewItem}">
              <div class="collaped-item" (click)="expendItem(i, 'methodList');setValidtorEditMood(item.communicationId , item.value , i)">
                  <span class="item-label">{{ 'forms.ContactMethod' | translate }}:</span>
                  <div>
                      <span class="label">
                          ({{item.name}}:
                      </span>
                      <span class="value">
                          {{item.value}})
                      </span>
                  </div>
                  <svg width="8.001" height="15" viewBox="0 0 8.001 15">
                      <path id="expand-contact-icon" d="M-8881-618.05l.938-.95,3.063,3.1,3.061-3.1.94.95-4,4.05Zm0-6.9,4-4.052,4,4.052-.94.947-3.061-3.1-3.062,3.1Z" transform="translate(8881 629)" fill="#999"/>
                  </svg>
              </div>
              <div class="expand-item">
                  <div  class="contact-method">
                      <div class="item-head" (click)="expendItem(i, 'methodList')">
                          <span>{{ 'forms.ContactMethod' | translate }}</span>
                          <div class="expand">
                              <svg width="8.002" height="15.002" viewBox="0 0 8.002 15.002">
                                  <path id="expand-contact-icon" d="M-8881-614.95l4-4.05,4,4.05-.938.949-3.062-3.1-3.062,3.1Zm0-13.1.938-.949,3.063,3.1,3.063-3.1.938.947-4,4.052Z" transform="translate(8881.002 629)" fill="#999"/>
                              </svg>
                          </div>

                      </div>
                      <div class="button-row">
                          <button mat-flat-button type="button" class="active">{{item.name}}</button>
                      </div>
                      <!-- <form #editForm="ngForm">
                          <mat-form-field class="form-group" appearance="fill">
                              <mat-label>{{item.name}}</mat-label>
                              <input matInput maxlength="50" [(ngModel)]="item.value" [type]="item.communicationId == 1 || item.communicationId == 3 ? 'number': item.communicationId == 2 ? 'email' : 'text'" #editContactControl="ngModel" name="editContactControl" required (keyup)="setValidtorEditMood(item.communicationId , item.value , i)">
                              <mat-error *ngIf="editContactControl.touched && editContactControl.errors?.required">{{ 'forms.Required' | translate }}</mat-error>
                              <mat-error *ngIf="editContactControl.touched && editContactControl.errors?.pattern">{{item.name}} {{ 'forms.NotValid' | translate }}</mat-error>
                              <mat-error *ngIf="editContactControl.errors?.notUniqe">{{ 'forms.notUniqe' | translate }}</mat-error>
                          </mat-form-field>
                      </form> -->
                      <form class="communicationUpdate" #editForm="ngForm">
                        <div class="form-group">
                          <mat-form-field class=" mb-0 pb-0" appearance="fill">
                            <mat-label>{{item.name}}</mat-label>
                            <input matInput maxlength="50" [(ngModel)]="item.value" [email]="item.type == 2"
                              [type]="item.type == 1 || item.type == 3 ? 'number': item.type == 2 ? 'email' : 'text'"
                              #editContactControl="ngModel" name="editContactControl" required
                              (keyup)="setValidtorEditMood(item.communicationId , item.value , i);updateItem(i);">
                            <!-- <mat-error *ngIf="editContactControl.errors?.notUniqe">{{ 'forms.notUniqe' | translate }}</mat-error> -->
                          </mat-form-field>
                          <p class="mat-error has-error"
                          *ngIf="editContactControl.touched && editContactControl.errors?.required">{{ 'forms.Required' |
                          translate }}</p>
                        <p class="mat-error has-error" *ngIf="editContactControl.touched && editContactControl.errors?.pattern">
                          {{item.name}} {{ 'forms.NotValid' | translate }}</p>
                         <p class="mat-error has-error" *ngIf="item.type == 2 && editContactControl.errors?.email">{{ 'forms.NotValid' | translate }}</p>
                        <p class="mat-error has-error"
                          *ngIf="(item.type == 1 || item.type == 3) && item.value.length < 11 && !editContactControl.errors?.required && !(editContactControl.touched && editContactControl.errors?.pattern)">
                          {{item.name}} {{ 'forms.NotValid' | translate }} </p>
                        </div>
                      </form>

                      <div class="btn-conatiner">
                          <button type="button" class="btn-delete" [matTooltip]="'experts.Delete' | translate" matTooltipClass="bg-tooltip" (click)="deleteItem(i)">
                              <svg width="20" height="26" viewBox="0 0 20 26">
                                  <path id="delete-icon" d="M5617.238,13487a4.27,4.27,0,0,1-4.319-4.21v-13.66a.884.884,0,0,1,.9-.874h16.371a.884.884,0,0,1,.9.874v13.66a4.271,4.271,0,0,1-4.32,4.21Zm6.918-13.48v8.216a1.1,1.1,0,0,0,2.2,0v-8.216a1.1,1.1,0,0,0-2.2,0Zm-6.515,0v8.216a1.1,1.1,0,0,0,2.2,0v-8.216a1.1,1.1,0,0,0-2.2,0Zm-5.078-6.27a.555.555,0,0,1-.562-.546v-1.7a2.683,2.683,0,0,1,2.713-2.647h3.908v-.33a1.044,1.044,0,0,1,1.057-1.028h4.645a1.044,1.044,0,0,1,1.057,1.028v.33h3.908a2.684,2.684,0,0,1,2.713,2.647v1.7a.557.557,0,0,1-.563.546Z" transform="translate(-5612.001 -13461.004)" fill="#b6c3d4"/>
                              </svg>
                          </button>
                          <button  mat-raised-button color="primary" [matTooltip]="'general.Update' | translate" matTooltipClass="bg-tooltip"
                          [disabled]="(!item.value || ((item.type == 1 || item.type == 3) && item.value.length < 11)) || editContactControl.errors?.pattern || (item.type == 2 && editContactControl.errors?.email)"
                          (click)="editItem(i);">
                              <svg class="d-block" width="19" height="14" viewBox="0 0 19 14">
                                  <path id="check-mark" d="M155.509,169.385a1.615,1.615,0,0,1,0,2.274L144.8,182.443a1.589,1.589,0,0,1-2.258,0l-5.1-5.134a1.615,1.615,0,0,1,0-2.273,1.588,1.588,0,0,1,2.257,0l3.969,4,9.58-9.648A1.589,1.589,0,0,1,155.509,169.385Z" transform="translate(-136.976 -168.914)" fill="#fff"/>
                              </svg>
                          </button>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      <div *ngIf="showContactItem" class="contact-method">
          <div class="item-head">
            <!-- expendItem(index, 'methodList'); -->
              <span>{{ 'forms.ContactMethod' | translate }}</span>
              <div class="expand" (click)="showContactItem = false">
                  <svg width="8.002" height="15.002" viewBox="0 0 8.002 15.002">
                      <path id="expand-contact-icon" d="M-8881-614.95l4-4.05,4,4.05-.938.949-3.062-3.1-3.062,3.1Zm0-13.1.938-.949,3.063,3.1,3.063-3.1.938.947-4,4.052Z" transform="translate(8881.002 629)" fill="#999"/>
                  </svg>
              </div>
          </div>

          <div class="button-row" *ngFor="let communicationType of communicationTypes">
              <button type="button" class="button-contact" mat-flat-button (click)="selectContactMethod(communicationType);" [ngClass]="{'active': selectedMethod.id == communicationType.id}">{{communicationType.name}}</button>
          </div>
          <ng-container *ngFor="let communicationType of communicationTypes">
              <div>
                  <mat-form-field *ngIf="selectedMethod.id == communicationType.id" class="form-group" appearance="fill">
                      <mat-label>{{communicationType.name}}</mat-label>
                      <input matInput [formControl]="contactControl">
                      <mat-error *ngIf="contactControl.errors?.required">{{ 'forms.Required' | translate }}</mat-error>
                      <mat-error *ngIf="contactControl.errors?.pattern">{{communicationType.name}} {{ 'forms.NotValid' | translate }}</mat-error>
                      <mat-error *ngIf="contactControl.errors?.maxlength">{{ 'forms.maxLength50' | translate }}</mat-error>
                      <mat-error *ngIf="contactControl.errors?.notUniqe">{{ 'forms.notUniqe' | translate }}</mat-error>
                  </mat-form-field>
              </div>
          </ng-container>

          <div class="btn-conatiner">
              <button type="button" class="btn-delete" [matTooltip]="'experts.Delete' | translate" matTooltipClass="bg-tooltip" (click)="toggleContactItem()">
                  <svg width="20" height="26" viewBox="0 0 20 26">
                      <path id="delete-icon" d="M5617.238,13487a4.27,4.27,0,0,1-4.319-4.21v-13.66a.884.884,0,0,1,.9-.874h16.371a.884.884,0,0,1,.9.874v13.66a4.271,4.271,0,0,1-4.32,4.21Zm6.918-13.48v8.216a1.1,1.1,0,0,0,2.2,0v-8.216a1.1,1.1,0,0,0-2.2,0Zm-6.515,0v8.216a1.1,1.1,0,0,0,2.2,0v-8.216a1.1,1.1,0,0,0-2.2,0Zm-5.078-6.27a.555.555,0,0,1-.562-.546v-1.7a2.683,2.683,0,0,1,2.713-2.647h3.908v-.33a1.044,1.044,0,0,1,1.057-1.028h4.645a1.044,1.044,0,0,1,1.057,1.028v.33h3.908a2.684,2.684,0,0,1,2.713,2.647v1.7a.557.557,0,0,1-.563.546Z" transform="translate(-5612.001 -13461.004)" fill="#b6c3d4"/>
                  </svg>
              </button>
              <button mat-raised-button type="button" [matTooltip]="'general.Add' | translate" matTooltipClass="bg-tooltip" color="primary" (click)="addNewContact();">
                  <svg class="d-block" width="19" height="14" viewBox="0 0 19 14">
                  <path id="check-mark" d="M155.509,169.385a1.615,1.615,0,0,1,0,2.274L144.8,182.443a1.589,1.589,0,0,1-2.258,0l-5.1-5.134a1.615,1.615,0,0,1,0-2.273,1.588,1.588,0,0,1,2.257,0l3.969,4,9.58-9.648A1.589,1.589,0,0,1,155.509,169.385Z" transform="translate(-136.976 -168.914)" fill="#fff"/>
                  </svg>
              </button>
          </div>
      </div>
  </div>

  <div class="has-error" *ngIf="!contactMethodItems.length && isSubmited && isRequired">{{ 'forms.Required' | translate }}</div>


</div>
