import { E } from '@angular/cdk/keycodes';
import {
  Component,
  Inject,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthGuard } from 'src/app/helpers/auth.guard';
import { Lookup } from 'src/app/models/Lookup';
import { Country } from 'src/app/models/lookups/Country';
import { Language } from 'src/app/models/lookups/Language';
import { AssistanceRequestStatusEnum, ExpertsMatchedFilterModel } from 'src/app/models/matchmaking/matchmaking-filter-model';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ExpertRequestsService } from 'src/app/services/expert-requests';
import { LookupsService } from 'src/app/services/lookups.service';
import { MatchmakingRequestsService } from 'src/app/services/matchmakingRequests.service';
import { ProjectsService } from 'src/app/services/projects.service';
import Swal from 'sweetalert2';
import { MatchmakingShowMoreComponent } from './matchmaking-show-more/matchmaking-show-more.component';
export interface DialogData {
  id: number;
  expert?: any;
}

@Component({
  selector: 'app-matchmaking-assign-expert',
  templateUrl: './matchmaking-assign-expert.component.html',
})
export class MatchmakingAssignExpertComponent implements OnInit {
  isChaked = false;
  isAssign = false;
  isClear = false;
  showSearch = false;
  changePage = false;
  dataLoading = false;
  isLoggedIn = false;
  loading = false;
  showFilter = false;
  sectorsLoading = false;
  showsectorsTree = false;
  checkIfTreeShow = false;
  projectsLoading = false;
  totalCount: number;
  sectorsTree: Lookup[] = [];
  detailsSelectedProject = []; // update object
  selectedProject: any[] = [];
  countryIds: [] = [];
  languageIds: [] = [];
  allLanguages: Language[] = [];
  allCountries: Country[] = [];
  specializationIds: [] = [];
  specializationList: any;
  AssistanceRequestStatusEnum = AssistanceRequestStatusEnum;
  filterModel: ExpertsMatchedFilterModel = new ExpertsMatchedFilterModel();
  @ViewChild('callSectosDialog') callSectosDialog: TemplateRef<any>;
  dataSource: any[] = [];
  list: any;
  // mat table
  pageCount = 0;
  manualPage = 0;
  expertId: number;
  enterpriseProjectId: number;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private matchmakingService: MatchmakingRequestsService,
    private translate: TranslateService,
    private projectsService: ProjectsService,
    private expertRequestsService: ExpertRequestsService,
    private lookupsService: LookupsService,
    public dialogRef: MatDialogRef<MatchmakingAssignExpertComponent>,
    public childDialog: MatDialog,
    public authenticationService: AuthenticationService,
    public authGuard: AuthGuard,

    private router: Router
  ) {}

  ngOnInit(): void {
    console.log(this.data);
    this.filterModel.enterpriseProjectId = +this.data.id;
    this.filterModel.pageSize = 8;
    this.filterModel.pageNumber = 0;
    this.getListExpertsMatchedWithEnterprise(null);
    this.getSectors(null);
    this.getSpecializations();
    this.getCountries();
    this.getLanguages();
  }

  getListExpertsMatchedWithEnterprise(event: any) {
    if (this.authenticationService.currentUserValue) {
      this.dataLoading = true;
      if (event && !this.changePage) {
        this.filterModel.pageNumber = event.pageIndex;
      } else if (!this.changePage) {
        this.filterModel.pageNumber = 0;
      }
      this.matchmakingService
        .getListExpertsMatchedWithEnterprise(this.filterModel)
        .subscribe(
          (data: any) => {
            if (
              this.filterModel.searchText ||
              this.specializationIds.length ||
              this.countryIds.length ||
              this.selectedProject.length ||
              this.languageIds.length ||
              this.isClear
            ) {
              this.dataSource = data.pageData;
              if (this.dataSource) {
                this.dataLoading = false;
              }
            } else {
              this.dataSource = [...this.dataSource, ...data.pageData];
            }
            this.dataSource.forEach((element) => {
              element.isChaked = false;
              if (this.data.expert) {
                if (this.data.expert.id === element.id) {
                  element.isChaked = true;
                  if (!this.AssistanceRequestStatusEnum.ExpertWithdrawn) {
                    this.expertId = this.data.expert.id;
                  }
                }
              }
            });
            this.totalCount = data.totalCount;
            this.pageCount = Math.ceil(data.totalCount / data.pageSize);
            this.manualPage = Number(data.pageIndex) + 1;
            this.dataLoading = false;
            this.isClear = false;
          },
          (error: any) => {
            this.dataLoading = false;
            console.log(error);
          }
        );
    } else {
      this.dialogRef.close();
    }
  }
  getNextPage() {
    if (this.filterModel.pageNumber < this.pageCount - 1) {
      this.changePage = true;
      this.filterModel.pageNumber += 1;
      this.getListExpertsMatchedWithEnterprise(false);
    }
  }
  updatePage(event: any) {
    if (event.target.value <= this.pageCount) {
      this.filterModel.pageNumber = event.target.value - 1;
      this.getListExpertsMatchedWithEnterprise(null);
    }
  }
  onChange(ele: any) {
    console.log(ele);

    this.dataSource.forEach((element) => {
      element.isChaked = false;
    });
    this.expertId = ele.id;
    if (this.data.expert) {
      if (this.data.expert.id != this.expertId) {
        this.isAssign = true;
      } else {
        this.isAssign = false;
      }
    } else {
      this.isAssign = true;
    }
  }
  openSectors(data: any, from: any) {
    this.childDialog.open(MatchmakingShowMoreComponent, {
      width: '530px',
      data: { from, data },
    });
  }
  getSectors(e: any) {
    this.projectsLoading = true;
    this.sectorsLoading = true;
    console.log(e);

    const filter = {
      searchText: e ? e.target.value : '',
    };
    this.projectsService.getSectors(filter).subscribe(
      (data: any) => {
        this.projectsLoading = false;
        this.sectorsLoading = false;
        if (data) {
          this.sectorsTree = data;
          this.checkIfTreeShow = true;
        }
      },
      (error: any) => {
        this.projectsLoading = false;
        this.sectorsLoading = false;
      }
    );
  }

  getSpecializations() {
    this.projectsService.getSpecializations(null).subscribe(
      (data: any) => {
        if (data) {
          this.specializationList = data;
        }
      },
      (error: any) => {
        console.error(error);
      }
    );
  }
  getCountries() {
    this.lookupsService.getCountries().subscribe(
      (data: any) => {
        if (data) {
          this.allCountries = data;
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }
  getLanguages() {
    this.expertRequestsService.getLanguages(null).subscribe(
      (data: any) => {
        if (data) {
          this.allLanguages = data;
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  // SEARCH
  makeSearch() {
    if (!this.showSearch) {
      this.showSearch = true; // Show input
    } else {
      if (this.filterModel.searchText) {
        this.getListExpertsMatchedWithEnterprise(null);
      }
    }
  }
  resetAfterClear() {
    if (!this.filterModel.searchText) {
      this.resetSearch();
    }
  }
  resetSearch() {
    this.filterModel.searchText = '';
    this.filterModel.pageNumber = 0;
    this.getListExpertsMatchedWithEnterprise(null);
    this.isClear = true;
  }

  clearFilter() {
    this.isClear = true;
    delete this.filterModel.sectorIds;
    delete this.filterModel.specializationIds;
    delete this.filterModel.countryIds;
    delete this.filterModel.languageIds;
    this.languageIds = [];
    this.specializationIds = [];
    this.countryIds = [];
    this.selectedProject = [];
    this.dataSource = [];
    this.filterModel.pageNumber = 0;
    this.getListExpertsMatchedWithEnterprise(null);
    this.projectsService.clearSectors.next(true);
  }

  updateFilter() {
    if (this.selectedProject.length > 0) {
      let project = this.selectedProject.map((item: any) => {
        return item.id;
      });
      this.filterModel.sectorIds = `[${project}]`;
    } else {
      delete this.filterModel.sectorIds;
    }
    if (this.specializationIds.length > 0) {
      this.filterModel.specializationIds = `[${this.specializationIds}]`;
    } else {
      delete this.filterModel.specializationIds;
    }
    if (this.countryIds.length > 0) {
      this.filterModel.countryIds = `[${this.countryIds}]`;
    } else {
      delete this.filterModel.countryIds;
    }

    if (this.languageIds.length > 0) {
      this.filterModel.languageIds = `[${this.languageIds}]`;
    } else {
      delete this.filterModel.languageIds;
    }

    this.filterModel.pageNumber = 0;
  }
  save() {
    if (this.authenticationService.currentUserValue) {
      this.loading = true;
      let ids = {
        expertId: this.expertId,
        enterpriseProjectId: this.filterModel.enterpriseProjectId,
      };
      if (this.expertId) {
        this.matchmakingService.assignMissionToExpert(ids).subscribe(
          (data: any) => {
            this.loading = false;
            this.dialogRef.close(true);
            Swal.fire({
              title: this.translate.instant('swal.success'),
              text: this.translate.instant('swal.savedsuccessfully'),
              icon: 'success',
              confirmButtonText: this.translate.instant('swal.ok'),
            });
          },
          (err: any) => {
            console.log(err);
            this.loading = false;
            Swal.fire({
              title: this.translate.instant('swal.Failed'),
              text: 'assign mission to expert failed',
              icon: 'error',
              confirmButtonText: this.translate.instant('swal.ok'),
            });
          }
        );
      } else {
        Swal.fire({
          title: this.translate.instant('swal.Failed'),
          text: 'Please select an expert',
          icon: 'error',
          confirmButtonText: this.translate.instant('swal.ok'),
        });
        this.loading = false;
      }
    } else {
      this.dialogRef.close();
    }
  }
}
