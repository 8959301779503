import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../models/config';
import * as XLSX from 'xlsx';
import { BehaviorSubject } from 'rxjs';
// import * as FileSaver from 'file-saver';
const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root',
})
export class AdminService {

  checkUserNameAfterUpdate = new BehaviorSubject<any>(null);
  constructor(private http: HttpClient) { }
  exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ['data'],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    // FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }
  // enterprise
  getListEnterprises(enterpriseFilterModel: any): any {
    return this.http.post(
      `${environment.apiUrl}/enterprise/ListEnterprises`,
      enterpriseFilterModel
    );
  }
  exportEnterprise(filterModel: any): any {
    return this.http.post(
      `${environment.apiUrl}/enterprise/ExportEnterprises`,
      filterModel
    );
  }
  exportEnterpriseEdits(filterModel: any): any {
    return this.http.post(
      `${environment.adminApiUrl}/ExportEnterpriseProfileRequests`,
      filterModel
    );
  }

  RemoveEnterprise(enterpriseId: any) {
    return this.http.get(
      `${environment.apiUrl}/enterprise/RemoveEnterprise/${enterpriseId}`
    );
  }
  // expert
  getListExperts(expertFilterModel: any): any {
    return this.http.post(
      `${environment.apiUrl}/expert/ListExperts`,
      expertFilterModel
    );
  }
  exportExpert(filterModel: any): any {
    return this.http.post(`${environment.apiUrl}/expert/ExportExperts`, filterModel);
  }
  exportExpertEdits(filterModel: any): any {
    return this.http.post(
      `${environment.adminApiUrl}/ExportExpertProfileRequests`,
      filterModel
    );
  }

  RemoveExpert(expertId: any) {
    return this.http.get(`${environment.apiUrl}/expert/RemoveExpert/${expertId}`);
  }

  updateExpertProfile(expertModel: any, files: any) {
    const formData = new FormData();
    files.forEach((file: any) => {
      // formData.append('file[]', file, file.name);
      if (file.attachmentId === undefined) {
        // send new files
        formData.append('file[]', file, file.name);
      }
    });
    formData.append('data', JSON.stringify(expertModel));
    return this.http.post(`${environment.apiUrl}/expert/EditExpert`, formData);
  }
  updateEnterpriseProfile(enterpriseModel: any, files: any) {
    const formData = new FormData();
    files.forEach((file: any) => {
      // formData.append('file[]', file, file.name);
      if (file.attachmentId === undefined) {
        // send new files
        formData.append('file[]', file, file.name);
      }
    });
    formData.append('data', JSON.stringify(enterpriseModel));
    return this.http.post(
      `${environment.apiUrl}/enterprise/EditEnterprise`,
      formData
    );
  }

  // users
  GetAdminTypes() {
    return this.http.get(`${environment.apiUrl}/systemAdmin/GetAdminTypes`);
  }
  getCurrentUserFullName() {
    return this.http.get(`${environment.apiUrl}/systemAdmin/GetCurrentUserFullName`);
  }

  getListSystemAdmins(usersFilterModel: any): any {
    return this.http.post(
      `${environment.apiUrl}/systemAdmin/ListSystemAdmins`,
      usersFilterModel
    );
  }

  removeSystemAdmin(userId: any) {
    return this.http.get(
      `${environment.apiUrl}/systemAdmin/RemoveSystemAdmin/${userId}`
    );
  }

  getDetails(userId: any) {
    return this.http.get(`${environment.apiUrl}/SystemAdmin/GetDetailsById/${userId}`);
  }

  saveSystemAdmin(userModel: any) {
    if (userModel.SystemAdminId) {
      return this.http.post(
        `${environment.apiUrl}/systemAdmin/UpdateSystemAdmin`,
        userModel
      );
    } else {
      return this.http.post(
        `${environment.apiUrl}/systemAdmin/AddSystemAdmin`,
        userModel
      );
    }
  }

  GetProfile() {
    return this.http.get(`${environment.apiUrl}/systemAdmin/GetProfile`);
  }
  ActivateSystemAdmin(status: any, id: any) {
    const obj = { SystemAdminId: id, Activated: status };
    return this.http.post(
      `${environment.apiUrl}/systemAdmin/ActivateSystemAdmin`,
      obj
    );
  }

  GetExpertsRequests(obj: any): any {
    return this.http.post(
      `${environment.apiUrl}/admin/GetExpertProfileRequests`,
      obj
    );
  }

  GetExpertProfileRequestDetails(userId: any): any {
    return this.http.get(
      `${environment.apiUrl}/admin/GetExpertProfileRequestDetails/${userId}`
    );
  }

  GetEnterprisesRequests(obj: any): any {
    return this.http.post(
      `${environment.apiUrl}/admin/GetEnterpriseProfileRequests`,
      obj
    );
  }

  GetEnterpriseProfileRequestDetails(userId: any): any {
    return this.http.get(
      `${environment.apiUrl}/admin/GetEnterpriseProfileRequestDetails/${userId}`
    );
  }

  ApproveEdits(status: any, id: any) {
    const obj = { Approved: status, ObjectId: id };
    return this.http.post(`${environment.apiUrl}/expert/ApproveEdits`, obj);
  }

  enterpriseApproveEdits(status: any, id: any) {
    const obj = { Approved: status, ObjectId: id };
    return this.http.post(`${environment.apiUrl}/enterprise/ApproveEdits`, obj);
  }

  exportSystemAdmins(filterModel: any): any {
    return this.http.post(
      `${environment.apiUrl}/systemAdmin/ExportSystemAdmins`,
      filterModel
    );
  }
}
