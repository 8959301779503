import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../models/config';

@Injectable({ providedIn: 'root' })
export class RequestsService {

    constructor(private http: HttpClient) {
    }

    // getRequests(filterObj): any {
    //     return this.http.post(`${environment.apiUrl}/AssessmentRequest/GetEnterpriseRequests`, filterObj);
    // }
    // export(filterObj): any {
    //     return this.http.post(`${environment.apiUrl}/AssessmentRequest/ExportRequests`, filterObj);
    // }
    // Withdraw(RequestId) {
    //     return this.http.post(`${environment.apiUrl}/AssessmentRequest/Withdraw`, {RequestId});
    // }


    // getRequestDetails(RequestId) {
    //     return this.http.get(`${environment.apiUrl}/AssessmentRequest/GetRequest/${RequestId}`);
    // }
    // sendExpertRespondMission(respondObj) {
    //     return this.http.post(`${environment.apiUrl}/AssessmentRequest/ExpertRespondMission`, respondObj);
    // }

    // AddExpertFeedback(feedbackObj, files) {
    //     const formData = new FormData();
    //     files.forEach(file => {
    //         formData.append('file[]', file, file.name);
    //     });
    //     formData.append('data', JSON.stringify(feedbackObj));
    //     return this.http.post(`${environment.apiUrl}/AssessmentRequest/AddExpertFeedback`, formData);
    // }
    // AddEnterpriseFeedback(feedbackObj) {
    //     return this.http.post(`${environment.apiUrl}/AssessmentRequest/AddEnterpriseFeedback`, feedbackObj);
    // }
    // AddNewRequest(newRequestObj, files) {
    //     const formData = new FormData();
    //     files.forEach(file => {
    //         formData.append('file[]', file, file.name);
    //     });
    //     formData.append('data', JSON.stringify(newRequestObj));
    //     return this.http.post(`${environment.apiUrl}/AssessmentRequest/NewRequest`, formData);
    // }
}
