<mat-dialog-content class="createUserDialog">
  <section class="createUser">


    <div class="auth-card">

      <div class="d-flex align-items-center justify-content-between">
        <div>
          <h2 class="title">{{ 'requestes.Expert Feedback' | translate}}</h2>
        </div>

        <button class="close" mat-dialog-close>
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
    <div *ngIf='dataLoading' class="dataLoading">
      <mat-spinner class="m-auto" diameter="50"></mat-spinner>
    </div>
    <div *ngIf='!dataLoading'>
      <div *ngIf='dataSource else dataEmpty'>
        <div class="scroll-div longer mx-3 pt-0 pb-3">
          <div class="questionnaire-box mb-3 " *ngIf="!data.isGenral">
            <div class="row">
              <div class="col">
                <label>{{'requestes.Group'|translate}}</label>
                <h4><span> {{data.group.name}} </span></h4>
              </div>
            </div>
          </div>

          <div class="questionnaires-details mt-0">
            <div class="row">
              <div class="col">
                <div class="p-description">
                  <label>{{ 'requestes.Feedback by' | translate}}</label>
                  <div class="img-cricle mb-4">
                    <div class="image">
                      <span>{{ dataSource.expert | shortName }}</span>
                    </div>
                    <div class="image-label">
                      {{dataSource.expert}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="questionnaires-details mt-0">
            <div class="row">
              <div class="col">
                <div class="p-description">
                  <label>{{'requestes.Feedback on'|translate}}</label>
                  <p>{{dataSource?.feedbackedOn | date: "MMM dd, y hh:mm aaa"}} </p>
                </div>
              </div>
            </div>
          </div>
          <div class="questionnaires-details mt-0">
            <div class="row">
              <div class="col ">
                <div class="p-description">
                  <label>{{'requestes.Feedback'|translate}}</label>
                  <div class="feedback-p" [innerHTML]="dataSource?.feedback | sanitizeHtml"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="questionnaires-details mt-0" *ngIf="dataSource.attachments && dataSource.attachments.length">
            <div class="row">
              <div class="col ">
                <div class="p-description">
                  <label>{{'expert.Attachments'|translate}}</label>
                  <div class="mb-2">
                    <app-files-viewer [files]='dataSource.attachments'></app-files-viewer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ng-template #dataEmpty>
        <div class="data-empty" *ngIf="!dataLoading">
          <p>{{ "expert.NoDataFound" | translate }}</p>
        </div>
      </ng-template>
    </div>
  </section>
</mat-dialog-content>
