export class CasesFilterModel {
  pageSize: number;
  pageNumber: number;
  sortItem?: number;
  searchText?: string;
  totalCount?: number = 0;
  requestedDateFrom?: string;
  requestedDateTo?: string;
  status?:number;
  sectorIds?: number[] = [];
  specializationIds?: number[] = [];
  projectIds?: number[] = [];
  countriesIds?: number[] = [];
  citiesIds?: number[] = [];
  dateTo?:string;
  dateFrom?:string;
  caseId?:number;
}
