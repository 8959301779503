import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Config } from '../models/config';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ExpertRequestsService {
  constructor(private http: HttpClient) { }


  getListExperts(filterModel: any): any {
    return this.http.get(`${environment.apiUrl}/Expert/ListExperts`, {
      params: filterModel,
    });
  }
  getListExpertRequests(filterModel: any): any {
    return this.http.get(`${environment.apiUrl}/Expert/ListExpertRequests`, {
      params: filterModel,
    });
  }
  getLanguages(filterModel: any): any {
    return this.http.get(`${environment.apiUrl}/Language/GetLanguages`, {
      params: filterModel,
    });
  }
  getCountriesForExpert(text: any): any {
    return this.http.get(`${environment.apiUrl}/Country/GetCountriesForExpert?searchText=${text}`);
  }
  getLanguagesForExpert(text: any): any {
    return this.http.get(`${environment.apiUrl}/Language/GetLanguagesForExpert?searchText=${text}`);
  }
  getRequestDetails(id: number) {
    return this.http.get(`${environment.apiUrl}/Expert/GetRequestDetails/${id}`);
  }
  getExpertDetails(id: number) {
    return this.http.get(`${environment.apiUrl}/Expert/GetExpertDetails/${id}`);
  }
  registrationDecision(data: any) {
    return this.http.post(
      `${environment.apiUrl}/Expert/SubmitRegistrationDecision`,
      data
    );
  }
  submitProfileUpdatesDecision(data: any) {
    return this.http.post(
      `${environment.apiUrl}/Expert/SubmitProfileUpdatesDecision`,
      data
    );
  }
  removeExpert(data: any) {
    return this.http.delete(`${environment.apiUrl}/Expert/RemoveExpert`, {
      params: data,
    });
  }
  getSectorsForExpert(filterModel: any): any {
    return this.http.get(`${environment.apiUrl}/Sector/GetSectorsForExpert`, {
      params: filterModel,
    });
  }

  getProjects() {
    return this.http.get(`${environment.apiUrl}/project/GetProjectsBasedOnPermissions`);
  }
  validateEmail(data: any) {
    return this.http.get(`${environment.apiUrl}/Expert/ValidateEmail`, {
      params: data,
    });
  }
  updateExpert(user: any, files: any) {
    const formData = new FormData();
    files.forEach((file: any) => {
      if (!file.attachmentId) {
        formData.append('file[]', file, file.name);
      }
    });
    formData.append('data', JSON.stringify(user));
    return this.http.put(`${environment.apiUrl}/Expert/UpdateExpert`, formData);
  }

}
