import { Component, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from './services/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  title = 'UnidoAdmin';

  constructor(public translate: TranslateService,private authenticationService:AuthenticationService) {
    const lang = localStorage.getItem('language') || 'en';
    translate.addLangs(['en', 'fr']);
    translate.setDefaultLang('en');
    translate.use(lang);
  }

  @HostListener('document:mousemove')
  @HostListener('document:keypress')
  @HostListener('document:click')
  @HostListener('window:beforeunload', ['$event'])
  handleUserActivity(event: any): void {
    this.authenticationService.resetTimer();
    // Prompt before closing the tab/window
    // event.preventDefault();
    // event.returnValue = '';
  }
}
