export class MatchmakingFilterModel {
  id: number;
  searchField?: string;
  status?: number;
  pageSize?: number;
  pageNumber: number;
  searchText?: string;
  totalCount?: number;
  expertIds?: string;
  sortItem?: number;
  countryIds?: string;
  languageIds?: string;
  sectorIds?: string;
  projectIds?: string;
  specializationIds?: string;
  requestedDateFrom?: any;
  requestedDateTo?: any;
  joinedDateTo?: any;
  joinedDateFrom?: any;
  durationFrom?: any;
  durationTo?: any;
}

export class ExpertsMatchedFilterModel {
  enterpriseProjectId: number;
  searchText?: string;
  countryIds?: string;
  sectorIds?: string;
  specializationIds?: string;
  languageIds?: string;
  totalCount?: number;
  pageSize: number;
  pageNumber: number;
  //projectIds?: string;
}

export enum AssistanceRequestStatusEnum {
  SubmittedWithinRegister = 0,
  New = 1,
  FBApproved = 2,
  FBRejected = 3,
  EnterpriseFilledApplication = 4,
  ExpertAssigned = 5,
  ExpertDeclined = 6,
  ExpertWithdrawn = 7,
  NewCase = 8, //expert approved the mission and this request will be moved to the cases
  ExpertAddedFeedback = 9,
  EnterpriseAddedFeedback = 10,
  FocalPointAddedFeedbachAndClose = 11,
}
