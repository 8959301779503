import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Config } from '../models/config';

@Injectable({
  providedIn: 'root',
})
export class CasesService {

  constructor(private http: HttpClient) { }

  getListAdminCases(filterModel: any): any {
    return this.http.get(`${environment.apiUrl}/Case/ListAdminCases`, {
      params: filterModel,
    });
  }

  getCaseDetailsByAdmin(id: any): any {
    return this.http.get(`${environment.apiUrl}/Case/GetCaseDetailsByAdmin/` + id);
  }

  addAdminFinalFeedback(data: any) {
    return this.http.post(
      `${environment.apiUrl}/Case/AddAdminFinalFeedback`,
      data
    );
  }

  WithdrawCaseFromExpert(id: any): any {
    return this.http.get(`${environment.apiUrl}/Case/WithdrawCaseFromExpert?caseId=${id}`);
  }
  getCasesFilters(): any {
    return this.http.get(
      `${environment.apiUrl}/Case/GetCasesFilters`
    );
  }

  getGeneralQuestionsFeedback(data: any): any {
    return this.http.post(
      `${environment.apiUrl}/Case/GetGeneralQuestionsFeedback?assistanceRequestId=${data.assistanceRequestId}`,
      data
    );
  }

  getKPIFeedback(data: any): any {
    return this.http.post(
      `${environment.apiUrl}/Case/GetKPIFeedback?assistanceRequestId=${data.assistanceRequestId}&areaId=${data.areaId}`,
      data
    );
  }

  getOtherQuestionsGroupFeedback(data: any): any {
    return this.http.post(
      `${environment.apiUrl}/Case/GetOtherQuestionsGroupFeedback?assistanceRequestId=${data.assistanceRequestId}&groupId=${data.groupId}`,
      data
    );
  }
}

