import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Config } from '../models/config';

@Injectable({
  providedIn: 'root',
})
export class EnterpriseRequestsService {
  constructor(private http: HttpClient) {}

  getListEnterprisesRequests(filterModel: any): any {
    return this.http.get(
      `${environment.apiUrl}/Enterprise/ListEnterpriseRegistrationsRequests`,
      {
        params: filterModel,
      }
    );
  }
  getListEnterprises(filterModel: any): any {
    return this.http.get(`${environment.apiUrl}/Enterprise/ListEnterprises`, {
      params: filterModel,
    });
  }
  getEnterpriseRequestDetails(id: number) {
    return this.http.get(
      `${environment.apiUrl}/Enterprise/GetEnterpiseRegistrationRequestDetails/${id}`
    );
  }
  getEnterpriseDetails(id: number) {
    return this.http.get(
      `${environment.apiUrl}/Enterprise/GetEnterpriseDetails/${id}`
    );
  }

  registrationDecision(data: any,) {
    console.log(data);

    let obj = {
      id: data.id,
      rejectionReason: data.rejectionReason,
      response: data.response,
    }
    if (data.isAssistanceRequestView) {
      return this.http.post(
        `${environment.apiUrl}/AssistanceRequest/SubmitAssistanceRequestDecision`,
        obj
      );
    } else if (data.from) {
      return this.http.post(
        `${environment.apiUrl}/Enterprise/SubmitProfileUpdatesDecision`,
        obj
      );
    } else {
      return this.http.post(
        `${environment.apiUrl}/Enterprise/SubmitRegistrationDecision`,
        obj
      );
    }
  }


  sendResetPasswordLink(id: number) {
    return this.http.get(
      `${environment.apiUrl}/Enterprise/SendResetPasswordLink?id=${id}`
    );
  }

  removeEnterprise(data: any) {
    return this.http.delete(`${environment.apiUrl}/Enterprise/RemoveEnterprise`, {
      params: data,
    });
  }
  updateEnterprise(data: any) {
    return this.http.put(`${environment.apiUrl}/Enterprise/UpdateEnterprise`, data);
  }

  getDistinctTaxOrRegirtrationId(data: any) {
    return this.http.get(
      `${environment.apiUrl}/Enterprise/DistinctTaxOrRegirtrationId`,
      {
        params: data,
      }
    );
  }
  checkDistinctEnterprise(data: any) {
    return this.http.get(`${environment.apiUrl}/Enterprise/DistinctEnterprise`, {
      params: data,
    });
  }
  validateEmail(data: any) {
    return this.http.get(`${environment.apiUrl}/Enterprise/ValidateEmail`, {
      params: data,
    });
  }
  getAdditionalInfoQuestions(){
    return this.http.get(`${environment.apiUrl}/Enterprise/GetAdditionalInfoQuestions`);
  }

}
