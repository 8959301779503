import { City } from './../../../models/lookups/City';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { MatChipInputEvent, MatChipList } from '@angular/material/chips';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Country } from 'src/app/models/lookups/Country';
import { Language } from 'src/app/models/lookups/Language';
import { ExpertRequestsService } from 'src/app/services/expert-requests';
import Swal from 'sweetalert2';
import { EnterpriseRequestsService } from 'src/app/services/enterprise-requests';
import { Enterprise } from 'src/app/models/enterpris-requests/enterpriseRequests';
import { LookupsService } from 'src/app/services/lookups.service';
import { ApplicantPosition } from 'src/app/models/lookups/ApplicantPosition';
import { CompaniesSize } from 'src/app/models/lookups/CompaniesSize';
import { checkErrorsDialogComponent } from 'src/app/shared/check-errors-dialog/check-errors-dialog.component';

export interface DialogData {
  id: number;
}

@Component({
  selector: 'app-enterprise-update',
  templateUrl: './enterprise-update.component.html',
})
export class EnterpriseUpdateComponent implements OnInit {
  loading = false;
  dataLoading = false;
  isSubmitted = false;
  formGroupAppInfoChanged = false;
  formGroupContactInfoChanged = false;
  formGroupEnterpriseDetailsChanged = false;
  dataSource: Enterprise;
  enterpriseModel: any;
  emailData: any;
  countrySearch: string = '';
  searchCityText: string = '';
  SearchPosition: string = '';
  listCompaniesSizes: CompaniesSize[] = [];
  communications: any;
  taxLoading = false;
  isduplicatedTax = false;
  isduplicatedEnterpise = false;

  //////// Language field config /////////////////
  @ViewChild('languageInput', { static: false })
  languageInput!: ElementRef<HTMLInputElement>;
  @ViewChild('languageAuto', { static: false }) langMatAutocomplete!: MatAutocomplete;
  @ViewChild('chipLanguagesList', { static: false }) chipLanguagesList!: MatChipList;
  langChipsErrorMsg: string = '';
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredLanguages!: Observable<any[]>;
  languages: Language[] = [];
  allLanguages: Language[] = [];
  showlanguagesDrop = false;
  ////////// End language field config //////////////

  //////// services field config /////////////////
  @ViewChild('serviceInput', { static: false })
  serviceInput!: ElementRef<HTMLInputElement>;
  @ViewChild('serviceAuto', { static: false }) serviceMatAutocomplete!: MatAutocomplete;
  @ViewChild('chipServicesList', { static: false }) chipServicesList!: MatChipList;
  serviceChipsErrorMsg: string = '';
  filteredServices!: Observable<any[]>;
  services: any[] = [];
  listProductsAndServices: CompaniesSize[] = [];
  showServicesDrop = false;
  ////////// End services field config //////////////

  //////////  additional Information //////////////
  additionalInforGroupSubmited = false;
  additionalInforGroup!: FormGroup;
  additionalInfoAnswers: any[] = [];
  formFields: any;

  ////////  positions field config /////////////////
  filteredPositions!: Observable<any[]>;
  listpositions: CompaniesSize[] = [];
  ////////// End position field config //////////////

  // countries
  allCountries: Country[] = [];

  // citys
  allCitys: City[] = [];

  // formGroup
  formGroupAppInfo: FormGroup;
  formGroupContactInfo: FormGroup;
  formGroupEnterpriseDetails: FormGroup;

  // steps
  pageIndex = 1;
  stepsCount = 4;
  createPages = [
    { index: 0, title: '' },
    {
      index: 1,
      title: this.translate.instant('enterprises.Update enterprise data'),
    },
    {
      index: 2,
      title: this.translate.instant('auth.enterpriseRegTitle2'),
    },
    {
      index: 3,
      title: this.translate.instant('auth.enterpriseRegTitle3'),
    },
    { index: 4, title: this.translate.instant('auth.enterpriseRegTitle8') },
  ];
  public myreg =
    /(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public translate: TranslateService,
    public dialogRef: MatDialogRef<EnterpriseUpdateComponent>,
    private formBuilder: FormBuilder,
    private lookupsService: LookupsService,
    private expertRequestsService: ExpertRequestsService,
    private enterpriseRequestsService: EnterpriseRequestsService,
    public dialog: MatDialog,
    private el: ElementRef
  ) { }

  ngOnInit(): void {
    this.buildForm();
    this.buildadditionalInforGroup();
    this.handelChipsFilters();
    if (this.data.id) {
      this.getDetails();
    }
  }

  getDetails() {
    this.dataLoading = true;
    this.enterpriseRequestsService.getEnterpriseDetails(this.data.id).subscribe(
      (data: any) => {
        this.dataLoading = false;
        this.dataSource = data;
        if (this.dataSource) {
          this.getData();
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  getData() {
    this.getAdditionalInfo();
    this.getCities(null, false);
    this.getCountries();
    this.getLanguages(null);
    this.getPositions();
    this.getCompaniesSizes();
    this.getProductsAndServicesDistinct();
    this.bindDataToUpdate();
  }
  checkChanges() {
    this.formGroupAppInfo.valueChanges.subscribe((selectedValue) => {
      if (selectedValue) {
        this.formGroupAppInfoChanged = true;
      }
    });
    this.formGroupContactInfo.valueChanges.subscribe((selectedValue) => {
      if (selectedValue) {
        this.formGroupContactInfoChanged = true;
      }
    });
    this.formGroupEnterpriseDetails.valueChanges.subscribe((selectedValue) => {
      if (selectedValue) {
        this.formGroupEnterpriseDetailsChanged = true;
      }
    });
  }
  stepChanged(event: any) {
    event.selectedStep.interacted = false;
    if (event.previouslySelectedIndex > event.selectedIndex) {
      event.previouslySelectedStep.interacted = false;
    }
  }
  getCountries() {
    this.lookupsService.getCountriesWithFilter(this.countrySearch).subscribe(
      (data: any) => {
        this.allCountries = data as Country[];
      },
      (err: any) => {
        console.error(err);
      }
    );
  }
  getCompaniesSizes() {
    this.lookupsService.getCompaniesSizes().subscribe(
      (data: any) => {
        this.listCompaniesSizes = data as CompaniesSize[];
      },
      (err: any) => {
        console.error(err);
      }
    );
  }
  getProductsAndServicesDistinct() {
    this.lookupsService.getProductsAndServicesDistinct().subscribe(
      (data: any) => {
        this.listProductsAndServices = data;
        this.formGroupEnterpriseDetails.controls.services.setValue(null);
      },
      (err: any) => {
        console.error(err);
      }
    );
  }

  getCities(id: any, change: boolean = false) {
    if (change) {
      console.log(id.value);
    }
    let citySearch = {
      searchText: this.searchCityText,
      countryId: change ? id.value : this.dataSource.country.id,
    };
    this.lookupsService.getCities(citySearch).subscribe(
      (data: any) => {
        this.allCitys = data as City[];
        if (change) {
          this.formGroupEnterpriseDetails.controls.citys.setValue(null);
        }
      },
      (err: any) => {
        console.error(err);
      }
    );
  }
  bindDataToUpdate() {
    console.log(this.dataSource);

    if (this.dataSource) {
      const enterprise = this.dataSource;
      this.formGroupAppInfo.controls.firstName.setValue(
        enterprise.applicantFirstName
      );
      this.formGroupAppInfo.controls.lastName.setValue(
        enterprise.applicantLastName
      );
      this.formGroupAppInfo.controls.email.setValue(enterprise.applicantEmail);
      this.formGroupAppInfo.controls.positions.setValue(enterprise.position);
      this.formGroupAppInfo.controls.contactPersonHasApplicantName.setValue(
        enterprise.contactPersonHasApplicantName
      );

      this.formGroupContactInfo.controls.contactPersonFirstName.setValue(
        enterprise.contactPersonFirstName
      );

      this.formGroupContactInfo.controls.contactPersonLastName.setValue(
        enterprise.contactPersonLastName
      );
      if (enterprise.languages.length > 0) {
        this.languages = enterprise.languages.map((item: any) => {
          return {
            id: item.id,
            name: item.name,
          };
        });
      } else {
        this.languages = [];
      }

      if (enterprise.productAndServices.length > 0) {
        this.services = enterprise.productAndServices.map((item: any) => {
          return {
            id: item.id,
            name: item.name,
          };
        });
      } else {
        this.services = [];
      }
      if (enterprise.contactInfo.length > 0) {
        this.updateCommunicationsField(enterprise.contactInfo);
      }
      this.formGroupEnterpriseDetails.controls.enterpriseName.setValue(
        enterprise.enterpriseName
      );
      this.formGroupEnterpriseDetails.controls.country.setValue(
        enterprise.country.id
      );
      this.formGroupEnterpriseDetails.controls.citys.setValue(
        enterprise.city.id
      );
      this.formGroupEnterpriseDetails.controls.address.setValue(
        enterprise.address
      );
      this.formGroupEnterpriseDetails.controls.website.setValue(
        enterprise.website
      );
      this.formGroupEnterpriseDetails.controls.registrationId.setValue(
        enterprise.registrationId
      );
      this.formGroupEnterpriseDetails.controls.enterpriseSize.setValue(
        enterprise.enterpriseSize
      );
      this.formGroupEnterpriseDetails.controls.employeeNumber.setValue(
        enterprise.employeeNumber.id
      );
      setTimeout(() => {
        this.checkChanges();
      }, 1000);
      if(enterprise.contactPersonHasApplicantName) {
        this.addApplicantName()
      }
    }
  }
  bindadditionalInforGroup() {
    if (
      this.radioControlsGroup.controls.length &&
      this.dataSource.additionalIfnoQuestionsAnswers.length
    ) {
      this.radioControlsGroup.controls.filter((quetions: any) => {
        console.log('quetions', quetions);
        this.dataSource.additionalIfnoQuestionsAnswers.some((answers: any) => {
          console.log('answers', answers);
          if (quetions.value.id === answers.id) {
            if (answers.answer.answerId == 0) {
              quetions.controls.additionalInfoText.setValue(
                answers.answer.text
              );
              this.addAnswer(
                answers.id,
                quetions.value.questionTypeCode,
                null,
                answers.answer.text
              );
            } else {
              quetions.value.options.map((e: any) => {
                if (e.id == answers.answer.answerId) {
                  quetions.controls.radioControl.setValue(
                    answers.answer.answerId
                  );
                  this.addAnswer(
                    answers.id,
                    quetions.value.questionTypeCode,
                    e.id,
                    null
                  );
                }
              });
            }
          }
        });
      });
    }
  }
  updatePhoneCode(code: any) { }
  getLanguages(event: any) {
    this.expertRequestsService
      .getLanguagesForExpert('')
      .subscribe((data: any) => {
        if (data) {
          this.allLanguages = data as Language[];
          this.formGroupContactInfo.controls.languages.setValue(null);
        }
      });
  }
  getPositions() {
    this.lookupsService.getPositionsDistinct('').subscribe((data: any) => {
      this.listpositions = data;
    });
  }

  buildForm() {
    this.formGroupAppInfo = this.formBuilder.group({
      firstName: [
        '',
        [
          Validators.required,
          Validators.pattern('^(([^ ]?)(^[a-zA-Z].*[a-zA-Z]$)([^ ]?))$'),
        ],
      ],
      lastName: [
        '',
        [
          Validators.required,
          Validators.pattern('^(([^ ]?)(^[a-zA-Z].*[a-zA-Z]$)([^ ]?))$'),
        ],
      ],
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{1,}[.]{1}[a-zA-Z]{2,}'
          ),
        ],
        [this.uniqueEmailValidator()],
      ],
      positions: ['', Validators.required],
      contactPersonHasApplicantName: [false],
    });
    this.formGroupContactInfo = this.formBuilder.group({
      contactPersonFirstName: [
        '',
        [
          Validators.required,
          Validators.pattern('^(([^ ]?)(^[a-zA-Z].*[a-zA-Z]$)([^ ]?))$'),
        ],
      ],
      contactPersonLastName: [
        '',
        [
          Validators.required,
          Validators.pattern('^(([^ ]?)(^[a-zA-Z].*[a-zA-Z]$)([^ ]?))$'),
        ],
      ],
      languages: [this.languages, this.validateChips],
      communications: [this.communications, Validators.required],
    });
    this.formGroupEnterpriseDetails = this.formBuilder.group({
      enterpriseName: [
        '',
        [
          Validators.required,
          Validators.pattern('^(([^ ]?)(^[a-zA-Z].*[a-zA-Z]$)([^ ]?))$'),
        ],
      ],
      country: ['', Validators.required],
      citys: ['', Validators.required],
      address: ['', Validators.required],
      website: ['', [Validators.pattern(this.myreg)]],
      registrationId: [
        '',
        [Validators.required, Validators.pattern(/^[a-zA-Z0-9]*$/)],
      ],
      enterpriseSize: ['', Validators.required],
      employeeNumber: ['', Validators.required],
      services: [this.services, this.validateChips],
    });

    this.formGroupContactInfo.get('languages')!.statusChanges.subscribe((status) => {
      this.chipLanguagesList.errorState = status === 'INVALID';
      if (
        !this.languages.length &&
        this.languageInput.nativeElement.value === ''
      ) {
        this.langChipsErrorMsg = this.translate.instant('forms.Required');
      } else {
        this.langChipsErrorMsg = this.translate.instant('forms.validLang');
      }
    });

    // this.formGroupEnterpriseDetails.get('services')!.statusChanges.subscribe((status) => {
    //     this.chipServicesList.errorState = status === 'INVALID';
    //     if (
    //       !this.services.length &&
    //       this.serviceInput.nativeElement.value === ''
    //     ) {
    //       this.serviceChipsErrorMsg = this.translate.instant('forms.Required');
    //     } else {
    //       this.serviceChipsErrorMsg = this.translate.instant('forms.validLang');
    //     }
    //   });
    this.formGroupEnterpriseDetails.get('services')!.statusChanges.subscribe((status) => {
      this.chipServicesList.errorState = status === 'INVALID';
      if (!this.services.length && this.serviceInput.nativeElement.value === '') {
        this.chipServicesList.errorState = status === 'INVALID';
        this.serviceChipsErrorMsg = this.translate.instant('forms.Required');
      } else if (!this.services.length && this.serviceInput.nativeElement.value !== '') {
        this.chipServicesList.errorState = status === 'INVALID';
        this.serviceChipsErrorMsg = this.translate.instant('forms.pressEnter');
      } else {
        if (status === 'INVALID') {
          this.chipServicesList.errorState = status === 'INVALID';
          this.serviceChipsErrorMsg = this.translate.instant('forms.pressEnter');
        } else {
          this.chipServicesList.errorState = status === null;
        }
      }
    });
  }

  buildadditionalInforGroup() {
    this.additionalInforGroup = this.formBuilder.group({
      radioControlsGroup: this.formBuilder.array([]),
    });
  }

  get radioControlsGroup(): FormArray {
    return this.additionalInforGroup.get('radioControlsGroup') as FormArray;
  }

  newRadioControl(id: number, name: any, type: any, options: any): FormGroup {
    return this.formBuilder.group({
      id: [id],
      text: [name],
      questionTypeCode: [type],
      options: [options],
      radioControl: [''],
      additionalInfoText: ['', Validators.maxLength(500)],
      answer: [''],
    });
  }

  getAdditionalInfo() {
    this.enterpriseRequestsService.getAdditionalInfoQuestions().subscribe(
      (data) => {
        this.formFields = data as any[];
        this.formFields.map((e: any) => {
          this.radioControlsGroup.push(
            this.newRadioControl(e.id, e.text, e.questionTypeCode, e.options)
          );
        });
        this.bindadditionalInforGroup();
      },
      (err) => {
        console.error(err);
      }
    );
  }

  addAnswer(
    fieldId: number,
    questionTypeCode: number,
    optionId?: any,
    text?: any
  ) {
    console.log(this.additionalInforGroup.controls.radioControlsGroup);
    let field: any = {
      additionalInfoQuestionId: fieldId,
      questionTypeCode: questionTypeCode,
    };
    if (optionId) {
      field.answerId = optionId;
    }
    if (text) {
      field.text = text;
    }
    this.upsert(this.additionalInfoAnswers, field);
  }
  upsert(array: any, element: any) {
    const i = array.findIndex(
      (_element: any) =>
        _element.additionalInfoQuestionId === element.additionalInfoQuestionId
    );
    if (i > -1) array[i] = element;
    else array.push(element);
  }

  remove(obj: any, parentObj: any, control: any, form: any): void {
    const index = parentObj.indexOf(obj);
    if (index >= 0) {
      parentObj.splice(index, 1);
      form.get(control)!.setValue(null);
    }
  }
  hasError(formGroup: any, controlName: string, errorName: string) {
    return formGroup.controls[controlName].hasError(errorName);
  }

  hasArrayError(formGroup: any, controlName: string, errorName: string) {
    return formGroup.get(controlName).hasError(errorName);
  }

  checkDistinctEnterprise() {
    let enterprise = {
      enterpriseName:
        this.formGroupEnterpriseDetails.get('enterpriseName')!.value || '',
      countryId: this.formGroupEnterpriseDetails.get('country')!.value || null,
      cityId: this.formGroupEnterpriseDetails.get('citys')!.value || null,
      enterpriseId:
        this.dataSource && this.dataSource.id ? this.dataSource.id : '',
    };
    console.log(enterprise);

    if (
      enterprise.enterpriseName != '' &&
      enterprise.countryId &&
      enterprise.cityId
    ) {
      this.enterpriseRequestsService
        .checkDistinctEnterprise(enterprise)
        .subscribe(
          (data: any) => {
            this.isduplicatedEnterpise = false;
            if (
              !this.formGroupEnterpriseDetails
                .get('enterpriseName')
                ?.hasError('pattern')
            ) {
              this.formGroupEnterpriseDetails
                .get('enterpriseName')!
                .setErrors(null);
            }
            this.formGroupEnterpriseDetails.get('country')!.setErrors(null);
            this.formGroupEnterpriseDetails.get('citys')!.setErrors(null);
            if (data && data.Code == -3) {
              this.isduplicatedEnterpise = true;
              this.formGroupEnterpriseDetails
                .get('enterpriseName')!
                .setErrors({ valid: true });
              this.formGroupEnterpriseDetails
                .get('country')!
                .setErrors({ valid: true });
              this.formGroupEnterpriseDetails
                .get('citys')!
                .setErrors({ valid: true });
              Swal.fire({
                title: this.translate.instant('swal.Failed'),
                text: data.ErrorMessages[0],
                icon: 'warning',
                confirmButtonText: this.translate.instant('swal.ok'),
              });
            }
          },
          (err: any) => {
            this.isduplicatedEnterpise = false;
            console.error(err);
          }
        );
    } else {
      console.log(enterprise.enterpriseName);
      console.log(this.formGroupEnterpriseDetails.get('enterpriseName'));

      if (
        enterprise.enterpriseName != '' &&
        !this.formGroupEnterpriseDetails
          .get('enterpriseName')
          ?.hasError('pattern')
      ) {
        this.formGroupEnterpriseDetails.get('enterpriseName')!.setErrors(null);
      }
      if (enterprise.countryId) {
        this.formGroupEnterpriseDetails.get('country')!.setErrors(null);
      }
      if (enterprise.cityId) {
        this.formGroupEnterpriseDetails.get('city')!.setErrors(null);
      }

      this.isduplicatedEnterpise = false;
    }
    if (
      this.formGroupEnterpriseDetails.get('enterpriseName')?.hasError('pattern')
    ) {
      this.formGroupEnterpriseDetails
        .get('enterpriseName')!
        .setErrors({ pattern: true });
    }
  }
  getDistinctTaxOrRegirtrationId() {
    this.taxLoading = true;
    const taxOrRegirtration = {
      taxOrRegirtrationId:
        this.formGroupEnterpriseDetails.get('registrationId')!.value || '',
      enterpriseId:
        this.dataSource && this.dataSource.id ? this.dataSource.id : '',
    };
    if (taxOrRegirtration.taxOrRegirtrationId != '') {
      this.enterpriseRequestsService
        .getDistinctTaxOrRegirtrationId(taxOrRegirtration)
        .subscribe(
          (data: any) => {
            this.taxLoading = false;
            this.isduplicatedTax = false;
            if (
              !this.formGroupEnterpriseDetails.get('registrationId')?.errors
            ) {
              this.formGroupEnterpriseDetails
                .get('registrationId')!
                .setErrors(null);
            }
            if (data && data.Code == -3) {
              this.isduplicatedTax = true;
              this.formGroupEnterpriseDetails
                .get('registrationId')!
                .setErrors({ valid: true });
              Swal.fire({
                title: this.translate.instant('swal.Failed'),
                text: data.ErrorMessages[0],
                icon: 'warning',
                confirmButtonText: this.translate.instant('swal.ok'),
              });
            }
          },
          (err) => {
            this.taxLoading = false;
            this.isduplicatedTax = false;
            console.error(err);
          }
        );
    }
  }

  isInArray(parentObj: any, item: any) {
    return parentObj.some((obj: any) => obj.id === item.id);
  }
  checkEmail() {
    this.emailData = {
      email: this.formGroupAppInfo.controls.email.value,
      userId: this.dataSource.userId,
    };
  }
  uniqueEmailValidator(): AsyncValidatorFn {
    //debugger;
    return (
      control: AbstractControl
    ): Observable<{ [key: string]: any } | null> => {
      return this.enterpriseRequestsService.validateEmail(this.emailData).pipe(
        map((res: any) => {
          if (res.Code == -3) {
            return { emailTaken: true };
          } else {
            return null;
          }
        })
      );
    };
  }
  private validateChips(control: FormControl) {
    if (control.value && control.value.length === 0) {
      return {
        validateChipsArray: { valid: false },
      };
    }

    return null;
  }

  addService(event: any) {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      const itemFound = this.services.find(
        (item: any) => value.trim() == item.name
      );
      if (itemFound == undefined) {
        this.services.push({ id: null, name: value.trim() });
      }
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.formGroupEnterpriseDetails.get('services')!.setValue(null);
  }

  // Chips, autocomplete fields methods
  handelChipsFilters() {
    this.filteredLanguages = this.formGroupContactInfo
      .get('languages')!
      .valueChanges.pipe(
        startWith(''),
        map((language) =>
          language
            ? this._filter(
              language,
              this.allLanguages,
              'languages',
              this.formGroupContactInfo
            )
            : this.allLanguages.slice()
        )
      );
    this.filteredServices = this.formGroupEnterpriseDetails
      .get('services')!
      .valueChanges.pipe(
        startWith(''),
        map((service) =>
          service
            ? this._filter(
              service,
              this.listProductsAndServices,
              'services',
              this.formGroupEnterpriseDetails
            )
            : this.listProductsAndServices.slice()
        )
      );
    this.filteredPositions = this.formGroupAppInfo
      .get('positions')!
      .valueChanges.pipe(
        startWith(''),
        map((position) =>
          position
            ? this._filter(
              position,
              this.listpositions,
              'positions',
              this.formGroupAppInfo
            )
            : this.listpositions.slice()
        )
      );
  }

  private _filter(
    filteritem: any,
    parentObj: any,
    control: string,
    formName: any
  ) {
    if (typeof filteritem === 'string' || filteritem instanceof String) {
      const matches = parentObj.filter((obj: any) =>
        obj.name.toLowerCase().includes(filteritem.toLowerCase())
      );
      if (filteritem !== '') {
        if (control == 'languages') {
          formName.get(control)!.setErrors({ valid: false });
        } else if (control == 'services') {
          formName.get(control)!.setErrors({ valid: false });
        }
      }
      return matches;
    } else if (filteritem instanceof Object) {
    }
  }

  checkNext(formGroup: any, formName: any) {
    if (formName === 'formGroupEnterpriseDetails') {

      console.log(this.services.length);
      if (!this.services.length) {
        this.formGroupEnterpriseDetails
          .get('services')!
          .setErrors({ valid: false });
      } else {
        this.formGroupEnterpriseDetails
          .get('services')!
          .setErrors(null);
      }

      if (this.isduplicatedTax) {
        this.formGroupEnterpriseDetails
          .get('registrationId')!
          .setErrors({ valid: false });
      }
      if (this.isduplicatedEnterpise) {
        this.formGroupEnterpriseDetails
          .get('enterpriseName')!
          .setErrors({ valid: false });
        this.formGroupEnterpriseDetails
          .get('country')!
          .setErrors({ valid: false });
        this.formGroupEnterpriseDetails
          .get('citys')!
          .setErrors({ valid: false });
      }
      console.log(this.formGroupEnterpriseDetails);

    }

    if (formName === 'formGroupContactInfo') {
      if (!this.languages.length) {
        this.formGroupContactInfo.get('languages')!.setErrors({ valid: false });
      }
      this.isSubmitted = true;
    }

    if (formName === 'additionalInforGroup') {
      this.additionalInforGroupSubmited = true;
    }

    // if valid form go next step
    if (formGroup.valid) {
      window.scrollTo(0, 0);
      this.pageIndex++;
    }
  }

  selectedLang(event: MatAutocompleteSelectedEvent): void {
    this.languageInput.nativeElement.blur();
    setTimeout(() => {
      this.languageInput.nativeElement.focus();
    }, 1);
    if (!this.isInArray(this.languages, event.option.value)) {
      this.languages.push(event.option.value);
      this.languageInput.nativeElement.value = '';
      this.formGroupContactInfo.get('languages')!.setValue(null);
    }
  }
  selectedService(event: MatAutocompleteSelectedEvent): void {
    this.serviceInput.nativeElement.blur();
    setTimeout(() => {
      this.serviceInput.nativeElement.focus();
    }, 1);
    if (!this.isInArray(this.services, event.option.value)) {
      this.services.push(event.option.value);
      this.serviceInput.nativeElement.value = '';
    }
    this.formGroupEnterpriseDetails.get('services')!.setValue(null);
  }
  addApplicantName() {
    if (this.formGroupAppInfo.controls.contactPersonHasApplicantName.value) {
      this.formGroupContactInfo.controls.contactPersonFirstName.setValue(
        this.formGroupAppInfo.controls.firstName.value
      );
      this.formGroupContactInfo.controls.contactPersonFirstName.disable();
      this.formGroupContactInfo.controls.contactPersonLastName.setValue(
        this.formGroupAppInfo.controls.lastName.value
      );
      this.formGroupContactInfo.controls.contactPersonLastName.disable();
    } else {
      this.formGroupContactInfo.controls.contactPersonFirstName.setValue(null);
      this.formGroupContactInfo.controls.contactPersonFirstName.enable();
      this.formGroupContactInfo.controls.contactPersonLastName.setValue(null);
      this.formGroupContactInfo.controls.contactPersonLastName.enable();
    }
  }

  updateCommunicationsField($event: any) {
    this.communications = $event;
    this.formGroupContactInfo.controls.communications.setValue(
      this.communications
    );
    this.communications.map((e: any) => {
      if (!e.value || (e.type == 2 && !this.checkIfEmail(e.value))) {
        this.formGroupContactInfo.controls.communications!.setErrors({
          valid: false,
        });
      }
    });
  }

  checkIfEmail(str: any) {
    // Regular expression to check if string is email
    const regexExp =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;

    return regexExp.test(str);
  }

  displayFn(obj: any): string {
    if (obj && 'name' in obj) {
      return obj && obj.name ? obj.name : '';
    }
    return '';
  }
  updateEnterpriseModel() {
    this.enterpriseModel = {
      id: this.dataSource.id,
      firstName: this.formGroupAppInfo.get('firstName')!.value,
      lastName: this.formGroupAppInfo.get('lastName')!.value,
      email: this.formGroupAppInfo.get('email')!.value,
      contactPersonHasApplicantName: this.formGroupAppInfo.get('contactPersonHasApplicantName')!.value,
      position: null,
      contactPersonFirstName: this.formGroupContactInfo.get(
        'contactPersonFirstName'
      )!.value,
      contactPersonLastName: this.formGroupContactInfo.get(
        'contactPersonLastName'
      )!.value,
      enterpriseName:
        this.formGroupEnterpriseDetails.get('enterpriseName')!.value,
      countryId: this.formGroupEnterpriseDetails.controls.country.value,
      cityId: this.formGroupEnterpriseDetails.controls.citys.value,
      address: this.formGroupEnterpriseDetails.controls.address.value,
      website: this.formGroupEnterpriseDetails.controls.website.value,
      taxOrRegistrationId:
        this.formGroupEnterpriseDetails.controls.registrationId.value,
      enterpriseSize:
        this.formGroupEnterpriseDetails.controls.enterpriseSize.value,
      employeesNumberId:
        this.formGroupEnterpriseDetails.controls.employeeNumber.value,
      productsAndServicesProvided: this.services,
      communications: this.communications,
      languages: [],
      enterpriseAdditionalInfoQuesAnswers: this.additionalInfoAnswers,
    };

    this.languages.forEach((element: any) => {
      this.enterpriseModel.languages.push(element.id);
    });
    if (this.formGroupAppInfo.controls.positions.value.id) {
      this.enterpriseModel.position = {
        name: this.formGroupAppInfo.controls.positions.value.name,
        id: this.formGroupAppInfo.controls.positions.value.id,
      };
    } else {
      this.enterpriseModel.position = {
        name: this.formGroupAppInfo.controls.positions.value,
        id: null,
      };
    }
    if (
      this.enterpriseModel.productsAndServicesProvided &&
      this.enterpriseModel.productsAndServicesProvided.length > 0
    ) {
      this.formGroupEnterpriseDetails.controls.services.clearValidators();
      this.formGroupEnterpriseDetails.controls.services.updateValueAndValidity();
    }
    // this.EenterpriseModel.enterpriseAdditionalInfoQuesAnswers = [];
    // this.additionalInfoAnswers.forEach((q) => {
    //   this.EenterpriseModel.enterpriseAdditionalInfoQuesAnswers.push({
    //       id: q.additionalInfoQuestionId,
    //       text: q.text,
    //       questionTypeCode: q.questionTypeCode,
    //       options: [
    //         {
    //           id: q.answerId,
    //           text:  q.text
    //         }
    //       ]
    //   })
    // })
    this.enterpriseModel.enterpriseAdditionalInfoQuesAnswers =
      this.additionalInfoAnswers;
  }
  updateEnterprise() {
    this.updateEnterpriseModel();
    if (
      this.formGroupAppInfo.valid &&
      this.formGroupContactInfo.valid &&
      this.formGroupEnterpriseDetails.valid
    ) {
      this.loading = true;
      this.enterpriseRequestsService
        .updateEnterprise(this.enterpriseModel)
        .subscribe(
          (res: any) => {
            this.loading = false;
            this.dialogRef.close(true);
            Swal.fire({
              title: this.translate.instant('swal.success'),
              text: this.translate.instant('swal.updatedsuccessfully'),
              icon: 'success',
              confirmButtonText: this.translate.instant('swal.ok'),
            }).then(() => { });
          },
          (err: any) => {
            this.loading = false;
            let error = JSON.parse(err.detail);

            const dialogRef = this.dialog.open(
              checkErrorsDialogComponent,
              {
                width: '530px',
                data: error,
                closeOnNavigation: true,
              }
            );
          }
        );
    }
  }
  closeDialog() {
    if (
      this.formGroupAppInfoChanged ||
      this.formGroupContactInfoChanged ||
      this.formGroupEnterpriseDetailsChanged
    ) {
      Swal.fire({
        title: this.translate.instant('swal.Areyousure'),
        text: this.translate.instant('swal.You will lose the data you entered'),
        icon: 'warning',
        confirmButtonText: this.translate.instant('swal.ok'),
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.dialogRef.close();
        } else if (result.isDenied) {
        }
      });
    } else {
      this.dialogRef.close();
    }
  }
  @HostListener('document:click', ['$event']) onDocumentClick( event: MouseEvent) {

    if (
      !this.el.nativeElement.contains(event.target) &&
      document.querySelector('.cdk-overlay-backdrop') == event.target
    ) {
      this.closeDialog()
    }
  }
}
