<mat-dialog-content class="createUserDialog createDialog assign-expert">
  <section class="createUser assign-expert-dialog">
    <div class="auth-card">
      <div class="d-flex align-items-center justify-content-between">
        <div>
          <h2 class="title">{{ 'matchmaking.Assign Expert(s)' | translate }}</h2>
          <p>{{ 'matchmaking.Select from' | translate }} {{totalCount}} {{ 'matchmaking.ExpertsFirst' | translate
            }} </p>
        </div>
        <div class="page-filters mb-0">
          <div class="filters-container d-flex align-items-center justify-content-between">
            <!-- <form (submit)="makeSearch()" class="search-input">
              <mat-form-field appearance="legacy" *ngIf="showSearch">
                <input matInput autocomplete="off" placeholder="{{ 'users.Search by name' | translate }}"
                  [ngModelOptions]="{ standalone: true }" [(ngModel)]="filterModel.searchText"
                  (ngModelChange)="resetAfterClear()" />
                <mat-icon (click)="resetSearch(); showSearch = false" matSuffix>clear</mat-icon>
              </mat-form-field>
              <button mat-icon-button (click)="makeSearch()">
                <mat-icon>search</mat-icon>
              </button>
            </form> -->
            <button (click)="showFilter = !showFilter" [ngClass]="{ active: showFilter }"
              class="btn dropdown-custom dropdown-toggle">
              <svg width="15.999" height="14.003" viewBox="0 0 15.999 14.003">
                <path id="filter-icon"
                  d="M-3454.74,9334l-.229-.108a1.407,1.407,0,0,1-.8-1.033l-.073-.414-7.714-.017-.115-.061a.564.564,0,0,1-.328-.541.6.6,0,0,1,.354-.532,31.653,31.653,0,0,1,3.863-.059h3.94l.073-.407a1.359,1.359,0,0,1,.408-.769,1.377,1.377,0,0,1,.691-.377,14.429,14.429,0,0,1,2.2-.015,1.44,1.44,0,0,1,.766.4,1.335,1.335,0,0,1,.4.769l.073.4h1.492a6.011,6.011,0,0,1,1.354.047.6.6,0,0,1,.379.551.566.566,0,0,1-.337.539c-.1.054-.19.056-1.389.067l-1.5.014-.07.408a1.419,1.419,0,0,1-.8,1.035l-.227.107Zm.19-1.155h2.029v-2.01h-2.029Zm-3.825-3.629a11.142,11.142,0,0,1-1.253-.047l0,0a1.478,1.478,0,0,1-1.047-1.028,1.029,1.029,0,0,1-.047-.234v-.3h-1.546c-1.293,0-1.3,0-1.406-.056a.779.779,0,0,1-.229-.2.4.4,0,0,1-.075-.33c0-.229,0-.232.075-.333a.841.841,0,0,1,.216-.192c.122-.061.126-.062,1.419-.062h1.546l0-.291a1.258,1.258,0,0,1,.049-.255,1.49,1.49,0,0,1,1-1.015,15.02,15.02,0,0,1,2.38,0,1.45,1.45,0,0,1,1.068,1.137l.075.429h7.721l.113.059a.785.785,0,0,1,.225.191c.082.11.082.11.084.363a.392.392,0,0,1-.059.279.909.909,0,0,1-.2.191l-.1.068-7.786.019-.075.434a1.439,1.439,0,0,1-1.089,1.136,8.717,8.717,0,0,1-.879.044C-3458.256,9329.217-3458.316,9329.217-3458.375,9329.215Zm-1.1-1.211h2.026v-1.97h-2.026Zm7.536-3.591a3.16,3.16,0,0,1-1.424-.169,1.9,1.9,0,0,1-.581-.531,1.505,1.505,0,0,1-.192-.593v-.321h-4.816a45.5,45.5,0,0,1-4.69-.056.6.6,0,0,1-.354-.541.566.566,0,0,1,.333-.539l.11-.054,9.384-.021.042-.377a1.417,1.417,0,0,1,.963-1.132,4.379,4.379,0,0,1,1.326-.051c1.134.007,1.148.012,1.307.077a1.707,1.707,0,0,1,.712.616,2.3,2.3,0,0,1,.2.665l.021.146.15.025a3.7,3.7,0,0,0,.541.04c.534.014.628.063.752.191a.612.612,0,0,1-.042.851c-.131.122-.216.164-.731.164h-.656l-.054.316a1.385,1.385,0,0,1-.79,1.122,3.117,3.117,0,0,1-1.1.194A3.68,3.68,0,0,1-3451.94,9324.413Zm-.963-3.018a6.942,6.942,0,0,0-.028.825v.977h2.069v-2.01h-1c-.26,0-.527,0-.792.023l-.246-.023Z"
                  transform="translate(3464 -9320)" fill="#788da5" opacity="0.8" />
              </svg>
              <span>{{ "general.Filter" | translate }}</span>
              <svg width="8" height="5.001" viewBox="0 0 8 5.001">
                <path id="menu-icon" d="M-3.562-9.316-.5-6.215l3.062-3.1.938.949-4,4.051-4-4.051Z"
                  transform="translate(4.5 9.316)" fill="#788da5" opacity="0.8" />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <div class="filter-section mt-3" [hidden]="!showFilter">
        <label>{{ "general.Filter by" | translate }}</label>
        <div class="row">
          <div class="col-lg-6 col-sm-4 mb-2 filter-sectors">
            <app-chips-tree-search *ngIf="checkIfTreeShow" [isDataFound]="sectorsTree && sectorsTree.length > 0"
              [showIsicCode]="true" [TREE_DATA]="sectorsTree" [selected]="selectedProject" [loading]="projectsLoading"
              [isRequired]="true" [showAllSelectedChips]="false" [label]="'project.Project\'s Sectors' | translate"
              (selectedItems)="selectedProject = $event;updateFilter()" [isListFilters]="true">
            </app-chips-tree-search>
          </div>
          <div class="col-lg-3 col-md-4 mb-2">
            <mat-form-field class="no-validation">
              <mat-label>{{ "expert.Country" | translate }}</mat-label>
              <mat-select [(ngModel)]="countryIds" multiple (selectionChange)="updateFilter()">
                <mat-option [value]="country.id" *ngFor="let country of allCountries">{{ country.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-lg-3 col-sm-4 mb-2">
            <mat-form-field class="no-validation">
              <mat-label>{{ "questionnaires.Select specialization" | translate }}</mat-label>
              <mat-select [(ngModel)]="specializationIds" multiple (selectionChange)="updateFilter()">
                <mat-option *ngFor="let specialization of specializationList" [value]="specialization.id">{{
                  specialization.name
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-lg-3 col-sm-4 mb-2">
            <mat-form-field class="no-validation">
              <mat-label>{{ "Language.Languages" | translate }}</mat-label>
              <mat-select [(ngModel)]="languageIds" multiple (selectionChange)="updateFilter()">
                <mat-option *ngFor="let language of allLanguages" [value]="language.id">{{ language.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-lg-9 col-sm-12 mb-2 text-right">
            <button mat-flat-button class="mr-2 clear-btn" (click)="clearFilter()">{{ "general.Clear" | translate
              }}</button>
            <button mat-raised-button color="primary" (click)="getListExpertsMatchedWithEnterprise(null)">{{
              "general.Apply filter" |
              translate }}</button>
          </div>

        </div>
      </div>
    </div>




    <div *ngIf="!dataSource.length && dataLoading;" class="dataLoading">
      <mat-spinner class="m-auto" diameter="50"></mat-spinner>
    </div>
    <div *ngIf="dataSource.length" class="table-container mat-elevation-z8"
      [ngClass]="{ filterOpen: showFilter }" infiniteScroll [scrollWindow]="false" [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="pageCount" (scrolled)="getNextPage()">

      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr class="mat-header-row">
              <th class="mat-header-cell" width="30"></th>
              <th class="mat-header-cell">{{ "experts.Expert Name" | translate }}</th>
              <th class="mat-header-cell">{{ "expert.Country" | translate }}</th>
              <th class="mat-header-cell">{{ "matchmaking.Assistance Field Sectors" | translate }}</th>
              <th class="mat-header-cell">{{ "matchmaking.Assistance Field Specializations" | translate }}</th>
              <th class="mat-header-cell">{{ "Language.Languages" | translate }}</th>

            </tr>
          </thead>
          <tbody>
            <tr class="mat-row" *ngFor="let element of dataSource; let i = index"
              [ngClass]="{ active: element.isChaked }">
              <td class="mat-cell">
                <mat-radio-button class="mat-radio-button" [checked]="element.isChaked"
                  (change)="onChange(element);element.isChaked = true">
                </mat-radio-button>
              </td>
              <td class="mat-cell">
                <div class="img-cricle">
                  <div class="image">
                    <span>{{ element.name | shortName }}</span>
                  </div>
                  <div class="image-label">
                    {{element.name}}
                  </div>
                </div>
              </td>
              <td class="mat-cell fontSemiBold">
                <span *ngFor="let countrie of element.countries; let i = index " class="fontSemiBold">
                  <ng-container>{{countrie.name}}
                    <svg *ngIf="countrie.isMatched" class="ml-1 mr-1" width="9" height="9" viewBox="0 0 9 9">
                      <g id="matched" transform="translate(0.625 0.35)">
                        <circle id="circle" cx="4.5" cy="4.5" r="4.5" transform="translate(-0.625 -0.35)"
                          fill="#4ab635" />
                        <path id="check-mark"
                          d="M141.675,169.035a.422.422,0,0,1,0,.587l-2.715,2.783a.4.4,0,0,1-.572,0l-1.293-1.325a.422.422,0,0,1,0-.587.4.4,0,0,1,.572,0l1.006,1.032,2.429-2.49A.4.4,0,0,1,141.675,169.035Z"
                          transform="translate(-135.353 -166.505)" fill="#fff" />
                      </g>
                    </svg> {{i === element.countries.length -1 ? '' : '/'}}
                  </ng-container>
                </span>

              </td>
              <td class="mat-cell fontMedium">
                <div class="sectors-link">
                  <a class="no-hover-effect p-0" (click)="openSectors(element.sectors , 'sectors')">
                    +{{element.sectors.length}} {{ "sections.Sectors" | translate }}
                  </a>
                </div>
              </td>
              <td class="mat-cell fontSemiBold">
                <span *ngFor="let item of element.specializations; let i = index " class="fontSemiBold">
                  <ng-container *ngIf="i < 2">{{item.name}}
                    <svg *ngIf="item.isMatched" class="ml-1 mr-1" width="9" height="9" viewBox="0 0 9 9">
                      <g id="matched" transform="translate(0.625 0.35)">
                        <circle id="circle" cx="4.5" cy="4.5" r="4.5" transform="translate(-0.625 -0.35)"
                          fill="#4ab635" />
                        <path id="check-mark"
                          d="M141.675,169.035a.422.422,0,0,1,0,.587l-2.715,2.783a.4.4,0,0,1-.572,0l-1.293-1.325a.422.422,0,0,1,0-.587.4.4,0,0,1,.572,0l1.006,1.032,2.429-2.49A.4.4,0,0,1,141.675,169.035Z"
                          transform="translate(-135.353 -166.505)" fill="#fff" />
                      </g>
                    </svg> {{i === element.specializations.length -1 ? '' : '/'}}
                  </ng-container>
                </span>
                <ng-container *ngIf="element.specializations.length > 2">
                  <div class="sectors-link">
                    <a class="no-hover-effect p-0" (click)="openSectors(element.specializations , 'specializations' )">
                      +{{element.specializations.length
                      - 2}}
                      <svg width="14" height="14.001" viewBox="0 0 14 14.001">
                        <path id="external-link-icon"
                          d="M-2449.75-1879.186h-9.494a2.144,2.144,0,0,1-2.14-2.137v-9.5a2.353,2.353,0,0,1,2.152-2.365h4.607v1.448h-4.586a.73.73,0,0,0-.724.726l-.015,9.686a.706.706,0,0,0,.706.706l9.687-.016a.73.73,0,0,0,.724-.725v-4.585h1.448v4.585a2.356,2.356,0,0,1-2.356,2.172Zm-7.061-5.015a.718.718,0,0,1-.221-.534.687.687,0,0,1,.221-.485l6.841-6.528-1.516.01a.726.726,0,0,1-.726-.725.726.726,0,0,1,.725-.724h3.367a.716.716,0,0,1,.736.706v3.4a.726.726,0,0,1-.724.725.726.726,0,0,1-.725-.725l-.01-1.74-6.94,6.62a.752.752,0,0,1-.516.2A.742.742,0,0,1-2456.811-1884.2Z"
                          transform="translate(2461.384 1893.187)" fill="#3e74b3" />
                      </svg>
                    </a>
                  </div>
                </ng-container>
              </td>
              <td class="mat-cell fontSemiBold">
                <span *ngFor="let language of element.languages; let i = index " class="fontSemiBold">
                  <ng-container>{{language.name}}
                    <svg *ngIf="language.isMatched" class="ml-1 mr-1" width="9" height="9" viewBox="0 0 9 9">
                      <g id="matched" transform="translate(0.625 0.35)">
                        <circle id="circle" cx="4.5" cy="4.5" r="4.5" transform="translate(-0.625 -0.35)"
                          fill="#4ab635" />
                        <path id="check-mark"
                          d="M141.675,169.035a.422.422,0,0,1,0,.587l-2.715,2.783a.4.4,0,0,1-.572,0l-1.293-1.325a.422.422,0,0,1,0-.587.4.4,0,0,1,.572,0l1.006,1.032,2.429-2.49A.4.4,0,0,1,141.675,169.035Z"
                          transform="translate(-135.353 -166.505)" fill="#fff" />
                      </g>
                    </svg> {{i === element.languages.length -1 ? '' : '/'}}
                  </ng-container>
                </span>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- <div class="pagination-container">
              <div class="page-filter" *ngIf="pageCount > 1">
                <span>{{ 'expert.page' | translate }}</span><input type="text" (change)="updatePage($event)"
                  [value]="manualPage" />/{{ pageCount }}
              </div>
              <mat-paginator #paginator *ngIf="pageCount > 1" [length]="filterModel.totalCount"
                [pageSize]="filterModel.pageSize" [pageSizeOptions]="[5, 10, 20]"
                (page)="getListExpertsMatchedWithEnterprise($event)" [pageIndex]="filterModel.pageNumber"
                showFirstLastButtons></mat-paginator>
            </div> -->
      </div>
    </div>
      <div class="data-empty" *ngIf="!dataSource.length && !dataLoading">
        <p>{{ "expert.NoDataFound" | translate }}</p>
      </div>

    <div class="btn-container mt-3">
      <button mat-raised-button color="grey" mat-dialog-close>{{ 'swal.cancel' |
        translate}}</button>
      <button mat-raised-button color="primary" [class.loading-btn]="loading" [disabled]="loading || !isAssign" (click)="save()">
        <span>{{ 'matchmaking.Assign Expert' | translate}}</span>
      </button>
    </div>
  </section>
</mat-dialog-content>
