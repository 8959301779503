import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../models/config';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class LookupsService {
  constructor(private http: HttpClient) { }
  GetMenu() {
    return this.http.get(`${environment.apiUrl}/lookup/GetMenu`);
  }

  GetFAQs() {
    return this.http.get(`${environment.apiUrl}/home/GetFAQs`);
  }
  GetTermsAndConditions() {
    return this.http.get(`${environment.apiUrl}/home/GetTermsAndConditions`);
  }
  GetPolicies() {
    return this.http.get(`${environment.apiUrl}/home/GetPolicies?policyType=1`);
  }
  GetSupport() {
    return this.http.get(`${environment.apiUrl}/home/GetPolicies?policyType=2`);
  }
  AddSupportRequest(contactObj: any) {
    return this.http.post(
      `${environment.apiUrl}/home/AddSupportRequest`,
      contactObj
    );
  }

  getProjectSpecializations(filterModel: any): any {
    return this.http.get(`${environment.apiUrl}/Lookup/GetProjectSpecializations`, {
      params: filterModel,
    });
  }

  getCountries() {
    return this.http.get(`${environment.apiUrl}/lookup/Countries`);
  }
  getEnterprises() {
    return this.http.get(`${environment.apiUrl}/Dashboard/EnterpriseLookups`);
  }
  getCountriesWithFilter(text: any): any {
    return this.http.get(`${environment.apiUrl}/lookup/Countries?searchText=${text}`);
  }

  getAllCountries() {
    return this.http.get(`${environment.apiUrl}/lookup/AllCountries`);
  }

  getPermissions(permissionLevel: any) {
    return this.http.get(
      `${environment.apiUrl}/lookup/GetPermissions?permissionLevel=${permissionLevel}`
    );
  }
  getCities(filterModel: any): any {
    return this.http.get(`${environment.apiUrl}/Country/GetCities`, {
      params: filterModel,
    });
  }
  getLanguages() {
    return this.http.get(`${environment.apiUrl}/lookup/Languages`);
  }
  getProducts() {
    return this.http.get(`${environment.apiUrl}/lookup/ProductsServicesProvided`);
  }

  getSectors(filterModel: any): any {
    return this.http.get(`${environment.apiUrl}/Lookup/GetProjectSectors`, {
      params: filterModel,
    });
  }
  getPositionsDistinct(text: any): any {
    return this.http.get(`${environment.apiUrl}/Lookup/GetPositionsDistinct?searchText=${text}`);
  }
  getCommunicationPlatforms(filterModel: any): any {
    return this.http.get(`${environment.apiUrl}/Lookup/GetCommunicationPlatforms`, {
      params: filterModel,
    });
  }
  getProjectCountries(filterModel: any): any {
    return this.http.get(`${environment.apiUrl}/Lookup/GetProjectCountries`, {
      params: filterModel,
    });
  }

  getCommunications() {
    return this.http.get(`${environment.apiUrl}/lookup/CommunicationsPlatform`);
  }
  GetPositions() {
    return this.http.get(`${environment.apiUrl}/lookup/GetPositions`);
  }
  EnterpriseQuestionnaire() {
    return this.http.get(`${environment.apiUrl}/lookup/EnterpriseQuestionnaire`);
  }

  getListLanguages(langObj: any) {
    return this.http.post(
      `${environment.apiUrl}/lookupManagement/ListLanguages`,
      langObj
    );
  }
  getCompaniesSizes() {
    return this.http.get(`${environment.apiUrl}/lookup/GetCompaniesSizes`);
  }
  getProductsAndServicesDistinct() {
    return this.http.get(`${environment.apiUrl}/lookup/GetProductsAndServicesDistinct`);
  }
  getSystemYears() {
    return this.http.get(`${environment.apiUrl}/Kpi/GetSystemYears`);
  }
  getIpAddress() {
    return this.http.get(`${environment.apiUrl}/Lookup/GetIPAddress`);
  }
}
