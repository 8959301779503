import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Config } from '../models/config';

@Injectable({
  providedIn: 'root',
})
export class ProjectsService {
  projectDetails = new BehaviorSubject<any>(null);
  refreshProjectDeatils = new BehaviorSubject<any>(null);
  refreshConSpeSecForQuestionnaire = new BehaviorSubject<any>(null);
  projectCountriesSpecializationSectors = new BehaviorSubject<any>(null);
  questionnaireStepOneData = new BehaviorSubject<any>(null);
  questionnaireStepTwoData = new BehaviorSubject<any>(null);
  questionnaireStepThreeDataProjectIdAndQuestionnaireId =
    new BehaviorSubject<any>(null);
  clearSectors = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) {}

  getListProjects(filterModel: any): any {
    return this.http.get(`${environment.apiUrl}/Project/List`, {
      params: filterModel,
    });
  }
  getSectors(filterModel: any): any {
    return this.http.get(`${environment.apiUrl}/Sector/GetSectors`, {
      params: filterModel,
    });
  }
  getCurrencies(filterModel: any): any {
    return this.http.get(`${environment.apiUrl}/Lookup/GetCurrencies`, {
      params: filterModel,
    });
  }
  getSpecializations(filterModel: any): any {
    return this.http.get(`${environment.apiUrl}/Specialization/GetSpecializations`, {
      params: filterModel,
    });
  }
  getListUsersForProject(filterModel: any): any {
    return this.http.get(`${environment.apiUrl}/SystemAdmin/ListUsersForProject`, {
      params: filterModel,
    });
  }
  getListProjectAssinedUsers(filterModel: any): any {
    return this.http.get(`${environment.apiUrl}/Project/ListProjectAssinedUsers`, {
      params: filterModel,
    });
  }
  getCountries() {
    return this.http.get(`${environment.apiUrl}/Country/GetCountries`);
  }
  getUpgradingPointPermissions() {
    return this.http.get(
      `${environment.apiUrl}/Lookup/GetUpgradingPointPermissions`
    );
  }
  getProjectDetails(id: number) {
    return this.http.get(`${environment.apiUrl}/Project/Details/${id}`);
  }
  removeProject(id: number) {
    return this.http.get(`${environment.apiUrl}/Project/RemoveProject/${id}`);
  }
  getProjectAssigneeDetails(id: number, projectId: number) {
    const queryParameter =
      (id ? `?id=` + id : '') + (projectId ? `&projectId=` + projectId : '');
    return this.http.get(
      `${environment.apiUrl}/Project/GetProjectAssigneeDetails/` + queryParameter
    );
  }
  removeProjectAssignee(id: number, projectId: number) {
    const queryParameter =
      (id ? `?id=` + id : '') + (projectId ? `&projectId=` + projectId : '');
    return this.http.delete(
      `${environment.apiUrl}/Project/RemoveProjectAssignee` + queryParameter
    );
  }
  checkProjectAssignees(data: any) {
    return this.http.get(`${environment.apiUrl}/Project/CheckProjectAssignees`, data);
  }
  save(data: any) {
    if (data.id) {
      return this.http.put(`${environment.apiUrl}/Project/UpdateProject`, data);
    } else {
      return this.http.post(`${environment.apiUrl}/Project/AddProject`, data);
    }
  }
  assignSystemAdminToProject(data: any) {
    if (data.id) {
      return this.http.put(
        `${environment.apiUrl}/Project/UpdateProjectAssignee`,
        data
      );
    } else {
      return this.http.post(
        `${environment.apiUrl}/Project/AssignSystemAdminToProject`,
        data
      );
    }
  }
  extendProject(data: any) {
    return this.http.put(`${environment.apiUrl}/Project/ExtendProject`, data);
  }
  getProjectExtensionsDetails(id: number) {
    return this.http.get(
      `${environment.apiUrl}/Project/GetProjectExtensionsDetails/${id}`
    );
  }
  holdProject(data: any) {
    return this.http.put(`${environment.apiUrl}/Project/HoldProject`, data);
  }
  getProjectHoldsDetails(id: number) {
    return this.http.get(
      `${environment.apiUrl}/Project/GetProjectHoldsDetails/${id}`
    );
  }
  endProject(id: number) {
    return this.http.put(`${environment.apiUrl}/Project/EndProject/${id}`, {});
  }
  publishProject(data: any) {
    return this.http.put(`${environment.apiUrl}/Project/PublishProject`, data);
  }

  getQuestionGroups() {
    return this.http.get(`${environment.apiUrl}/Groups/GetGroups`);
  }
  getQuestionTypes() {
    return this.http.get(`${environment.apiUrl}/Question/GetQuestionTypes`);
  }
  getQuestionsOfGroup(id: any) {
    return this.http.get(
      `${environment.apiUrl}/Questionnaire/GetQuestionsByGroupForQuestionnaire/` + id
    );
  }
  createQuestionnaire(data: any) {
    return this.http.post(
      `${environment.apiUrl}/Questionnaire/CreateQuestionnaire`,
      data
    );
  }
  updateQuestionnaire(data: any) {
    return this.http.put(
      `${environment.apiUrl}/Questionnaire/UpdateQuestionnaire`,
      data
    );
  }
  enableEditingQuestionnaire(data: any) {
    return this.http.post(
      `${environment.apiUrl}/Questionnaire/ReCreateQuestionnaire`,
      data
    );
  }
  updateQuestion(data: any) {
    return this.http.put(
      `${environment.apiUrl}/Questionnaire/EditQuestionInQuestionnaire`,
      data
    );
  }
  addQuestionToQuestionnaire(data: any) {
    return this.http.post(
      `${environment.apiUrl}/Questionnaire/AddQuestionToQuestionnaire`,
      data
    );
  }
  ListProjectsHaveQuestionnaire(id: any) {
    return this.http.get(
      `${environment.apiUrl}/Project/ListProjectsHaveQuestionnaire/` + id
    );
  }
  GetQuestionnairesByProject(id: any) {
    return this.http.get(
      `${environment.apiUrl}/Questionnaire/GetQuestionnairesByProject/` + id
    );
  }
  GetQuestionnaireQuestions(id: any) {
    return this.http.get(
      `${environment.apiUrl}/Questionnaire/GetQuestionnaireQuestions/` + id
    );
  }

  listProjectQuestionnaire(params: any): any {
    return this.http.get(
      `${environment.apiUrl}/Questionnaire/ListProjectQuestionnaire`,
      {
        params: params,
      }
    );
  }
  getQuestionsPerQuestionnaire(params: any): any {
    return this.http.get(
      `${environment.apiUrl}/Questionnaire/ListQuestionsPerQuestionnairePerProject`,
      {
        params: params,
      }
    );
  }
  deleteQuestionnaire(questionnaireId: any, projectId: any) {
    const queryParameter =
      (questionnaireId ? `?questionnaireId=` + questionnaireId : '') +
      (projectId ? `&projectId=` + projectId : '');
    return this.http.delete(
      `${environment.apiUrl}/Questionnaire/DeleteQuestionnaire` + queryParameter
    );
  }
  deleteQuestionfromQuestionnaireInProjectDetails(
    questionnaireQuestionId: any,
    projectId: any
  ) {
    const queryParameter =
      (questionnaireQuestionId
        ? `?questionnaireQuestionId=` + questionnaireQuestionId
        : '') + (projectId ? `&projectId=` + projectId : '');
    return this.http.delete(
      `${environment.apiUrl}/Questionnaire/RemoveQuestionFromQuestionnaire` +
        queryParameter
    );
  }

  // kpis
  getAreasList(projectId: any) {
    const queryParameter = projectId ? `?projectId=` + projectId : '';
    return this.http.get(`${environment.apiUrl}/Kpi/ListAreas` + queryParameter);
  }
  getKpisQuestionsOfArea(data: any) {
    const queryParameter =
      (data.areaId ? `?areaId=` + data.areaId : '') +
      (data.projectId ? `&projectId=` + data.projectId : '');
    return this.http.get(
      `${environment.apiUrl}/Kpi/ListQuestionsGroupedByKpi/` + queryParameter
    );
  }
  addApplicationKPIs(data: any) {
    return this.http.post(`${environment.apiUrl}/Kpi/AddApplicationKPIs`, data);
  }

  listProjectKpis(data: any): any {
    const queryParameter =
      (data.projectId ? `?projectId=` + data.projectId : '') +
      (data.pageNumber ? `&pageNumber=` + data.pageNumber : '') +
      (data.pageSize ? `&pageSize=` + data.pageSize : '');
    return this.http.get(
      `${environment.apiUrl}/Kpi/ListProjectKpis` + queryParameter
    );
  }

  deleteKpiQuestion(kpiQuestionId: any, projectId: any) {
    return this.http.delete(
      `${environment.apiUrl}/Kpi/RemoveKpiQuestion?applicationKpiQuestionId=${kpiQuestionId}&projectId=${projectId}`
    );
  }

  getListProjectApplication(data: any) {
    return this.http.get(
      `${environment.apiUrl}/Application/ListProjectApplication`,
      {
        params: data,
      }
    );
  }
  ListProjectsHaveApplication(id: any) {
    return this.http.get(
      `${environment.apiUrl}/Project/ListProjectsHaveApplication/` + id
    );
  }
  GetApplicationsByProject(id: any) {
    return this.http.get(
      `${environment.apiUrl}/Application/GetApplicationssByProject/` + id
    );
  }

  createApplication(data: any) {
    return this.http.post(
      `${environment.apiUrl}/Application/CreateApplication`,
      data
    );
  }
  getQuestionsPerApplicationPerProject(params: any): any {
    return this.http.get(
      `${environment.apiUrl}/Application/ListQuestionsPerApplicationPerProject`,
      {
        params: params,
      }
    );
  }
  getListApplicationKpis(data: any) {
    return this.http.get(`${environment.apiUrl}/Kpi/ListApplicationKpis`, {
      params: data,
    });
  }
  addQuestionToApplication(data: any) {
    return this.http.post(
      `${environment.apiUrl}/Application/AddQuestionToApplication`,
      data
    );
  }
  GetQuestionsByGroupForApplication(id: any) {
    return this.http.get(
      `${environment.apiUrl}/Application/GetQuestionsByGroupForApplication/` + id
    );
  }
  getListApplicationOtherQuestions(data: any) {
    return this.http.get(
      `${environment.apiUrl}/Question/ListApplicationOtherQuestions`,
      {
        params: data,
      }
    );
  }
  EditOtherQuestionInApplication(data: any) {
    return this.http.put(
      `${environment.apiUrl}/Application/EditOtherQuestionInApplication`,
      data
    );
  }

  RemoveQuestionFromApplication(data: any) {
    return this.http.delete(
      `${environment.apiUrl}/Application/RemoveQuestionFromApplication`,
      {
        params: data,
      }
    );
  }
  getListQuestionsPerApplicationPerProject(data: any) {
    return this.http.get(
      `${environment.apiUrl}/Application/ListQuestionsPerApplicationPerProject`,
      {
        params: data,
      }
    );
  }
  updateApplication(data: any) {
    return this.http.put(
      `${environment.apiUrl}/Application/UpdateApplication`,
      data
    );
  }
  deleteApplication(data: any) {
    return this.http.delete(`${environment.apiUrl}/Application/DeleteApplication`, {
      params: data,
    });
  }
  GetApplicationQuestions(id: any) {
    return this.http.get(
      `${environment.apiUrl}/Application/GetApplicationQuestions/` + id
    );
  }
  enableEditingApplication(data: any) {
    return this.http.post(
      `${environment.apiUrl}/Application/ReCreateApplication`,
      data
    );
  }
  getQuestionsOfApplication(id: any) {
    return this.http.get(
      `${environment.apiUrl}/Application/GetQuestionsByGroupForApplication/` + id
    );
  }
}
