import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  CountryISO,
  PhoneNumberFormat,
  SearchCountryField,
} from 'ngx-intl-tel-input';
import { Communication } from 'src/app/models/lookups/Communication';
import { CommunicationType } from 'src/app/models/lookups/CommunicationType';
import { LookupsService } from 'src/app/services/lookups.service';

@Component({
  selector: 'app-contact-info-reactive-form',
  templateUrl: './contact-info-reactive-form.component.html',
})
export class ContactInfoReactiveFormComponent implements OnInit {
  form: any;
  contactType!: number;
  contactName: string = '';
  showContactItem = false;
  @Input() isSubmited = false;
  @Input() isRequired = false;
  @Input() updatedData: any;
  @Input() contactMethodItems: Communication[] = [];
  @Output() selectedMethods = new EventEmitter<Communication[]>();
  communicationTypes!: CommunicationType[];
  selectedMethod = new CommunicationType();
  selectedIndex = 1;
  communicationsList: any;
  //// Country Code
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  activeIndex: any;
  defaultMethod = 1;

  editMode = false;

  constructor(
    private formBuilder: FormBuilder,
    private lookupsService: LookupsService
  ) { }

  ngOnInit(): void {
    this.getCommunicationsPlatforms();
    this.buildForm();
    this.bindToUpdate();
  }

  getCommunicationsPlatforms() {
    this.lookupsService.getCommunicationPlatforms(null).subscribe(
      (data: any) => {
        if (data) {
          this.communicationTypes = data as CommunicationType[];
          this.selectedMethod = data[0];
        }
      },
      (err: any) => {
        console.error(err);
      }
    );
  }
  buildForm() {
    this.form = this.formBuilder.group({
      contactMethods: this.formBuilder.array([]),
    });
  }

  bindToUpdate() {
    if (this.updatedData) {
      this.contactMethodItems = this.updatedData;
      this.updatedData.forEach((element: any) => {
        this.addContactItem(element);
      });
      this.editMode = true;
    } else {
      this.addContactItem(null);
    }
  }

  get contactMethods() {
    return this.form.controls['contactMethods'] as FormArray;
  }


  addContactItem($ele: any, isAdd?: any) {
    console.log($ele);

    if (this.form.valid) {
      const methodsForm = this.formBuilder.group({
        filed: [
          $ele ? $ele.value : '', $ele ? this.setValidatorsCutomBiend($ele.type) :
            [
              Validators.required,
              Validators.maxLength(50),
            ],
        ],
        countryCode: [$ele ? $ele.phoneFlag : CountryISO.Morocco],
        dialCode: [$ele ? $ele.phoneCode : ''],
        enterpriseCommunicationId: [$ele ? $ele.enterpriseCommunicationId : ''],
        methodName: [$ele ? $ele.name : 'WhatsApp'],
        canedit: [$ele ? true : false],
        communicationId: $ele ? $ele.expertCommunicationId : '',
        selectedMethod: [$ele ? $ele.type : 1],
      });
      this.contactMethods.push(methodsForm);
    }

    if (isAdd) {
      this.activeIndex = this.contactMethods.length - 1;
    }
    if (this.communicationTypes && this.communicationTypes.length) {
      this.selectContactMethod(this.communicationTypes[0], this.contactMethods.length - 1)
    }
  }
  setValidatorsCutomBiend(id: any) {
    const validatorsArr = [Validators.required, Validators.maxLength(50)];
    if (id === 2) {
      validatorsArr.push(
        Validators.pattern(
          '[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{1,}[.]{1}[a-zA-Z]{2,}'
        )
      );
    }
    if (id === 4) {
      validatorsArr.push(
        Validators.pattern('^[a-zA-ZÀ-ÿ\s\u00C0-\u017F0-9]+$'),
      );
    }
    return validatorsArr;
  }

  selectContactMethod(method: any, i: any) {
    this.selectedMethod = method;
    this.form.controls.contactMethods.controls[i].get('filed').clearValidators();
    this.form.controls.contactMethods.controls[i].get('filed').setValidators(this.setValidatorsCutom(method));
    this.form.controls.contactMethods.controls[i].get('filed').updateValueAndValidity();
    this.form.markAsUntouched();
  }
  setValidatorsCutom(method: any) {
    console.log(method);
    const validatorsArr = [Validators.required, Validators.maxLength(50)];
    if (method.id === 2) {
      validatorsArr.push(
        Validators.pattern(
          '[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{1,}[.]{1}[a-zA-Z]{2,}'
        )
      );
    }
    if (method.id === 4) {
      validatorsArr.push(
        Validators.pattern('^[a-zA-ZÀ-ÿ\s\u00C0-\u017F0-9]+$'),
      );
    }

    return validatorsArr;
  }

  addNewContact(i: any) {
    console.log(this.contactMethods.controls[i]);

    const item = {} as Communication;
    if (this.form.controls.contactMethods.controls[i].valid) {
      console.log(this.selectedMethod);


      this.form.controls.contactMethods.controls[i]
        .get('canedit')
        .setValue(true);
      //this.form.controls.contactMethods.controls[i].get('selectedMethod').setValue(this.selectedMethod.id)
      this.showEdit(i)
      item.name = this.form.controls.contactMethods.controls[i].get('methodName').value;
      item.type = this.form.controls.contactMethods.controls[i].get('selectedMethod').value;
      item.id = this.form.controls.contactMethods.controls[i].get('selectedMethod').value;
      if (this.form.controls.contactMethods.controls[i].get('enterpriseCommunicationId').value) {
        item.enterpriseCommunicationId = this.form.controls.contactMethods.controls[i].get('enterpriseCommunicationId').value
      }
      if (this.form.controls.contactMethods.controls[i].get('communicationId').value) {
        item.communicationId = this.form.controls.contactMethods.controls[i].get('communicationId').value
      }
      if (this.selectedMethod.id == 1 || this.selectedMethod.id == 3) {
        item.phoneCode = this.form.controls.contactMethods.controls[i].get('filed').value?.dialCode
        item.phoneFlag = this.form.controls.contactMethods.controls[i].get('filed').value?.countryCode
        item.value = this.form.controls.contactMethods.controls[i].get('filed').value?.number;
      } else {
        item.value = this.form.controls.contactMethods.controls[i].get('filed').value;
      }
      if (this.contactMethodItems.length) {
        this.contactMethodItems.splice(i, 1)
        this.contactMethodItems.push(item);
      } else {
        this.contactMethodItems.push(item);
      }
      console.log(this.contactMethodItems);
      this.selectedMethods.emit(this.contactMethodItems);
    } else {
      if (this.form.controls.contactMethods.controls[i].get('canedit').value) {
        this.removeContactItem(i)
        this.addContactItem(null)
      }
      this.form.controls.contactMethods.controls[i].markAllAsTouched();
    }
    console.log(this.form.controls.contactMethods);

  }


  showEdit(i: any) {
    if (this.activeIndex !== i) {
      this.activeIndex = i;
    } else {
      this.activeIndex = null;
    }
  }

  removeContactItem(i: any) {
    if (this.contactMethods.length > 1) {
      this.contactMethods.removeAt(i);
      this.selectedMethods.emit(this.contactMethodItems);
    } else {
      this.contactMethods.removeAt(i);
      this.addContactItem(null);
      this.selectedMethods.emit(this.contactMethodItems);
    }
    if (i !== -1) {
      this.contactMethodItems.splice(i, 1);
      this.selectedMethods.emit(this.contactMethodItems);
    }
  }

  getValue(selectedMethod: any, filed: any) {
    if (typeof selectedMethod === 'object') {
      return filed.nationalNumber
    } else {
      return filed
    }

  }

  hasError(errorName: string, controlName: string, index: any = null) {
    if (controlName && index !== null) {
      return (this.contactMethods.controls[index] as FormGroup).controls[
        controlName
      ].hasError(errorName);
    } else {
      return this.form.controls[controlName!].hasError(errorName);
    }
  }
}
