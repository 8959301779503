import { Directive, Input, HostListener } from '@angular/core';

@Directive({
  selector: '[appPrint]'
})
export class PrintDirective {
  @Input() elem: string = '';
  constructor() { }

  @HostListener('click', ['$event']) onClick(){
    this.print();
  }

  print(): void {
    const lang = localStorage.getItem('language') || 'en';
    const basicUrl = location.origin;
    let style = basicUrl + '/assets/css/en-US/style.css';
    let dir = 'ltr';
    if (lang === 'ar') {
      style = basicUrl + '/assets/css/ar-SA/style.css';
      dir = 'rtl';
    }
    const mywindow = window.open('', 'PRINT', 'height=600,width=1000')!;
    mywindow.document.write('<html dir="' + dir + '"><head>\
      <base href="/">\
      <link rel="stylesheet" type="text/css" href="' + style + '">\
      <title>' + document.title  + '</title>');
    mywindow.document.write('</head><body class="printable-doc">');
    mywindow.document.write(document.getElementById(this.elem)!.innerHTML);
    mywindow.document.write('</body></html>');

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    mywindow.onload = () => {
        mywindow.print();
    };
    // mywindow.close();

    // return true;
  }
}
