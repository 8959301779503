import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EnterpriseRequestsService } from 'src/app/services/enterprise-requests';
import { ExpertRequestsService } from 'src/app/services/expert-requests';
import Swal from 'sweetalert2';
import { RejectionReasonComponent } from '../../../expert-requests/expert-requests-details/rejection-reason/rejection-reason.component';

interface DialogData {
  id: number;
  response: boolean;
  profileUpdatesDecision: boolean;
  isAssistanceRequestView: boolean;
  from: any;
}
@Component({
  selector: 'app-enterprise-rejection-reason',
  templateUrl: './enterprise-rejection-reason.component.html',
})
export class EnterpriseRejectionReasonComponent implements OnInit {
  loading = false;
  formGroup: FormGroup;
  rejectionReason = '';
  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<RejectionReasonComponent>,
    private translate: TranslateService,
    private expertRequestsService: ExpertRequestsService,
    private enterpriseRequestsService: EnterpriseRequestsService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit(): void {
    this.buildForms();
    console.log(this.data);
  }
  buildForms() {
    const regex = /^[a-zA-ZÀ-ÿ\s\u00C0-\u017F]+$/;
    this.formGroup = this.formBuilder.group({
      rejectionReason: [
        '',
        [
          Validators.required,
          Validators.pattern(regex),
          Validators.maxLength(500),
        ],
      ],
    });
  }

  save() {
    let data = {
      id: this.data.id,
      from: this.data.from,
      rejectionReason: this.formGroup.value.rejectionReason,
      response: this.data.response,
      isAssistanceRequestView: this.data.isAssistanceRequestView,
    };
    if (this.formGroup.valid) {
      this.loading = true;
      this.enterpriseRequestsService.registrationDecision(data).subscribe(
        (data: any) => {
          this.loading = false;
          this.dialogRef.close(true);
          Swal.fire({
            title: 'Success',
            text: 'Decision saved successfully',
            icon: 'success',
            confirmButtonText: this.translate.instant('swal.ok'),
          }).then(() => { });
        },
        (error: any) => {
          console.log(error);
        }
      );
    }
  }

  hasError(formGroup: FormGroup, controlName: string, errorName: string) {
    return formGroup.controls[controlName].hasError(errorName);
  }
}
