<mat-dialog-content class="createUserDialog createDialog">
  <section class="createUser">
    <div class="auth-card d-flex align-items-center justify-content-between">
      <h2 class="title">{{ "experts.Rejection reason" | translate }}</h2>

      <button class="close" mat-dialog-close="">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <form [formGroup]="formGroup">
    <div class="form-element">
      <mat-form-field class="form-group" appearance="fill">
        <mat-label>{{ "experts.Rejection reason" | translate }} </mat-label>
        <textarea matInput formControlName="rejectionReason"></textarea>
        <mat-error *ngIf="hasError(formGroup, 'rejectionReason', 'required')">{{ 'forms.Required' | translate }}</mat-error>
        <mat-error *ngIf="hasError(formGroup, 'rejectionReason', 'pattern') || hasError(formGroup, 'rejectionReason', 'maxlength')">
          {{ 'forms.Enter valid rejection reason' | translate }}
        </mat-error>
      </mat-form-field>

      <div class="btn-container sp-btn">
        <button mat-raised-button color="grey" mat-dialog-close (click)="loading = false">{{ 'Close' | translate
          }}</button>
        <button mat-raised-button color="primary" [class.loading-btn]="loading" [disabled]="loading" (click)="save(); ">
          <span>{{ 'Confirm' | translate }}</span>
        </button>
      </div>
    </div>
    </form>

  </section>
</mat-dialog-content>
