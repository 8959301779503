import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../models/config';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MatchmakingRequestsService {

  constructor(private http: HttpClient) {
  }

  // enterpriseRequests(filterModel): any {
  //   return this.http.post(`${environment.adminApiUrl}/ListMatchMaking`, filterModel);
  // }
  // export(filterModel): any {
  //   return this.http.post(`${environment.adminApiUrl}/ExportMatchMaking`, filterModel);
  // }

  // GetMatchedExperts(id): any {
  //   const filterModel = {
  //     AssessmentRequestId : id,
  //     PageSize : 50,
  //     PageNumber : 0
  //   };
  //   return this.http.post(`${environment.adminApiUrl}/GetMatchedExperts`, filterModel);
  // }

  // MatchMaking(RequestId, ExpertId) {
  //   const obj = { RequestId, ExpertId };
  //   return this.http.post(`${environment.assessmentRequestApiUrl}/MatchMaking`, obj);
  // }

  // AddFBFeedback(RequestId, FocalFeedback) {
  //   const obj = { RequestId, FocalFeedback };
  //   return this.http.post(`${environment.assessmentRequestApiUrl}/AddFocalPointFeedback`, obj);
  // }


  /////
  getmatchmakingList(filterModel: any): any {
    return this.http.get(`${environment.apiUrl}/MatchMakingTransaction/ListMatchmakingRequests`, {
      params: filterModel,
    });
  }
  getMatchmakingRequestDetails(enterpriseProjectId: any): any {
    return this.http.get(`${environment.apiUrl}/MatchMakingTransaction/GetMatchmakingRequestDetails/` + enterpriseProjectId);
  }
  getListExpertsMatchedWithEnterprise(filterModel: any): any {
    return this.http.get(`${environment.apiUrl}/Expert/ListExpertsMatchedWithEnterprise`, {
      params: filterModel,
    });
  }
  assignMissionToExpert(obj: any): any {
    return this.http.post(`${environment.apiUrl}/MatchMakingTransaction/AssignMissionToExpert`, obj);
  }
}
