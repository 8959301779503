import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'DTM'
})
export class DateTimeMergePipe implements PipeTransform {
  transform(date: Date, time: string): string {
    if (!date) { return ''; }
    const clearDate = String(date).split('T')[0] || '';
    // const newDate = new Date(date + '' + time);
    return clearDate + ' ' + time;
  }
}
