<aside class="sidebarMenu" [ngClass]="{'open-sidebar': toggleSide}">
    <div class="sidebar-content">
        <a [routerLink]="['/admin/requests/enterprise-requests']" class="logo">
            <img class="desktop-logo" src="./assets/images/Logo.png" alt="">
            <img class="mop-logo" src="./assets/images/UNIDO-Logo.png" alt="">
        </a>
        <div class="userAccount m-0">
            <div class="profile-image">
                <img *ngIf="currentUser.profilePicture" [src]="currentUser.profilePicture" (error)="currentUser.profilePicture = ''" />
                <span *ngIf="!currentUser.profilePicture">{{ initName(currentUser.fullName) | uppercase }}</span>
            </div>
            <div class="user-name">
                <span class="mr-2">Hello, {{currentUser.fullName}}</span>
            </div>
        </div>
        <div class="menu-items">
            <ul class="nav-items">
                <li *ngFor='let item of menuItems;let i = index'>
                  <ng-container *ngIf="item.subMenu.length else withOutSub">
                     <a href="javascript:void(0);" (click)='item.openSub = !item.openSub;closeSub(i)' [class.active]="item.openSub">
                        <svg width="24.998" height="25.002" viewBox="0 0 24.998 25.002" [innerHTML]="item.svgPath | sanitizeHtml"></svg>
                        <span>{{item.name}}</span>
                        <mat-icon matSuffix class="ml-auto" *ngIf="item.subMenu.length">{{item.openSub ? 'expand_less' : 'expand_more'}}</mat-icon>
                    </a>
                  </ng-container>
                  <ng-template #withOutSub>
                     <a [routerLink]="item.name == 'KPI Dashboard' ? '#' :[item.url] " [routerLinkActive]="['active']" (click)='closeSub(i);item.openSub = !item.openSub'>
                        <svg width="24.998" height="25.002" viewBox="0 0 24.998 25.002" [innerHTML]="item.svgPath | sanitizeHtml"></svg>
                        <span>{{item.name}}</span>
                        <mat-icon matSuffix class="ml-auto" *ngIf="item.subMenu.length">{{item.openSub ? 'expand_less' : 'expand_more'}}</mat-icon>
                    </a>
                  </ng-template>
                    <ul *ngIf="item.subMenu.length && item.openSub">
                        <li *ngFor='let sub of item.subMenu'>
                            <a [routerLink]="[sub.url]" [routerLinkActive]="['active']">
                                <svg width="24.998" height="25.002" viewBox="0 0 24.998 25.002" [innerHTML]="sub.svgPath | sanitizeHtml"></svg>
                                <span>{{sub.name}}</span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>

            <ul class="btm-menuItems">
                <li class="signout-mopbile">
                    <a (click)="logout()">
                        <svg width="17.001" height="17.006" viewBox="0 0 17.001 17.006">
                            <path id="sign-out-icon" d="M15.19.008H1.8A1.769,1.769,0,0,0,.532.539,1.784,1.784,0,0,0,0,1.815V15.2a1.815,1.815,0,0,0,1.811,1.808h13.4A1.817,1.817,0,0,0,17,15.2V11.052L15.342,12.61v2.74H1.656V1.775H15.342V4.4L17,5.861V1.7A1.766,1.766,0,0,0,16.472.43,1.69,1.69,0,0,0,15.35,0C15.3,0,15.244,0,15.19.008ZM10.677,5.047l2.691,2.688H3.806v1.54h9.562l-2.691,2.691,1.167,1.169,4.52-4.629-4.52-4.629Z" transform="translate(17.001 17.006) rotate(180)" fill="#999"/>
                        </svg>
                        <span>
                            Sign out
                        </span>
                    </a>
                </li>
                <li>
                    <svg width="13" height="13" viewBox="0 0 13 13">
                        <path id="copyright-icon" d="M-7.575-5.307a1.852,1.852,0,0,1,.2-.58,1.143,1.143,0,0,1,.366-.4,1.054,1.054,0,0,1,.6-.153.944.944,0,0,1,.427.092,1.222,1.222,0,0,1,.32.229,1.151,1.151,0,0,1,.229.336.972.972,0,0,1,.092.412h1.16a2.061,2.061,0,0,0-.168-.839,2,2,0,0,0-.458-.656,2.032,2.032,0,0,0-.7-.427,2.573,2.573,0,0,0-.9-.153,2.532,2.532,0,0,0-1.114.229,2.214,2.214,0,0,0-.778.61,2.614,2.614,0,0,0-.458.885,3.61,3.61,0,0,0-.153,1.053v.183a4.156,4.156,0,0,0,.137,1.068,2.16,2.16,0,0,0,.458.885,2.291,2.291,0,0,0,.793.58,2.686,2.686,0,0,0,1.114.214,2.6,2.6,0,0,0,.854-.137,2.167,2.167,0,0,0,.7-.4,1.831,1.831,0,0,0,.473-.61,2.089,2.089,0,0,0,.2-.748h-1.16a.813.813,0,0,1-.092.366.9.9,0,0,1-.244.305,1.081,1.081,0,0,1-.336.183,1.247,1.247,0,0,1-.4.061,1.145,1.145,0,0,1-.6-.137.989.989,0,0,1-.366-.4,2.052,2.052,0,0,1-.2-.58,3.5,3.5,0,0,1-.061-.656v-.183A3.262,3.262,0,0,1-7.575-5.307Zm1.251-5.768a6.461,6.461,0,0,0-4.608,1.892,6.461,6.461,0,0,0-1.892,4.608A6.461,6.461,0,0,0-10.932.034,6.461,6.461,0,0,0-6.324,1.926,6.461,6.461,0,0,0-1.716.034,6.461,6.461,0,0,0,.176-4.574,6.461,6.461,0,0,0-1.716-9.182,6.461,6.461,0,0,0-6.324-11.074Zm0,11.718A5.209,5.209,0,0,1-10-.9a5.209,5.209,0,0,1-1.541-3.677A5.209,5.209,0,0,1-10-8.251,5.209,5.209,0,0,1-6.324-9.793,5.209,5.209,0,0,1-2.647-8.251,5.209,5.209,0,0,1-1.106-4.574,5.209,5.209,0,0,1-2.647-.9,5.209,5.209,0,0,1-6.324.644Z" transform="translate(12.824 11.074)" fill="#999"/>
                    </svg>
                    <span>
                         2023 United Nations Industrial Development Organization, All Rights Reserved
                    </span>
                </li>
            </ul>
        </div>
    </div>
    <div class="close-icon" *ngIf="toggleSide" (click)="sidebarTrigger.emit()">
        <svg width="17" height="17" viewBox="0 0 17 17">
            <path id="close-menu-btn" d="M967.057,548.363l-6.847-6.849-6.847,6.849a.969.969,0,0,1-1.369-1.371l6.849-6.845-6.849-6.849a.967.967,0,0,1,1.369-1.366l6.847,6.847,6.847-6.847a.968.968,0,0,1,1.369,0,.957.957,0,0,1,.011,1.354l-.011.012-6.849,6.849,6.849,6.845a.968.968,0,0,1,0,1.369l0,0a.965.965,0,0,1-.678.286A.978.978,0,0,1,967.057,548.363Z" transform="translate(-951.712 -531.648)" fill="#f0f0f0"/>
          </svg>
    </div>
</aside>
