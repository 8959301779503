import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../models/config';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(private http: HttpClient) { }

  GetNotifications(searchModel: any) {
    return this.http.get(`${environment.apiUrl}/Notification/List`, { params: searchModel });
  }
  SeeNotifications() {
    return this.http.get(`${environment.apiUrl}/Notification/SeeNotification`);
  }
}
