import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from '../services/authentication.service';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService, private router : Router) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
          let user:any =  this.authenticationService.currentUserValue;


            if ((err.status === 404) &&  user?.loginStatus == 0) {
                // auto logout if 401 response returned from api
                this.router.navigate(['404']);
                // location.reload(true);
            } else if ((err.status === 401 || err.status === 403)) {
                // auto logout if 401 response returned from api
                this.authenticationService.logout();
                 //this.router.navigate(['404']);
                // location.reload(true);
            }

            // const error = err.error.message || err.statusText;
            return throwError(err.error);
        }));
    }
}
