

  import { Component, OnInit, ChangeDetectorRef, ViewChild, Output, EventEmitter, Input } from '@angular/core';
  import { NgForm, FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

  import { LookupsService } from 'src/app/services/lookups.service';
  import { CommunicationType } from 'src/app/models/lookups/CommunicationType';
  import { Communication } from 'src/app/models/lookups/Communication';
   @Component({
    selector: 'app-contact-info-form',
    templateUrl: './contact-info-form.component.html'
  })
  export class ContactInfoFormComponent implements OnInit {
    @ViewChild('editForm', { static: false }) formEdit!: NgForm;
    @Output() selectedMethods = new EventEmitter<Communication[]>();
    @Input() isSubmited = false;
    @Input() isRequired = false;
    @Input() updatedData:any;
    toggleMobNew = true;
    toggleEmailNew = false;
    toggleWhatAppNew = false;
    toggleOtherNew = false;
    showContactItem = false;
    @Input() showNewItem = false;

    contactType!: number;
    contactName: string = '';
    @Input() contactMethodItems: Communication[] = [];
    communicationTypes!: CommunicationType[];

    patternOtions = '';

    contactControl = new FormControl('', []);
    selectedMethod = new CommunicationType();
    constructor(private cd: ChangeDetectorRef,
                private lookupsService: LookupsService) { }

    ngOnInit() {
      this.getCommunicationsPlatforms();
      if (this.updatedData) {
        this.contactMethodItems = this.updatedData;
       }
    }


  getCommunicationsPlatforms() {
    this.lookupsService.getCommunicationPlatforms(null).subscribe(
      (data: any) => {
        this.communicationTypes = data as CommunicationType[];
        this.selectedMethod = data[0];
        this.contactType = this.communicationTypes[0].id;
        // console.log(this.contactType);
        this.contactName = this.communicationTypes[0].name;
        this.setValidatContact();
      },
      (err: any) => {
        console.log(err);
      }
    );
  }

    toggleContactItem() {
      this.showContactItem = !this.showContactItem;
      this.setValidatContact();
      this.showNewItem = true;
       this.contactControl.markAsPristine();
       this.contactControl.markAsUntouched();
       this.contactType = 1;
    }

    // expand and collapse
    expendItem(i: any, parent: any) {
      this.contactControl.setValue('');
      this.showContactItem = false;
      const target = document.querySelectorAll('#' + parent + ' .static-item').item(i);
      if (!target.classList.contains('active')) {
        this.removeActiveTab(parent);
        target.classList.add('active');
      } else {
        this.removeActiveTab(parent);
      }
    }
    removeActiveTab(target: any) {
      const elems = document.querySelectorAll('#' + target + ' .static-item.active');
      [].forEach.call(elems, (el: any) => {
        el.className = el.className.replace(/\bactive\b/, '');
      });
    }
    // end expand and collapse

    checkValues(index: any) {
      // console.log('item', this.contactMethodItems[index]);
    }

    selectContactMethod(method: any) {
      this.selectedMethod = method;
      this.contactName = method.name;
      this.contactType = method.id;
      this.setValidatContact();
    }
    setValidatContact() {
      // console.log(this.contactType);
      // WhatsApp=1,
      // Email=2,
      // Mobile=3,
      // Other=4
      this.contactControl = new FormControl('', []);
      this.contactControl.setValue('');
      if (this.selectedMethod.communicationPlatformType == 1) { // whatsapp
        this.contactControl.setValidators([
          Validators.required,
          Validators.maxLength(50),
          Validators.pattern('^((\\+91-?)|0)?[0-9]{11,50}$')
        ]);
      }else if (this.selectedMethod.communicationPlatformType == 2) { // email
        this.contactControl.setValidators([
          Validators.required,
          Validators.email,
          Validators.pattern('[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{1,}[.]{1}[a-zA-Z]{2,}'),
          Validators.maxLength(50)
        ]);
      } else if (this.selectedMethod.communicationPlatformType == 3) { // mobile no.
        this.contactControl.setValidators([
          Validators.required,
          Validators.maxLength(50),
          Validators.pattern('^((\\+91-?)|0)?[0-9]{11,50}$')
        ]);
      }  else if (this.selectedMethod.communicationPlatformType == 4) { // other.
        this.contactControl.setValidators([
          Validators.required,
          Validators.maxLength(50)
        ]);
      } else {
        this.contactControl.setValidators([]);
      }
    }

    addNewContact() {
      const item = {} as Communication;
      console.log(this.contactControl);
      // if (this.checkItemExist()) {
      //   this.contactControl.setErrors({notUniqe: true});
      // }
      // && !this.checkItemExist()
      if (this.contactControl.valid ) {
        this.showNewItem = true;
        item.name = this.contactName;
        item.communicationId = this.contactType;
        item.type = this.contactType;
        item.id = this.contactType;
        item.value = this.contactControl.value;
        this.contactMethodItems.push(item);
        this.selectedMethods.emit(this.contactMethodItems);
        this.toggleContactItem();
        this.contactControl.setValue('');
      }
    }

    checkItemExist() {
      return this.contactMethodItems.some((item) => item.value === this.contactControl.value);
    }

    deleteItem(itemIndex: any) {
      if (itemIndex !== -1) {
        this.contactMethodItems.splice(itemIndex, 1);
        this.selectedMethods.emit(this.contactMethodItems);
      }
      console.log(this.contactMethodItems);

    }
    editItem(index: any) {
      this.selectedMethods.emit(this.contactMethodItems);
      this.expendItem(index, 'methodList');
    }
    updateItem(index: any) {
      this.selectedMethods.emit(this.contactMethodItems);
    }
    setValidtorEditMood(CurrentMethod: any, value: any, i: any) {
      console.log(CurrentMethod);
      // if (!this.formEdit.form.get('editContactControl')?.valid) {
      //   this.contactMethodItems.map((item: any, index) => {
      //     if (i == index) {
      //       item.valid = false;
      //     } else {
      //        item.valid = true;
      //     }
      //   });
      // } else {
      //   this.contactMethodItems.map((item: any, index) => {
      //     if (i == index) {
      //       item.valid = true;
      //     }
      //   });
      // }

      if (CurrentMethod === 1) {
        // whatapp.

        this.formEdit.form
          .get('editContactControl')!
          .setValidators([
            Validators.required,
            Validators.pattern('^((\\+91-?)|0)?[0-9]{11,20}$'),
            Validators.maxLength(50),
          ]);
        this.formEdit.form.get('editContactControl')!.updateValueAndValidity();
      } else if (CurrentMethod === 2) {
        // email
        this.formEdit.form
          .get('editContactControl')!
          .setValidators([
            Validators.required,
           // Validators.email,
            Validators.pattern(
              '[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{1,}[.]{1}[a-zA-Z]{2,}'
            ),
            Validators.maxLength(50),
          ]);
        this.formEdit.form.get('editContactControl')!.updateValueAndValidity();
      } else if (CurrentMethod === 3) {
        // other.

        this.formEdit.form
          .get('editContactControl')!
          .setValidators([
            Validators.required,
            Validators.pattern('^((\\+91-?)|0)?[0-9]{11,20}$'),
            Validators.maxLength(50),
          ]);
        this.formEdit.form.get('editContactControl')!.updateValueAndValidity();
      } else if (CurrentMethod === 4) {
        // other.

        this.formEdit.form
          .get('editContactControl')!
          .setValidators([Validators.required, Validators.maxLength(50)]);
        this.formEdit.form.get('editContactControl')!.updateValueAndValidity();
      } else {
        this.formEdit.form.get('editContactControl')!.setValidators([]);
        this.formEdit.form.get('editContactControl')!.updateValueAndValidity();
      }
      console.log(this.contactMethodItems);

      // if (value == '') {
      //   this.deleteItem(i);
      //   //this.toggleContactItem();
      // }

      this.addNewContact();
    }
  }
